import { createAction } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import { SET, UPDATE } from './constants';

export const set = createAction(SET, (token: string) => token)<string>();

export const update = createAction(UPDATE, (response: AxiosResponse) => {
  const header = response.headers['authorization'];
  return header.split(' ')[1];
})<string>();
