import 'core-js/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'moment/locale/ja';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import store, { history } from './store';
import GlobalStyle from './GlobalStyle';
import App from './App';
import * as serviceWorker from './serviceWorker';

moment.locale('ja', {
  months: '1月 2月 3月 4月 5月 6月 7月 8月 9月 10月 11月 12月'.split(' '),
});

if (!(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
  Sentry.init({
    dsn: 'https://3eb4dad079254d3cb3fb2d63fe45d59a@sentry.io/2024386',
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GlobalStyle />
        <App />
      </ConnectedRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
