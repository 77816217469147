import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Types from 'MyTypes';
import Responses from 'Responses';
import { System as SystemAPI } from '../../api/chat';
import Base from '../../components/molecules/Base';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import IconArrow from '../../components/atoms/icons/IconArrow';

type Props = {
  children?: React.ReactNode;
  token: string;
};

type State = {
  isLoading?: boolean;
  systemMessages: Types.SystemMessages;
};

const mapStateToProps = (state: Types.RootState, ownProps: RouteComponentProps<{ operator_id: string }>) => {
  return {
    ...ownProps,
  };
};

const mapDispatchToProps = {};

class UtteranceAdmin extends React.Component<Props, State> {
  readonly state: State = {
    isLoading: true,

    systemMessages: {
      id: '',
      operatorOnly: {
        transfer: {
          incoming: '',
          receive: '',
        },
        chat: {
          start: '',
          unresolved: '',
          crewDisconnect: '',
        },
        createRoom: {
          registCategory: '',
        },
      },
      crewOnly: {
        chat: {
          assignedOperator: '',
          reassignedOperator: '',
        },
      },
    },
  };

  componentDidMount(): void {
    this.fetchMessages();
  }

  fetchMessages = () => {
    this.setState({ isLoading: true });
    SystemAPI.getMessages()
      .then(response => {
        if (response.status === 200) {
          this.decode(response.data);
        } else {
          console.log(response);
          alert('API サーバーで問題が発生しました');
        }
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);
        alert('API サーバーで問題が発生しました');
      });
  };

  saveMessages = () => {
    this.setState({ isLoading: true });

    const data = this.encode(this.state.systemMessages);
    SystemAPI.putMessages(data).then(response => {
      if (response.status === 200) {
        alert('保存しました。');
      } else {
        alert(`保存できませんでした。${response.data}`);
      }
      this.setState({ isLoading: false });
    });
  };

  decode = (data: Responses.SystemMessages) => {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const { operator_only, crew_only } = data;
    this.setState({
      systemMessages: {
        id: data.id,
        operatorOnly: {
          transfer: {
            incoming: operator_only.transfer.incoming,
            receive: operator_only.transfer.receive,
          },
          chat: {
            start: operator_only.chat.start,
            unresolved: operator_only.chat.unresolved,
            crewDisconnect: operator_only.chat.crew_disconnect,
          },
          createRoom: {
            registCategory: operator_only.create_room.regist_category,
          },
        },
        crewOnly: {
          chat: {
            assignedOperator: crew_only.chat.assigned_operator,
            reassignedOperator: crew_only.chat.reassigned_operator,
          },
        },
      },
    });
  };

  encode = (systemMessages: Types.SystemMessages): Responses.SystemMessages => {
    const { operatorOnly, crewOnly } = systemMessages;
    return {
      id: systemMessages.id,
      operator_only: {
        transfer: {
          ...operatorOnly.transfer,
        },
        chat: {
          ...operatorOnly.chat,
          crew_disconnect: operatorOnly.chat.crewDisconnect,
        },
        create_room: {
          regist_category: operatorOnly.createRoom.registCategory,
        },
      },
      crew_only: {
        chat: {
          assigned_operator: crewOnly.chat.assignedOperator,
          reassigned_operator: crewOnly.chat.reassignedOperator,
        },
      },
    };
  };

  cancel = (): void => {
    this.fetchMessages();
  };

  save = (): void => {
    this.saveMessages();
  };

  render() {
    const { systemMessages, isLoading } = this.state;
    const { operatorOnly, crewOnly } = systemMessages;

    return (
      <StyleBase>
        <Wrap>
          <div>
            <WrapTitle>
              <StyleArrow size={16} />
              <Title size={16} text="オペレーター側に表示されるもの" />
            </WrapTitle>
            <SubArea>
              <WrapSubTitle>
                <SubTitle text={'転送周り'} />
              </WrapSubTitle>
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'転送着信時'} />
                </WrapSubSubTitle>
                <Label text={'他のオペレーターから転送の着信があった際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することが出来ます。'}
                    value={operatorOnly.transfer.incoming}
                    onChange={e => {
                      systemMessages.operatorOnly.transfer.incoming = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
              <Separator />
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'転送受入時'} />
                </WrapSubSubTitle>
                <Label text={'転送を受け入れた際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することができます。'}
                    value={operatorOnly.transfer.receive}
                    onChange={e => {
                      systemMessages.operatorOnly.transfer.receive = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
            </SubArea>
            <SubArea>
              <WrapSubTitle>
                <SubTitle text={'チャット中'} />
              </WrapSubTitle>
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'チャット開始メッセージ'} />
                </WrapSubSubTitle>
                <Label text={'他のオペレーターから転送の着信があった際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することが出来ます。'}
                    value={operatorOnly.chat.start}
                    onChange={e => {
                      systemMessages.operatorOnly.chat.start = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
              <Separator />
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'未解決によるクルー再接続'} />
                </WrapSubSubTitle>
                <Label
                  text={
                    'クルーが案件終了時に「質問は解決しましたか？」に対して「いいえ」が押された際のメッセージです。'
                  }
                />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することができます。'}
                    value={operatorOnly.chat.unresolved}
                    onChange={e => {
                      systemMessages.operatorOnly.chat.unresolved = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
              <Separator />
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'クルーからの切断'} />
                </WrapSubSubTitle>
                <Label text={'クルーから案件終了をされた際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することができます。'}
                    value={operatorOnly.chat.crewDisconnect}
                    onChange={e => {
                      systemMessages.operatorOnly.chat.crewDisconnect = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
            </SubArea>
            <SubArea>
              <WrapSubTitle>
                <SubTitle text={'案件登録'} />
              </WrapSubTitle>
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'カテゴリ設定時'} />
                </WrapSubSubTitle>
                <Label text={'カテゴリを設定して保存された際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することが出来ます。'}
                    value={operatorOnly.createRoom.registCategory}
                    onChange={e => {
                      systemMessages.operatorOnly.createRoom.registCategory = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
            </SubArea>
            <WrapTitle>
              <StyleArrow size={16} />
              <Title size={16} text="クルー側に表示されるもの" />
            </WrapTitle>
            <SubArea>
              <WrapSubTitle>
                <SubTitle text={'チャット中'} />
              </WrapSubTitle>
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'オペレータ接続完了時'} />
                </WrapSubSubTitle>
                <Label text={'待ちからオペレータに割り振りがされた際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することが出来ます。'}
                    value={crewOnly.chat.assignedOperator}
                    onChange={e => {
                      systemMessages.crewOnly.chat.assignedOperator = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
              <Separator />
              <SubSubArea>
                <WrapSubSubTitle>
                  <SubSubTitle text={'オペレータ再接続完了時'} />
                </WrapSubSubTitle>
                <Label text={'再接続リクエストによってオペレータが割り振りされた際のメッセージです。'} />
                <WrapInput>
                  <InputMessage
                    type="text"
                    theme="gray"
                    placeholder={'クルーやオペレーターにシステムが自動で応答する際の発話を変更することができます。'}
                    value={crewOnly.chat.reassignedOperator}
                    onChange={e => {
                      systemMessages.crewOnly.chat.reassignedOperator = e.target.value;
                      this.setState({ systemMessages });
                    }}
                  />
                </WrapInput>
              </SubSubArea>
            </SubArea>
          </div>
          <Footer>
            <SaveButton type="button" label="保存" kind="primary" onClick={this.save} disabled={isLoading} />
            <CancelButton type="button" label="*キャンセル" onClick={this.cancel} disabled={isLoading} />
          </Footer>
        </Wrap>
      </StyleBase>
    );
  }
}

const StyleBase = styled(Base)`
  position: relative;
  overflow: scroll;
`;

const Wrap = styled.div`
  margin: 5px;
`;

const Footer = styled.div`
  background-color: #ffffff;
  padding: 15px 50px 0 0;
  margin-left: -20px;
  text-align: right;
  border-top: 2px solid #fafafa;
  position: fixed;
  bottom: 0;
  width: calc(100% - 180px);
  height: 70px;
`;

const WrapTitle = styled.div`
  margin-bottom: 20px;
`;

const WrapSubTitle = styled.div`
  margin-bottom: 20px;
`;

const WrapSubSubTitle = styled.div`
  margin-bottom: 10px;
`;

const Title = styled(Label)`
  font-weight: bold;
`;

const SubTitle = styled(Label)`
  font-weight: bold;
  color: #338cd8;
`;

const SubSubTitle = styled(Label)`
  font-weight: bold;
`;

const SubArea = styled.div`
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 40px;
`;

const SubSubArea = styled.div`
  padding-left: 10px;
  margin-top: 20px;
`;

const Separator = styled.div`
  border-top: solid 1px #e8e8e8;
`;

const WrapInput = styled.div`
  margin: 5px 0 30px 10px;
`;

const InputMessage = styled(Input)`
  border-radius: 5px;
  padding: 5px;
  height: 40px;
  width: 100%;
`;

const StyleArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

const SaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const CancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(UtteranceAdmin);
