import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { QaHistory, QaHistoryJSON } from './models';

export const set = createAction(SET, (json: QaHistoryJSON[]) => {
  return json.map(
    (qa: QaHistoryJSON) =>
      ({
        questionId: qa.attributes.question_id,
        fileFormat: qa.attributes.file_format,
        userName: qa.attributes.user_name,
        skillName: qa.attributes.skill_name,
        fileName: qa.attributes.file_name,
        createdAt: qa.attributes.created_at,
      } as QaHistory),
  ) as QaHistory[];
})<QaHistory[]>();
