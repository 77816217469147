import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET, CHECK, CHECK_ALL, EDIT, DELETE } from './constants';
import { Flag } from './models';

export type FlagState = {
  flags: Flag[];
};

const initialState: FlagState = {
  flags: [],
};

const flags = createReducer<Flag[], Action>(initialState.flags)
  .handleType(SET, (state, action: Action) => action.payload)
  .handleType(CHECK, (state, action: Action) => {
    const index = action.payload;
    state[index].isChecked = !state[index].isChecked;
    return [...state];
  })
  .handleType(CHECK_ALL, (state, _action) => {
    const isCheckedAll = state.every(operator => operator.isChecked);
    return state.map(operator => {
      operator.isChecked = !isCheckedAll;
      return operator;
    });
  })
  .handleType(EDIT, (state, action: Action) => action.payload)
  .handleType(DELETE, (state, action: Action) => action.payload);
export default combineReducers({ flags });
