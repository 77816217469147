import styled from 'styled-components';
import { ReactComponent as Loupe } from './svg/loupe.svg';

type Props = {
  size: number;
};

const iconLoupe = styled(Loupe)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size * 1.027}px`};
  fill: #95a1ac;
`;

export default iconLoupe;
