import axios from './httpClient';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
  operator_id?: number;
  business_id?: number;
};

export const Skills = {
  getAll: (option: Option = { all: false }) => axios.get('skills', { params: option }).catch(error => error.response),
  get: (id: number) => axios.get(`skills/${id}`).catch(error => error.response),
  put: (data: object) => axios.put('skills', data, {}).catch(error => error.response),
  post: (data: object) => axios.post('skills', data, {}).catch(error => error.response),
  getStatsAll: (option: Option) => axios.get(`reports/skills`, { params: option }).catch(error => error.response),
  getStats: (id: number) => axios.get(`reports/skills/${id}`, {}).catch(error => error.response),
  reorder: (data: object) => axios.post('skills/reorder', data, {}).catch(error => error.response),
};
