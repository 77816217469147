import styled from 'styled-components';
import { ReactComponent as Edit } from './svg/edit.svg';

type Props = {
  size: number;
  disabled?: boolean;
  onClick?: () => void;
};

const IconEdit = styled(Edit)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  opacity: ${(props: Props) => (props.disabled ? 0.4 : 1)};
  cursor: ${(props: Props) => (props.disabled ? 'default' : 'pointer')};
`;

export default IconEdit;
