import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { Business, Skill, Tag, BusinessDetail, BusinessJSON } from './models';

export const set = createAction(SET, (json: BusinessJSON[]) =>
  json.map(business => {
    const attributes = business.attributes;
    return {
      businessId: attributes.business_id,
      name: attributes.name,
    } as Business;
  }),
)<Business[]>();

export const setDetail = createAction(SET, (json: BusinessJSON[]) => {
  const businesses: BusinessDetail[] = [];
  json.map(business => {
    const attributes = business.attributes;
    const skills: Skill[] = [];
    attributes.skills.map(skill => {
      const tags: Tag[] = [];
      skill.tags.map(tag => {
        tags.push({
          tagId: tag.tag_id,
          name: tag.name,
        });
        return tags;
      });
      skills.push({
        skillId: skill.skill_id,
        name: skill.name,
        color: skill.color,
        tags: tags,
        isChecked: false,
      });
      return skills;
    });

    businesses.push({
      businessId: attributes.business_id,
      name: attributes.name,
      skills: skills,
      isChecked: false,
    });
    return business;
  });
  return businesses;
})<BusinessDetail[]>();
