import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { BlackList } from './models';

export type BlackListState = {
  blackLists: BlackList[];
};

const initialState: BlackListState = {
  blackLists: [],
};

const blacklists = createReducer<BlackList[], Action>(initialState.blackLists).handleType(
  SET,
  (state, action: Action) => action.payload,
);

export default combineReducers({ blacklists });
