import * as React from 'react';
import styled from 'styled-components';
import Loading from './Loading';

type Props = {
  children?: React.ReactNode;
  theme?: 'white' | 'gray';
  label?: string;
  loading?: boolean;
};

// SelectBox のためのコンポーネント
const Box: React.FC<Props> = (props: Props) => {
  const { theme, label, loading } = props;
  let Parent;
  if (theme && theme === 'gray') {
    Parent = GrayBox;
  } else {
    Parent = WhiteBox;
  }

  return (
    <Parent>
      {loading && <Loading type={'spokes'} width={16} height={16} color={'#000000'} />}
      {loading && <div style={{ width: '3px' }} />}
      <Label>{label}</Label>
    </Parent>
  );
};

Box.defaultProps = {
  label: '',
  loading: false,
};

const BaseBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 60px;
  height: 100%;
  padding: 11px 30px 11px 10px;
  line-height: 1.2;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  pointer-events: none;
  :before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    right: 15px;
    width: 9px;
    height: 2px;
    background-color: #707070;
    border-radius: 1px;
    transform: rotate(45deg);
  }
  :after {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    width: 9px;
    height: 2px;
    background-color: #707070;
    border-radius: 1px;
    transform: rotate(135deg);
  }
`;

const GrayBox = styled(BaseBox)`
  background-color: #fafafa;
`;

const WhiteBox = styled(BaseBox)`
  background-color: #ffffff;
`;

const Label = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default Box;
