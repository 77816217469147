import React from 'react';
import styled from 'styled-components';
import Label from '../atoms/Label';
import Input from '../atoms/Input';
import IconCheck2 from '../atoms/icons/IconCheck2';
import IconArrow from '../atoms/icons/IconArrow';
import IconEdit from '../atoms/icons/IconEdit';
import IconDelete from '../atoms/icons/IconDelete';
import { categoryModels } from '../../features/category';

enum CategoryType {
  Category,
  Sub,
  SubSub,
}

interface Props {
  isOpen: boolean;
  isEditing: boolean;
  stats: categoryModels.Category[];
  onClick?: (eventName: string, categoryType: CategoryType, cateIdx: number, subIdx: number, subsubIdx: number) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    categoryType: CategoryType,
    cateIdx: number,
    subIdx: number,
    subsubIdx: number,
  ) => void;
}

const SkillCategory: React.FC<Props> = props => {
  // const { stats } = props;
  return (
    <Wrap>
      <Header>
        <TitleIconArrow
          size={16}
          isOpen={props.isOpen}
          onClick={() => props.onClick('toggle', CategoryType.Category, -1, -1, -1)}
        />
        <CategoryName text="カテゴリ" />
      </Header>
      {!props.isOpen ? (
        <div />
      ) : (
        <Area>
          <InnerTitle text="階層を持つカテゴリ" size={14} />
          <Label text="大中小のそれぞれの階層が結びつきを持つカテゴリを設定できます。" size={12} />
          <Head>
            <CellCategory>大カテゴリ</CellCategory>
            <CellSubCategory>中カテゴリ</CellSubCategory>
            <CellSubSubCategory>小カテゴリ</CellSubSubCategory>
          </Head>
          {props.stats.map((category, i) => (
            <SubArea key={`SubArea${i}`}>
              <Category>
                <CategoryIconArrow
                  size={14}
                  isOpen={category.isOpen}
                  onClick={() => props.onClick('toggle', CategoryType.Category, i, -1, -1)}
                />
                {!category.isEditing ? (
                  <CategoryName text={category.name} />
                ) : (
                  <InputCategoryName
                    key={`categoryName${i}`}
                    name={`categoryName${i}`}
                    theme="gray"
                    type="text"
                    value={category.name}
                    onChange={e => props.onChange(e, CategoryType.Category, i, -1, -1)}
                  />
                )}
                <WrapAction>
                  {category.isEditing ? (
                    <Commit
                      size={12}
                      fill={'#338ed8'}
                      onClick={() => props.onClick('commit', CategoryType.Category, i, -1, -1)}
                    />
                  ) : (
                    <Edit
                      size={12}
                      onClick={() => props.onClick('edit', CategoryType.Category, i, -1, -1)}
                      disabled={props.isEditing}
                    />
                  )}
                  <Delete
                    size={12}
                    disabled={props.isEditing && !category.isEditing}
                    onClick={() => props.onClick('delete', CategoryType.Category, i, -1, -1)}
                  />
                </WrapAction>
              </Category>
              {category.isOpen ? (
                <Wrap>
                  <SubCategories>
                    <WrapSubCategory>
                      {category.subCategories.map((subCategory, j) => (
                        <SubCategory key={`SubCategory${i}_${j}`}>
                          <SubCategoryTitleCell>
                            {!subCategory.isEditing ? (
                              <SubCategoryName text={subCategory.name} />
                            ) : (
                              <InputSubCategoryName
                                key={`subCategoryName${i}_${j}`}
                                name={`subCategoryName${i}_${j}`}
                                theme="gray"
                                type="text"
                                value={subCategory.name}
                                onChange={e => props.onChange(e, CategoryType.Sub, i, j, -1)}
                              />
                            )}
                            <WrapAction>
                              {subCategory.isEditing ? (
                                <Commit
                                  size={12}
                                  fill={'#338ed8'}
                                  onClick={() => props.onClick('commit', CategoryType.Sub, i, j, -1)}
                                />
                              ) : (
                                <Edit
                                  size={12}
                                  onClick={() => props.onClick('edit', CategoryType.Sub, i, j, -1)}
                                  disabled={props.isEditing}
                                />
                              )}
                              <Delete
                                size={12}
                                onClick={() => props.onClick('delete', CategoryType.Sub, i, j, -1)}
                                disabled={props.isEditing && !subCategory.isEditing}
                              />
                            </WrapAction>
                          </SubCategoryTitleCell>
                          <SubSubCategories>
                            {subCategory.subSubCategories.map((subSubCategory, k) => (
                              <SubSubCategory key={`SubSubCategory${i}_${j}_${k}`}>
                                {!subSubCategory.isEditing ? (
                                  <SubSubCategoryName text={subSubCategory.name} />
                                ) : (
                                  <InputSubSubCategoryName
                                    key={`subSubCategoryName${i}_${j}_${k}`}
                                    name={`subSubCategoryName${i}_${j}_${k}`}
                                    theme="gray"
                                    type="text"
                                    value={subSubCategory.name}
                                    onChange={e => props.onChange(e, CategoryType.SubSub, i, j, k)}
                                  />
                                )}
                                <WrapAction>
                                  {subSubCategory.isEditing ? (
                                    <Commit
                                      size={12}
                                      fill={'#338ed8'}
                                      onClick={() => props.onClick('commit', CategoryType.SubSub, i, j, k)}
                                    />
                                  ) : (
                                    <Edit
                                      size={12}
                                      onClick={() => props.onClick('edit', CategoryType.SubSub, i, j, k)}
                                      disabled={props.isEditing}
                                    />
                                  )}
                                  <Delete
                                    size={12}
                                    onClick={() => props.onClick('delete', CategoryType.SubSub, i, j, k)}
                                    disabled={props.isEditing && !subSubCategory.isEditing}
                                  />
                                </WrapAction>
                              </SubSubCategory>
                            ))}
                            <SubSubCategoryAdd>
                              <AddSubSubCategory
                                text="＋小カテゴリ追加"
                                onClick={() =>
                                  props.onClick('add', CategoryType.SubSub, i, j, subCategory.subSubCategories.length)
                                }
                              />
                            </SubSubCategoryAdd>
                          </SubSubCategories>
                        </SubCategory>
                      ))}
                    </WrapSubCategory>
                  </SubCategories>
                  <SubCategoryAdd>
                    <AddSubCategory
                      text="＋中カテゴリ追加"
                      onClick={() => props.onClick('add', CategoryType.Sub, i, category.subCategories.length, -1)}
                    />
                  </SubCategoryAdd>
                </Wrap>
              ) : (
                <Wrap />
              )}
            </SubArea>
          ))}
          <SubAreaAdd>
            <CategoryAdd>
              <AddCategory
                text="＋大カテゴリ追加"
                onClick={() => props.onClick('add', CategoryType.Category, props.stats.length, -1, -1)}
              />
            </CategoryAdd>
          </SubAreaAdd>
        </Area>
      )}
    </Wrap>
  );
};

interface IconArrowInterface {
  size: number;
  isOpen: boolean;
}

const Header = styled.div`
  height: 45px;
`;

const Wrap = styled.div``;

const TitleIconArrow = styled(IconArrow)`
  width: ${(props: IconArrowInterface) => `${props.size}px`};
  transform: ${(props: IconArrowInterface) => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #000000;
  margin-right: 12px;
`;

const CategoryIconArrow = styled(IconArrow)`
  width: ${(props: IconArrowInterface) => `${props.size}px`};
  transform: ${(props: IconArrowInterface) => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #a7a7a7;
  margin-right: 12px;
`;

const Area = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
  height: auto;
  overflow: hidden;

  padding: 20px;
`;

const InputName = styled(Input)`
  border-radius: 5px;
  height: 30px;
  padding: 5px;
`;

const InputCategoryName = styled(InputName)`
  width: 75%;
`;

const InputSubCategoryName = styled(InputName)`
  width: 65%;
`;

const InputSubSubCategoryName = styled(InputName)`
  width: 75%;
  margin-top: -5px;
`;

const SubArea = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;

  padding: 10px 0px 0px 20px;
`;

const SubAreaAdd = styled(SubArea)`
  background-color: #338cd8;
  padding-bottom: 10px;
`;

const InnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  font-weight: bold;
`;

const Head = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Cell = styled.div`
  display: table-cell;
  padding: 10px 20px 9px;
  border-bottom: 1px solid #f5f5f5;
`;

const CellCategory = styled(Cell)`
  width: 130px;
`;

const CellSubCategory = styled(Cell)`
  width: 200px;
`;

const CellSubSubCategory = styled(Cell)``;

const Category = styled.div`
  margin-bottom: 10px;
  padding-right: 10px;
`;

const SubCategories = styled.table`
  margin-left: 120px;
`;

const WrapSubCategory = styled.tbody``;

const SubCategory = styled.tr`
  //:nth-child(even) {
  //  background-color: #f8f8f8;
  //}
`;

const SubCategoryTitleCell = styled.td`
  padding: 10px;
  border: 1px solid #f5f5f5;
  min-width: 180px;
`;

const CategoryName = styled(Label)`
  font-weight: bold;
`;

const SubCategoryName = styled(Label)`
  display: block;
  width: 65%;
  float: left;
`;

const SubSubCategoryName = styled(Label)`
  display: block;
  width: 75%;
  float: left;
`;

const SubSubCategories = styled.td`
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
  padding: 10px;
  vertical-align: top;
`;

const SubSubCategory = styled.div`
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  padding-top: 13px;
  padding-left: 10px;
  margin: 2px;
  float: left;
  width: calc(50% - 5px);
  height: 50px;

  background-color: #f8f8f8;
`;

const WrapAction = styled.div`
  float: right;
  padding-right: 5px;
  width: 50px;
`;

const CategoryAdd = styled.div``;

const SubCategoryAdd = styled.div`
  margin-left: 120px;
  margin-bottom: 10px;
  padding-top: 10px;
`;

const SubSubCategoryAdd = styled.div`
  clear: both;
  padding-top: 5px;
  padding-left: 5px;
`;

const Commit = styled(IconCheck2)`
  padding: 5px;
`;

const Edit = styled(IconEdit)`
  padding: 5px;
`;

const Delete = styled(IconDelete)`
  padding: 5px;
`;

const Add = styled(Label)`
  color: #338cd8;
`;

const AddCategory = styled(Label)`
  color: #ffffff;
  cursor: pointer;
`;

const AddSubCategory = styled(Add)`
  cursor: pointer;
`;

const AddSubSubCategory = styled(Add)`
  cursor: pointer;
`;

export default SkillCategory;
