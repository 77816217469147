import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import Input from '../../components/atoms/Input';
import IconArrow from '../../components/atoms/icons/IconArrow';
import TextArea from '../../components/atoms/TextArea';
import SelectBox from '../../components/atoms/SelectBox';
import SelectGroupBox from '../../components/atoms/SelectGroupBox';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import Table, { Cell, Header as THeader } from '../../components/atoms/Table';
import TableScroll from '../../components/atoms/TableScroll';
import Checkbox from '../../components/atoms/Checkbox';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import Tab from '../../components/atoms/Tab';
import { OptionInterface as Option } from '../../utils/interface';
import { notificationActions, notificationModels } from '../../features/notification';
import { businessModels } from '../../features/business';
import { skillModels } from '../../features/skill';
import { Notifications as NotificationsAPI } from '../../api/admin';
import { AxiosResponse } from 'axios';
import { OptionGroupInterface, OptionInterface } from '../../utils/interface';

const ORDER_INDEX_SEND = 0;
const ORDER_INDEX_LEVEL = 1;
const ORDER_INDEX_RATE = 2;

const ORDER_NONE = 0;
const ORDER_ASC = 1;
const ORDER_DESC = 2;

const orderArrows = ['', '↑', '↓'];

type Props = {
  children?: React.ReactNode;
  notifications: notificationModels.Notification[];
  businesses: businessModels.Business[];
  skills: skillModels.Skill[];
  operatorSkills: skillModels.OperatorSkill[];
  businessTable: notificationModels.Business[];

  toggleBusiness: (index: number) => {};
  checkSkill: (bizIndex: number, skillIndex: number) => {};
  checkAllSkills: (index: number) => {};
  checkAll: () => {};
  setNotifications: (notifications: notificationModels.NotificationJSON[], skills: skillModels.Skill[]) => void;
  addNotification: (notification: notificationModels.Notification) => Promise<AxiosResponse>;
  setBusinesses: (businesses: businessModels.Business[], skills: skillModels.Skill[]) => void;
};

type State = {
  notification: notificationModels.Notification;
  notifications: notificationModels.Notification[];
  tabIndex: number;
  condSkillId: number;
  options: Option[];
  isLoading: boolean;
  order: { index: number; order: number };
  optionGroups: OptionGroupInterface[];
  selectedOption: OptionInterface;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    businesses: state.business.businesses,
    skills: state.skill.skills,
    operatorSkills: state.skill.operatorSkills,
    notifications: state.notification.notifications,
    businessTable: state.notification.businessTable,
  };
};

const mapDispatchToProps = {
  setNotifications: notificationActions.set,
  toggleBusiness: notificationActions.toggle,
  checkSkill: notificationActions.check,
  checkAllSkills: notificationActions.checkAllSkills,
  checkAll: notificationActions.checkAll,
  setBusinesses: notificationActions.setBiz,
};

class NotificationsAdmin extends React.Component<Props, State> {
  readonly stats = {
    notificationLevelsOption: [
      { label: '通常', value: 'Info', key: 'Info', checked: false },
      { label: '緊急', value: 'Emergency', key: 'Emergency', checked: false },
    ],
  };

  getInitOption(): OptionInterface {
    return {
      key: 'opt_0',
      label: '全ての窓口',
      value: 0,
      checked: true,
    };
  }

  readonly state: State = {
    notification: {
      title: '',
      body: '',
      type: this.stats.notificationLevelsOption[0].value,
      is_deleted: false,
      notificationId: 0,
      skills: [],
    },
    condSkillId: 0,
    tabIndex: 0,
    isLoading: true,
    notifications: [],
    options: [{ key: 'select', label: '全ての窓口', value: '0', checked: true }],
    order: { index: ORDER_INDEX_SEND, order: ORDER_NONE },
    optionGroups: [
      {
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [this.getInitOption()],
      },
    ],
    selectedOption: this.getInitOption(),
  };

  get options() {
    return [
      ...this.state.options,
      ...this.props.skills.map(skill => ({
        key: 'select',
        label: skill.name,
        value: `${skill.skillId}`,
        checked: skill.skillId === this.state.condSkillId,
      })),
    ];
  }

  componentDidMount(): void {
    console.log(this.props.operatorSkills);
    const ids = this.props.operatorSkills.map(item => item.skillId);
    this.fetchNotifications({ all: true, skill_ids: ids });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isStorePropsExist = this.props.skills.length > 0 && this.props.businesses.length > 0;

    if (prevProps.skills !== this.props.skills) {
      this.setState({ options: this.options });
    }

    if (this.props.operatorSkills.length > 0 && this.props.businessTable.length === 0) {
      this.props.setBusinesses(this.props.businesses, this.props.operatorSkills.sort(this.sortSkills));
    }

    const initOptionState = isStorePropsExist && this.state.optionGroups.length === 1;
    if (initOptionState && this.props.operatorSkills.length > 0) {
      //絞り込みスキル
      const optionGroups: OptionGroupInterface[] = [];
      optionGroups.push({
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [
          {
            label: '全ての窓口',
            value: '0 0',
            checked: true,
            key: 'opt_0',
          },
        ],
      });

      for (let i = 0; i < this.props.businesses.length; i++) {
        const business = this.props.businesses[i];
        const options: OptionInterface[] = [];
        const operatorSkills = this.props.operatorSkills.filter(skill => skill.businessId === business.businessId);

        for (let j = 0; j < operatorSkills.length; j++) {
          options.push({
            label: operatorSkills[j].name,
            value: `${business.businessId} ${operatorSkills[j].skillId}`,
            checked: false,
            key: `opt_${operatorSkills[j].skillId}`,
          });
        }

        optionGroups.push({
          optionGroupId: this.props.businesses[i].businessId,
          optionGroupLabel: this.props.businesses[i].name,
          options: options,
        });
      }
      this.setState({ optionGroups: optionGroups, isLoading: false });
    }
  }

  sortSkills(a: skillModels.Skill, b: skillModels.Skill): number {
    if (a.order !== b.order) {
      return a.order - b.order;
    } else {
      return a.skillId - b.skillId;
    }
  }

  fetchNotifications(option: {}): void {
    this.setState({ isLoading: true });
    console.log(option);
    NotificationsAPI.getAll(option)
      .then(response => {
        if (response.status === 200) {
          this.props.setNotifications(response.data, this.props.skills);
          if (this.props.operatorSkills.length > 0) {
            this.props.setBusinesses(this.props.businesses, this.props.operatorSkills.sort(this.sortSkills));
          }
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ notifications: this.props.notifications, isLoading: false });
      });
  }

  handleSend = (): void => {
    const skillIds: number[] = [];

    if (this.state.notification.title.length === 0) {
      alert('件名が空です');
      return;
    }
    if (this.state.notification.body.length === 0) {
      alert('本文が空です');
      return;
    }
    this.setState({ isLoading: true });

    this.props.businessTable.forEach(business =>
      business.skills
        .filter(skill => skill.isChecked)
        .forEach(skill => {
          skillIds.push(skill.skillId);
        }),
    );

    const addParam: notificationModels.PostNotificationParam = {
      title: this.state.notification.title,
      body: this.state.notification.body,
      type: this.state.notification.type,
      skill_ids: skillIds,
    };

    NotificationsAPI.add(addParam)
      .then(response => {
        if (response.status === 200) {
          const initNotification: notificationModels.Notification = {
            title: '',
            body: '',
            type: this.stats.notificationLevelsOption[0].value,
            is_deleted: false,
            notificationId: 0,
            skills: [],
          };
          const copyBizTbl = this.props.businessTable.slice();
          copyBizTbl.forEach(biz => {
            biz.isChecked = false;
            biz.skills.forEach(skill => {
              skill.isChecked = false;
            });
          });

          this.setState({ notification: initNotification });
          //this.setState({ notification: initNotification, businessTable: copyBizTbl });
          this.fetchNotifications({ all: true, order: this.getOrderParam() });
          this.setState({ isLoading: false });
          alert('配信が完了しました');
        } else {
          console.log(response);
          this.setState({ isLoading: false });
          alert('API サーバーで問題が発生しました');
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        alert('API サーバーで問題が発生しました');
      });
  };

  isCheckAll = (): boolean => {
    const isPropsDataEmpty =
      this.props.businessTable.length === 0 || this.props.skills.length === 0 || this.props.businesses.length === 0;
    if (this.state.isLoading || isPropsDataEmpty) return false;

    let skillsNum = 0;
    this.props.businessTable.forEach(business => {
      skillsNum += business.skills.length;
    });

    let checkedSkillsNum = 0;
    this.props.businessTable.forEach(business => {
      checkedSkillsNum += business.skills.filter(skill => skill.isChecked).length;
    });

    return skillsNum === checkedSkillsNum;
  };

  handleChangeLevel = (name: string, value: Option): void => {
    const notification = this.state.notification;
    notification.type = `${value.value}`;
    this.setState({ notification: notification });
  };

  handleChangeText = (eventName: string, text: string): void => {
    const notification = this.state.notification;
    if (eventName === 'title') {
      notification.title = text;
    } else if (eventName === 'body') {
      notification.body = text;
    }
    this.setState({ notification: notification });
  };

  getTypeName(notificationType: string): string {
    if (notificationType === this.stats.notificationLevelsOption[0].value) {
      return this.stats.notificationLevelsOption[0].label;
    } else if (notificationType === this.stats.notificationLevelsOption[1].value) {
      return this.stats.notificationLevelsOption[1].label;
    } else {
      return '';
    }
  }

  handleChangeTab = (tabIndex: number): void => {
    const skillId = this.state.condSkillId;
    let businessId = 0;
    if (tabIndex > 0) {
      businessId = this.props.businesses[tabIndex - 1].businessId;
    }

    this.setState({ tabIndex: tabIndex }, () => {
      this.reloadNotifications(businessId, [+skillId]);
    });
  };

  handleOnSkillChanged = (name: string, option?: OptionInterface): void => {
    if (option === undefined) return;
    const optionValue = option.value.toString().split(' ')[1];
    const skillId = parseInt(optionValue, 10);
    this.setState({ condSkillId: skillId });
    const tabIndex = this.state.tabIndex;

    let businessId = 0;
    if (tabIndex > 0) {
      businessId = this.props.businesses[tabIndex - 1].businessId;
    }

    this.reloadNotifications(businessId, [+skillId]);

    const optionGroups = this.state.optionGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      for (let j = 0; j < optionGroup.options.length; j++) {
        if (optionGroup.options[j].value === option.value) {
          this.setState({
            selectedOption: optionGroup.options[j],
          });
          return;
        }
      }
    }
  };

  reloadNotifications = (businessId: number, skillIds: number[]): void => {
    if (businessId !== 0 && skillIds[0] !== 0) {
      this.fetchNotifications({ all: true, skill_ids: skillIds, business_id: businessId, order: this.getOrderParam() });
    } else if (businessId === 0 && skillIds[0] !== 0) {
      this.fetchNotifications({ all: true, skill_ids: skillIds, order: this.getOrderParam() });
    } else if (businessId !== 0 && skillIds[0] === 0) {
      const ids = this.props.operatorSkills.map(item => item.skillId);
      this.fetchNotifications({ all: true, skill_ids: ids, business_id: businessId, order: this.getOrderParam() });
    } else {
      const ids = this.props.operatorSkills.map(item => item.skillId);
      this.fetchNotifications({ all: true, skill_ids: ids, order: this.getOrderParam() });
    }
    this.setState({ isLoading: false });
  };

  //-------------------------------------------------------------------
  //ソート[START]
  //-------------------------------------------------------------------
  orderValue(index: number): string {
    if (index === this.state.order.index) {
      return orderArrows[this.state.order.order];
    } else {
      return '';
    }
  }

  orderChange(index: number): void {
    let order = ORDER_NONE;
    if (this.state.order.index === index) {
      order = (this.state.order.order + 1) % 3;
    } else {
      order = ORDER_ASC;
    }

    this.setState(
      {
        isLoading: true,
        order: { index: index, order: order },
      },
      () => {
        let businessId = 0;
        if (this.state.tabIndex > 0) {
          businessId = this.props.businesses[this.state.tabIndex - 1].businessId;
        }
        const skillId = this.state.condSkillId;

        this.reloadNotifications(businessId, [+skillId]);
      },
    );
  }

  getOrderParam(): string {
    if (this.state.order.order === ORDER_NONE) return null;

    let order = '';
    if (this.state.order.index === 0) order = 'created_at';
    if (this.state.order.index === 1) order = 'type';
    if (this.state.order.index === 2) order = 'open_rate';

    if (this.state.order.order === ORDER_DESC) order = '-' + order;

    return order;
  }
  //-------------------------------------------------------------------
  //ソート[END]
  //-------------------------------------------------------------------

  render() {
    const { stats, isCheckAll } = this;
    const { isLoading, notification, optionGroups, selectedOption } = this.state;
    const { businessTable, checkSkill, checkAllSkills, checkAll, toggleBusiness, notifications } = this.props;

    return (
      <Base>
        <ScreenLoading
          width={'150px'}
          height={'150px'}
          type={'spin'}
          color={'#black'}
          isloading={isLoading || businessTable.length === 0}
        />
        <Area>
          <Header>
            <StyleIconArrow size={16} isOpen={true} />
            <Title size={16} text="お知らせの作成" />
          </Header>
          <SendArea>
            <InnerTitleArea>
              <InnterTitle text="お知らせの作成" />
            </InnerTitleArea>
            <Wrap>
              <Table>
                <CondRow>
                  <CondCell>
                    <WrapCondTitle>
                      <Label text={'お知らせレベル'} />
                    </WrapCondTitle>
                  </CondCell>
                  <CondCell>
                    <WrapCondContents>
                      <WrapCondItem>
                        <NotificationSelectBox
                          name={'notificationLevel'}
                          options={stats.notificationLevelsOption}
                          theme="gray"
                          loading={isLoading}
                          disabled={isLoading}
                          selectedOption={
                            stats.notificationLevelsOption.filter(
                              option => option.value === this.state.notification.type,
                            )[0]
                          }
                          onChanged={this.handleChangeLevel}
                        />
                      </WrapCondItem>
                    </WrapCondContents>
                  </CondCell>
                </CondRow>
                <CondRow>
                  <CondCell>
                    <WrapCondTitle>
                      <Label text={'窓口配信'} />
                    </WrapCondTitle>
                  </CondCell>
                  <CondCell>
                    <WrapCondContents>
                      <WrapCondItem>
                        <CheckboxAll
                          key={'chkall'}
                          label={'全ての窓口'}
                          disabled={isLoading}
                          checked={isCheckAll()}
                          onClick={() => checkAll()}
                        />
                        {businessTable.map(
                          (business, i) =>
                            business.skills.length > 0 && (
                              <WrapBusiness key={`WrapBusinedd${business.businessId}`}>
                                <StyleIconArrow size={16} isOpen={business.isOpen} onClick={() => toggleBusiness(i)} />
                                <Checkbox
                                  key={`chkbiz${business.businessId}`}
                                  label={business.name}
                                  disabled={isLoading}
                                  checked={business.isChecked}
                                  onClick={() => checkAllSkills(i)}
                                />
                                {business.isOpen && (
                                  <WrapSkills>
                                    {business.skills.map((skill, j) => (
                                      <WrapSkill key={`WrapSkill${skill.skillId}`}>
                                        <Checkbox
                                          key={`chkskill${skill.skillId}`}
                                          label={skill.name}
                                          disabled={isLoading}
                                          checked={skill.isChecked}
                                          onClick={() => checkSkill(i, j)}
                                        />
                                      </WrapSkill>
                                    ))}
                                  </WrapSkills>
                                )}
                              </WrapBusiness>
                            ),
                        )}
                      </WrapCondItem>
                    </WrapCondContents>
                  </CondCell>
                </CondRow>
                <CondRow>
                  <CondCell>
                    <WrapCondTitle>
                      <Label text={'件名'} />
                    </WrapCondTitle>
                  </CondCell>
                  <CondCell>
                    <WrapCondContents>
                      <WrapCondItem>
                        <KeywordText
                          type={'text'}
                          theme="gray"
                          name={'txttitle'}
                          value={notification.title}
                          placeholder={'お知らせの件名'}
                          onChange={e => this.handleChangeText('title', e.target.value)}
                        />
                      </WrapCondItem>
                    </WrapCondContents>
                  </CondCell>
                </CondRow>
                <CondRow>
                  <CondCell>
                    <WrapCondTitle>
                      <Label text={'本文'} />
                    </WrapCondTitle>
                  </CondCell>
                  <CondCell>
                    <WrapCondContents>
                      <WrapCondItem>
                        <KeywordTextArea
                          theme="gray"
                          rows={5}
                          maxRows={5}
                          name={'txtcontents'}
                          value={notification.body}
                          placeholder={'お知らせの本文'}
                          onChange={e => this.handleChangeText('body', e.target.value)}
                        />
                      </WrapCondItem>
                    </WrapCondContents>
                  </CondCell>
                </CondRow>
                <CondRow>
                  <CondCell></CondCell>
                  <CondCell>
                    <WrapButton>
                      <AddButton
                        type="button"
                        label={'配信'}
                        kind={'primary'}
                        disabled={isLoading}
                        onClick={this.handleSend}
                      />
                    </WrapButton>
                  </CondCell>
                </CondRow>
              </Table>
            </Wrap>
          </SendArea>
          <Header>
            <StyleIconArrow size={16} isOpen={true} />
            <Title size={16} text="お知らせの配信履歴" />
          </Header>
          <TabArea>
            <StyleTab
              onClick={() => this.handleChangeTab(0)}
              selected={this.state.tabIndex === 0}
              label={'全ての事業者'}
            />
            {this.props.businesses !== undefined &&
              this.props.businesses.map((business, i) => (
                <StyleTab
                  key={`Tab${i}`}
                  onClick={() => this.handleChangeTab(business.businessId)}
                  selected={this.state.tabIndex === business.businessId}
                  label={business.name}
                />
              ))}
          </TabArea>
          <HistoryArea>
            <WrapCond>
              <InnterTitle text="窓口の絞り込み" />
              <WrapCondItem>
                <SkillSelectGroupBox
                  name={'skills'}
                  optionGroups={optionGroups}
                  selectedOption={selectedOption}
                  theme="gray"
                  loading={isLoading}
                  disabled={isLoading}
                  onChanged={this.handleOnSkillChanged}
                />
              </WrapCondItem>
            </WrapCond>
            <Se />
            <WrapHistory>
              <TableScroll>
                <THeader>
                  <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_SEND)}>
                    配信日<Order>{this.orderValue(ORDER_INDEX_SEND)}</Order>
                  </HeaderCell>
                  <HeaderCell>件名</HeaderCell>
                  <HeaderCell>本文</HeaderCell>
                  <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_LEVEL)}>
                    お知らせレベル<Order>{this.orderValue(ORDER_INDEX_LEVEL)}</Order>
                  </HeaderCell>
                  <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_RATE)}>
                    開封率<Order>{this.orderValue(ORDER_INDEX_RATE)}</Order>
                  </HeaderCell>
                </THeader>
                {isLoading ? (
                  <InfoRow />
                ) : (
                  notifications.map((notification, i) => (
                    <InfoRow key={i}>
                      <InfoCell>{notification.createdAt}</InfoCell>
                      <InfoCell>{notification.title}</InfoCell>
                      <InfoCell>{notification.body}</InfoCell>
                      <InfoCell>{this.getTypeName(notification.type)}</InfoCell>
                      <InfoCell>{`${notification.openUserNumber} / ${notification.targetUserNumber}`}</InfoCell>
                    </InfoRow>
                  ))
                )}
              </TableScroll>
            </WrapHistory>
          </HistoryArea>
        </Area>
      </Base>
    );
  }
}

interface IconArrowInterface {
  size: number;
  isOpen: boolean;
}

const Area = styled.div``;

const SendArea = styled.div`
  border-radius: 16px 16px 16px 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 50px;
`;

const HistoryArea = styled.div`
  border-radius: 0px 0px 16px 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  margin-bottom: 50px;
`;

const Header = styled.div`
  margin-bottom: 10px;
`;

const Wrap = styled.div``;

const Title = styled(Label)`
  font-weight: bold;
`;

const WrapCond = styled.div`
  margin: 10px 0px 10px 25px;
  height: auto;
`;

const WrapHistory = styled.div`
  height: auto;
`;

const WrapCondTitle = styled.div`
  text-align: right;
  width: 100%;
  padding-top: 7px;
`;

const WrapCondContents = styled.div`
  width: 100%;
  float: left;
  padding: 5px 15px 5px 5px;
`;

const WrapBusiness = styled.div`
  margin: 10px 0px 20px 5px;
`;

const WrapSkills = styled.div`
  border-radius: 10px 10px 10px 10px;
  background-color: #f5f5f5;
  padding: 5px 3px 0px 10px;
  margin: 10px 0px 0px 15px;
  height: auto;
  overflow: hidden;
`;

const WrapSkill = styled.div`
  margin: 5px 5px 0px 5px;
  height: 35px;
  float: left;
`;

const WrapButton = styled.div`
  margin: 10px 10px 20px 10px;
  float: right;
`;

const AddButton = styled(Button)`
  width: 150px;
`;

const WrapCondItem = styled.div``;

const InnerTitleArea = styled.div`
  margin-left: 15px;
`;

const InnterTitle = styled(Label)`
  color: #338cd8;
  font-weight: bold;
`;

const CheckboxAll = styled(Checkbox)`
  margin-top: 7px;
`;

const StyleIconArrow = styled(IconArrow)`
  width: ${(props: IconArrowInterface) => `${props.size}px`};
  transform: ${(props: IconArrowInterface) => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #000000;
  margin-right: 12px;
`;

const NotificationSelectBox = styled(SelectBox)`
  width: 200px;
`;

const SkillSelectGroupBox = styled(SelectGroupBox)`
  width: 300px;
`;

const KeywordText = styled(Input)`
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  font-size: 14px;
  width: 50%;
`;

const KeywordTextArea = styled(TextArea)`
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
`;

const Se = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  border: none;
  border-top: 1px #f5f5f5 solid;
`;

const CondRow = styled.div`
  display: table-row;
  width: 100%;
`;

const CondCell = styled.span`
  display: table-cell;
  vertical-align: top;
  padding: 5px;
  border-bottom: solid 1px #f8f8f8;

  :nth-child(2n + 1) {
    width: 120px;
    padding-top: 10px;
  }
  :nth-child(2n) {
    //width: 80%;
  }
  //border: solid 1px;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  //cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;

  :nth-child(5n + 1) {
    min-width: 40px;
    text-align: center;
  }
  :nth-child(5n + 2) {
  }
  :nth-child(5n + 3) {
  }
  :nth-child(5n + 4) {
    min-width: 120px;
    text-align: center;
  }
  :nth-child(5n) {
    min-width: 100px;
    text-align: center;
  }
`;

const Order = styled.span``;

const HeaderCell = styled(Cell)`
  :nth-child(1) {
    cursor: pointer;
  }
  :nth-child(4) {
    cursor: pointer;
  }
  :nth-child(5) {
    cursor: pointer;
  }
`;

const TabArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyleTab = styled(Tab)`
  width: calc(100% / 4);
`;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsAdmin);
