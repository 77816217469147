import styled from 'styled-components';
import { ReactComponent as Check } from './svg/check2.svg';

type Props = {
  size: number;
  fill: string;
  onClick?: () => void;
};

const IconCheck2 = styled(Check)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  cursor: pointer;
  stroke: ${(props: Props) => `${props.fill || '#338ed8'}`};
`;

export default IconCheck2;
