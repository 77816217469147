import { createAction } from 'typesafe-actions';
import { SET, OP_SET } from './constants';
import { Skill, SkillJSON, OperatorSkill, OperatorSkillJSON } from './models';

export const set = createAction(SET, (json: SkillJSON[]) => {
  return json.map(
    (skill: SkillJSON) =>
      ({
        businessId: skill.attributes.business_id,
        skillId: skill.attributes.skill_id,
        name: skill.attributes.name,
        color: skill.attributes.color,
        order: skill.attributes.order,
      } as Skill),
  ) as Skill[];
})<Skill[]>();

export const opSet = createAction(OP_SET, (json: OperatorSkillJSON[]) => {
  return json.map(
    (operatorSkill: OperatorSkillJSON) =>
      ({
        businessId: operatorSkill.attributes.business_id,
        skillId: operatorSkill.attributes.skill_id,
        name: operatorSkill.attributes.name,
        order: operatorSkill.attributes.order,
      } as OperatorSkill),
  ) as OperatorSkill[];
})<OperatorSkill[]>();
