import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import IconArrow from '../atoms/icons/IconArrow';
import { skillModels } from '../../features/skill';

interface Props {
  stats: skillModels.SkillDetailJSON;
}

const SkillCard: React.FC<Props> = props => {
  const { stats } = props;

  return (
    <Wrapper color={stats.overview.color === undefined ? '#ffffff' : stats.overview.color}>
      <Inner>
        <Content>
          <Header>
            <Title>
              <BusinessName>{stats.overview.business_name}</BusinessName>
              <SkillName>{stats.overview.name}</SkillName>
            </Title>
            <Status>
              <StatusLabel>{`現在の対応状況 ${stats.overview.current_items}/${stats.overview.max_num_of_waiting_items}`}</StatusLabel>
              <StatusBar>
                <CurrentStatus
                  per={Math.floor((stats.overview.current_items / stats.overview.max_num_of_waiting_items) * 100)}
                />
              </StatusBar>
            </Status>
          </Header>
          <Body>
            <Info>
              <InfoTable>
                <InfoHead>
                  <InfoCell>待ち人数上限</InfoCell>
                  <InfoCell>待ち人数</InfoCell>
                  <InfoCell>待ち時間（最長）</InfoCell>
                  <InfoCell>応対人数</InfoCell>
                  <InfoCell>残り受可人数</InfoCell>
                  <InfoCell>受付可人数</InfoCell>
                  <InfoCell>離席人数</InfoCell>
                </InfoHead>
                <InfoRow>
                  <InfoCell>{stats.overview.max_num_of_waiting_items}</InfoCell>
                  <InfoCell>{stats.overview.current_items}</InfoCell>
                  <InfoCell>{moment(stats.overview.max_waiting_time).format('HH:mm')}</InfoCell>
                  <InfoCell>{stats.overview.current_items}</InfoCell>
                  <InfoCell>{stats.overview.remaining_acceptable_items}</InfoCell>
                  <InfoCell>{stats.overview.total_acceptable_items}</InfoCell>
                  <InfoCell>{stats.overview.num_of_leaving}</InfoCell>
                </InfoRow>
              </InfoTable>
            </Info>
          </Body>
        </Content>
        <Edit>
          <StyleIconArrow size={16} />
        </Edit>
      </Inner>
    </Wrapper>
  );
};

interface CardInterface {
  color: string;
}

interface CurrentStatusInterface {
  per: number;
}

const Wrapper = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  border-left: 24px solid ${(props: CardInterface) => `${props.color}`};
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.04);
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: calc(100% - 40px);
  padding: 24px;
  background-color: #ffffff;
`;

const Header = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

const Title = styled.h3``;

const BusinessName = styled.p`
  font-size: 1.6rem;
`;

const SkillName = styled.p`
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.4;
`;

const Status = styled.div`
  width: 50%;
  max-width: 460px;
  margin-left: 10%;
`;

const StatusLabel = styled.p`
  font-size: 1.4rem;
  text-align: center;
`;

const StatusBar = styled.div`
  width: 100%;
  height: 24px;
  background-color: #fafafa;
  border: 1px solid #ededed;
  border-radius: 72px;
`;

const CurrentStatus = styled.span`
  display: inline-block;
  width: ${(props: CurrentStatusInterface) => `${props.per}%`};
  height: 24px;
  background-color: #d83333;
  border-radius: 72px;
  margin-top: -1px;
  margin-left: -1px;
`;

const Body = styled.div`
  margin-top: 20px;
  padding-top: 24px;
  border-top: 1px solid #f0f0f0;
`;

const Info = styled.div``;

const InfoTable = styled.div`
  display: table;
  width: 100%;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
`;

const InfoHead = styled(InfoRow)`
  background-color: #f5f5f5;
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const Edit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  background-color: #fafafa;
  border-left: 1px solid #f3f3f3;
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #707070;
  transform: rotate(270deg);
`;

export default SkillCard;
