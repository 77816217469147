import * as Axios from 'axios';
import { CHAT_API } from '../config';

const axios = Axios.default.create({
  baseURL: `https://${CHAT_API.apiUrl}/${CHAT_API.apiPath}/${CHAT_API.apiVer}`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
  transformResponse: [
    function(data) {
      if (data) {
        const json = JSON.parse(data);
        if (json.data) {
          return json.data;
        }
        return json;
      }
      return data;
    },
  ],
});

export default axios;
