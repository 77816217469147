import axios from './httpClient';

export const Qa = {
  post: (formData: FormData) =>
    axios
      .post(`qa`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => error.response),
  delete: (formData: FormData) =>
    axios
      .post(`qa/delete`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => error.response),
};
