import * as React from 'react';
import 'normalize.css';
import styled from 'styled-components';
import GlobalStyle from '../../GlobalStyle';

interface Props {
  children: React.ReactNode;
}

const Base: React.FC<Props> = props => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Wrapper {...props}>{props.children}</Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export default Base;
