import React from 'react';
import styled from 'styled-components';
import Label from '../atoms/Label';
import Input from '../atoms/Input';
import IconArrow from '../atoms/icons/IconArrow';
import IconForward from '../atoms/icons/IconForward';
import IconCheck2 from '../atoms/icons/IconCheck2';
import IconEdit from '../atoms/icons/IconEdit';
import IconDelete from '../atoms/icons/IconDelete';
import { tagModels } from '../../features/tag';

interface Props {
  isOpen: boolean;
  isEditing: boolean;
  stats: tagModels.Tag[];
  onClick?: (eventName: string, index: number) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

const SkillQuestion: React.FC<Props> = props => {
  // const { stats } = props;
  if (props.stats == null) return <Wrap />;
  return (
    <Wrap>
      <Header>
        {props.isOpen ? (
          <TitleIconArrow size={16} onClick={() => props.onClick('toggle', -1)} />
        ) : (
          <TitleIconForward size={16} onClick={() => props.onClick('toggle', -1)} />
        )}
        <TitleLabel text={'質問タグ'} />
      </Header>
      {!props.isOpen ? (
        <div />
      ) : (
        <Area>
          <InnerTitle text="登録中の質問タグ" size={14} />
          <SubArea>
            {props.stats.map((tag, i) => (
              <TagArea key={`TagArea${i}`}>
                <WrapTag>
                  <Tag>
                    {!tag.isEditing ? (
                      <Label text={tag.name} />
                    ) : (
                      <InputTagName
                        key={`tagName${i}`}
                        name={`tagName${i}`}
                        theme="gray"
                        type="text"
                        value={tag.name}
                        onChange={e => props.onChange(e, i)}
                      />
                    )}
                  </Tag>
                </WrapTag>
                <WrapAction>
                  {tag.isEditing ? (
                    <Commit size={12} fill={'#338ed8'} onClick={() => props.onClick('commit', i)} />
                  ) : (
                    <Edit size={12} onClick={() => props.onClick('edit', i)} disabled={props.isEditing} />
                  )}
                  <Delete
                    size={12}
                    disabled={props.isEditing && !tag.isEditing}
                    onClick={() => props.onClick('delete', i)}
                  />
                </WrapAction>
              </TagArea>
            ))}
          </SubArea>
          <AddFlagArea>
            <AddFlagLabel text="＋質問タグを登録" size={12} onClick={() => props.onClick('add', props.stats.length)} />
          </AddFlagArea>
        </Area>
      )}
    </Wrap>
  );
};

const Header = styled.div`
  height: 45px;
`;

const Wrap = styled.div``;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const TitleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const TitleIconForward = styled(IconForward)`
  stroke: #000000;
  margin-right: 12px;
`;

const Area = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
  padding: 15px;
  height: auto;
  overflow: hidden;
`;

const InnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  font-weight: bold;
`;

const SubArea = styled.div`
  border-radius: 16px 16px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-bottom: none;
  padding-bottom: 10px;
  height: auto;
  overflow: hidden;
`;

const TagArea = styled.div`
  border-radius: 5px;
  height: 40px;
  width: 180px;
  margin: 10px 0px 0px 10px;
  padding: 5px 0px 5px 5px;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  height: 60px;
  overflow: hidden;

  float: left;
`;

const WrapTag = styled.div`
  float: left;
  width: 60%;
`;

const Tag = styled.div``;

const AddFlagArea = styled.div`
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 5px;
`;

const InputName = styled(Input)`
  border-radius: 5px;
  height: 30px;
  padding: 5px;
`;

const InputTagName = styled(InputName)`
  width: 100%;
`;

const AddFlagLabel = styled(Label)`
  color: #338cd8;
  cursor: pointer;
  margin: 5px 0px 5px 10px;

  //color: gray;
  //corsor: default;
`;

const WrapAction = styled.div`
  float: right;
  padding-right: 5px;
  width: 50px;
`;

const Commit = styled(IconCheck2)`
  padding: 5px;
`;

const Edit = styled(IconEdit)`
  padding: 5px;
`;

const Delete = styled(IconDelete)`
  padding: 5px;
`;

export default SkillQuestion;
