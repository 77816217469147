import React from 'react';
import styled from 'styled-components';
import Logo from '../../components/atoms/Logo';
import CurrentUser from '../../components/molecules/CurrentUser';
import { Link } from 'react-router-dom';

type Props = {
  menuIndex: number;
  name: string;
  visible: boolean;
  onClick: (menuIndex: number) => void;
};

const SideMenu: React.FC<Props> = (props: Props) => {
  const SideMenuItems = [
    { linkTo: '/operator', name: 'オペレーター管理', key: 'operator', visible: true },
    { linkTo: '/skills_admin', name: '窓口管理', key: 'skills', visible: true },
    { linkTo: '/notifications_admin', name: 'お知らせ管理', key: 'notifications', visible: true },
    { linkTo: '/flags_admin', name: 'フラグ管理', key: 'flags', visible: true },
    { linkTo: '/blacklists_admin', name: 'ブラックリスト管理', key: 'blacklists', visible: props.visible },
    { linkTo: '/shop_use_limit', name: '店舗別利用制限', key: 'shop', visible: props.visible },
    { linkTo: '/utterances_admin', name: 'システム発話管理', key: 'utterances', visible: props.visible },
    { linkTo: '/reports_download', name: '実績ダウンロード', key: 'reports', visible: true },
    { linkTo: '/qa_admin', name: 'QA管理', key: 'qa', visible: true },
    { linkTo: '/answer_setting', name: 'AI回答の制御', key: 'ai', visible: true },
    { linkTo: '/morphological_analysis', name: '形態素解析結果の取得', key: 'morphological', visible: props.visible },
  ];

  return (
    <Wrapper>
      <LogoArea>
        <Logo />
      </LogoArea>
      <Container>
        <CurrentUser role="システム管理者" name={props.name} />
        <Nav>
          <List>
            {SideMenuItems.map((menu, i) => (
              <Item key={`menu_${menu.key}`} visible={menu.visible}>
                <StyledLink to={menu.linkTo} isCurrent={props.menuIndex === i} onClick={() => props.onClick(i)}>
                  {menu.name}
                </StyledLink>
              </Item>
            ))}
          </List>
        </Nav>
      </Container>
    </Wrapper>
  );
};

type LinkProps = {
  isCurrent: boolean;
};

type ItemProps = {
  visible?: boolean;
};

const Wrapper = styled.div`
  width: 190px;
  height: 100%;
  background-color: #1d354a;
`;

const LogoArea = styled.div`
  padding: 19px 20px 18px;
  box-sizing: border-box;
`;

const Container = styled.div`
  border-top: 1px solid #344a5c;
`;

const Nav = styled.nav`
  margin-top: 64px;
`;

const List = styled.ul``;

const Item = styled.li`
  display: ${(props: ItemProps) => (props.visible === false ? 'none' : 'block')};
`;

const StyledLink = styled(Link)<LinkProps>`
  text-decoration: none;
  display: block;
  padding: 18px 20px 17px;
  color: #ffffff;
  font-size: 1.4rem;
  background-color: ${props => (props.isCurrent ? '#1a5488' : 'inherit')};
  border-left: ${props => (props.isCurrent ? '8px solid #1d7ace;' : 'none')};
  box-sizing: border-box;
  cursor: ${props => (props.isCurrent ? 'default' : 'pointer')};
`;

export default SideMenu;
