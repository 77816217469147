import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import { AxiosResponse } from 'axios';
import IconArrow from '../../components/atoms/icons/IconArrow';
import { OptionGroupInterface, OptionInterface } from '../../utils/interface';
import { operatorActions, operatorModels } from '../../features/operator';
import { qaHistoryActions, qaHistoryModels } from '../../features/qa';
import { businessModels } from '../../features/business';
import { skillActions, skillModels } from '../../features/skill';
import Base from '../../components/molecules/Base';
import Input from '../../components/atoms/Input';
import SelectGroupBox from '../../components/atoms/SelectGroupBox';
import IconLoupe from '../../components/atoms/icons/IconLoupe';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import { Operators as OperatorAPI, QaHistory as QaHistoryAPI } from '../../api/admin';
import DateRangePicker from '../../components/molecules/DateRangePicker';
import { Reports } from '../../api/admin';
import ModalWindow from '../organisms/ModalWindow';
import RadioButton from '../../components/atoms/RadioButton';
import InputFile from '../../components/atoms/InputFile';
import { userModels } from '../../features/user';
import { Qa as QaAPI } from '../../api/chat';
import Table, { Cell, Header as THeader } from '../../components/atoms/Table';
import moment from 'moment';
import Checkbox from '../../components/atoms/Checkbox';
import { RouteComponentProps } from 'react-router-dom';
interface Params {
  all?: boolean;
  skill_ids?: number[];
  order?: string;
}

type Props = {
  from?: Date;
  to?: Date;
  operators: operatorModels.Operator[];
  businesses: businessModels.Business[];
  skills: skillModels.OperatorSkill[];
  user: userModels.User;
  qaHistories: qaHistoryModels.QaHistory[];
  onDayChange?: (from?: Date, to?: Date) => void;
  setOperators: (operators: operatorModels.OperatorJSON) => {};
  setQaHistories: (qaHistories: qaHistoryModels.QaHistoryJSON) => {};
};

type State = {
  isLoading?: boolean;
  output: {
    isLoading: boolean;
  };
  registered: {
    isLoading: boolean;
  };
  skillIds: number[];
  from: Date;
  to: Date;
  keyword: string;
  roomId: string;
  optionGroups: OptionGroupInterface[];
  selectedOption: OptionInterface;
  optionOperatorGroups: OptionGroupInterface[];
  selectedOperatorOption: OptionInterface;
  userIds: number[];
  isPopup: boolean;
  isRegisterPopup: boolean;
  isUpload: boolean;
  isSuggest: boolean;
  operators: operatorModels.Operator[];
};

const mapStateToProps = (state: Types.RootState, ownProps: RouteComponentProps<{ operator_id: string }>) => {
  return {
    ...ownProps,
    ...state.user,
    operators: state.operator.operators,
    businesses: state.business.businesses,
    skills: state.skill.operatorSkills,
    qaHistories: state.qaHistory.qaHistories,
  };
};

const mapDispatchToProps = {
  setOperators: operatorActions.set,
  setQaHistories: qaHistoryActions.set,
  setOperatorSkills: skillActions.set,
};

class QaAdmin extends React.Component<Props, State> {
  private myRef: React.RefObject<HTMLInputElement>;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  get query() {
    const { skillIds, from, to, keyword, roomId, userIds, isSuggest } = this.state;
    let ids = skillIds;
    let operatorIds = [userIds[1]];
    if (skillIds[0] === 0) {
      ids = this.props.skills.map(item => item.skillId);
    }

    if (userIds[0] === 0) {
      operatorIds = this.state.operators.map(item => item.userId);
      operatorIds.push(0);
    }
    from.setHours(0);
    to.setHours(23);

    return {
      skill_ids: ids,
      start: from,
      end: to,
      start_hour: from.getHours(),
      end_hour: to.getHours(),
      keywords: keyword.replace('　', ' ').split(' '),
      room_id: roomId,
      user_ids: operatorIds,
      is_suggest: isSuggest,
    };
  }

  get qaManagements(): qaHistoryModels.QaHistory[] {
    if (this.state.isLoading) {
      const qaManagements: qaHistoryModels.QaHistory[] = [];
      return qaManagements;
    } else {
      return [...this.props.qaHistories];
    }
  }

  getInitOptionGroup(): OptionGroupInterface[] {
    return [
      {
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [this.getInitOption()],
      },
    ];
  }

  getInitOption(): OptionInterface {
    return {
      key: 'opt_0',
      label: '全ての窓口',
      value: '0 0',
      checked: true,
    };
  }

  getInitOperatorOptionGroup(): OptionGroupInterface[] {
    return [
      {
        optionGroupId: 0,
        optionGroupLabel: '全ての最終更新者',
        options: [this.getInitOperatorOption()],
      },
    ];
  }

  getInitOperatorOption(): OptionInterface {
    return {
      key: 'opt_0',
      label: '全ての最終更新者',
      value: '0 0',
      checked: true,
    };
  }

  readonly state: State = {
    isLoading: true,
    output: {
      isLoading: true,
    },
    registered: {
      isLoading: true,
    },
    skillIds: [0],
    from: new Date(),
    to: new Date(),
    keyword: '',
    roomId: '',
    optionGroups: this.getInitOptionGroup(),
    selectedOption: this.getInitOption(),
    optionOperatorGroups: this.getInitOperatorOptionGroup(),
    selectedOperatorOption: this.getInitOperatorOption(),
    userIds: [0, 1],
    isPopup: false,
    isRegisterPopup: false,
    isUpload: true,
    isSuggest: false,
    operators: [],
  };

  readonly stats = {
    TextProps: {
      type: 'text',
      name: 'condKeyword',
      placeholder: '検索したいキーワードを入力',
      value: '',
    },
    RoomTextProps: {
      type: 'text',
      name: 'condRoom',
      placeholder: '検索したい案件IDを入力',
      value: '',
    },
  };

  componentDidMount(): void {
    if (this.props.skills.length > 0) {
      const ids = this.props.skills.map(operatorSkill => operatorSkill.skillId);
      this.fetchOperators({ all: true, skill_ids: ids, order: 'users.user_id' });
    }

    this.fetchQaHistories();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isStorePropsExist =
      this.props.skills.length > 0 && this.props.businesses.length > 0 && this.props.operators.length > 0;
      if (isStorePropsExist && this.state.optionGroups.length === 1 && this.state.optionOperatorGroups.length === 1) {
        this.setState({ isLoading: true });
        //絞り込みスキル
        const optionGroups: OptionGroupInterface[] = [];
        optionGroups.push({
          optionGroupId: 0,
          optionGroupLabel: '全ての窓口',
          options: [
            {
              label: '全ての窓口',
              value: '0 0',
              checked: true,
              key: 'opt_0',
            },
          ],
        });

        if (this.props.skills.length > 0) {
          for (let i = 0; i < this.props.businesses.length; i++) {
            const business = this.props.businesses[i];
            const options: OptionInterface[] = [];
            const skills = this.props.skills.filter(skill => skill.businessId === business.businessId);

            for (let j = 0; j < skills.length; j++) {
              options.push({
                label: skills[j].name,
                value: `${business.businessId} ${skills[j].skillId}`,
                checked: false,
                key: `opt_${skills[j].skillId}`,
              });
            }

            optionGroups.push({
              optionGroupId: this.props.businesses[i].businessId,
              optionGroupLabel: this.props.businesses[i].name,
              options: options,
            });
          }
        }

        if (this.props.operators.length  === 0) {
          const ids = this.props.skills.map(operatorSkill => operatorSkill.skillId);
          this.fetchOperators({ all: true, skill_ids: ids, order: 'users.user_id' });
        }

        const optionOperatorGroups: OptionGroupInterface[] = [];
        optionOperatorGroups.push({
          optionGroupId: 0,
          optionGroupLabel: '全ての最終更新者',
          options: [
            {
              label: '全ての最終更新者',
              value: '0 0',
              checked: true,
              key: 'opt_0',
            },
          ],
        });

        if (this.props.operators.length > 0) {
          const targetRole = [1, 2]
          const operators = this.props.operators.filter(operator => targetRole.includes(Number(operator.role)));
          this.setState({operators: operators})
          const options: OptionInterface[] = [];
          for (let i = 0; i < operators.length; i++) {
            options.push({
              label: operators[i].name + operators[i].first_name,
              value: `1 ${operators[i].userId}`,
              checked: false,
              key: `opt_${operators[i].userId}`,
            });
          }
          options.push({
            label: 'QA管理者',
            value: `1 0`,
            checked: false,
            key: `opt_0`,
          });
          optionOperatorGroups.push({
            optionGroupId: 1,
            optionGroupLabel: '最終更新者',
            options: options,
          });
        this.setState({
          optionGroups: optionGroups,
          optionOperatorGroups: optionOperatorGroups,
          isLoading: false,
          output: { isLoading: false },
          registered: { isLoading: false },
        });
      }
    }
  }

  handleOnKeywordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ keyword: event.target.value });
  };

  handleOnRoomIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ roomId: event.target.value });
  };

  handleOnSkillChanged = (name: string, option?: OptionInterface): void => {
    const skillId = Number(option.value.toString().split(' ')[1]);
    const optionGroups = this.state.optionGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      for (let j = 0; j < optionGroup.options.length; j++) {
        if (optionGroup.options[j].value === option.value) {
          optionGroup.options[j].checked = true;
          this.setState({
            skillIds: [+skillId],
            selectedOption: optionGroup.options[j],
            optionGroups: optionGroups,
          });
          return;
        }
      }
    }
  };

  handleOnOperatorChanged = (name: string, option?: OptionInterface): void => {
    const groupId = Number(option.value.toString().split(' ')[0]);
    const userId = Number(option.value.toString().split(' ')[1]);
    const optionGroups = this.state.optionOperatorGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      for (let j = 0; j < optionGroup.options.length; j++) {
        if (optionGroup.options[j].value === option.value) {
          optionGroup.options[j].checked = true;
          this.setState({
            userIds: [+groupId, +userId],
            selectedOperatorOption: optionGroup.options[j],
            optionOperatorGroups: optionGroups,
          });
          return;
        }
      }
    }
  };

  invalidResponse = (response: AxiosResponse): boolean => {
    return !response || response.status !== 200;
  };

  handleOnDownload = (): void => {
    this.setState({ output: { isLoading: true } });
    Reports.fetchQa(this.query).then(res => this.handleResponse('QA管理', res));
    this.setState({ isPopup: !this.state.isPopup });
  };

  handleResponse = (reportName: string, response: AxiosResponse) => {
    if (this.invalidResponse(response)) {
      this.setState({ output: { isLoading: false } });
      return alert(`${reportName}レポートの出力に失敗しました。\n${response['data'] ? response.data.message : ''}`);
    }

    const { filename, url } = response.data.attributes;
    this.download(url, filename);
    this.setState({ output: { isLoading: false } });
  };

  download = (url: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  fetchOperators(option: Params = {}): void {
    this.setState({ isLoading: true });

    OperatorAPI.getAll(option)
      .then(response => {
        if (response.status === 200) {
          this.props.setOperators(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => this.setState({ isLoading: false }))
      .catch(response => {
        alert(response);
        this.setState({ isLoading: false });
      });
  }

  fetchQaHistories(): void {
    this.setState({ isLoading: true });

    QaHistoryAPI.getAll(null)
      .then(response => {
        if (response.status === 200) {
          this.props.setQaHistories(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => this.setState({ isLoading: false }))
      .catch(response => {
        alert(response);
        this.setState({ isLoading: false });
      });
  }

  handleOnClickPopup = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isPopup: !this.state.isPopup });
  };

  handleOnClickRegisterPopup = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isRegisterPopup: !this.state.isRegisterPopup });
  };

  handleOnUploadPopup = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isUpload: !this.state.isUpload });
  };

  handleUploadCsv = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return;
    const file = event.currentTarget.files[0];
    const isUpload = window.confirm(`登録します。よろしいですか？`);
    if (isUpload) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append('user_id', JSON.stringify({ user_id: this.props.user.userId }));
      formData.append('skill_ids', JSON.stringify({ skill_ids: this.props.skills.map(item => item.skillId) }));
      formData.append('csv', file);
      this.setState({ registered: { isLoading: true } });
      if (this.state.isUpload) {
        this.createUploadCSV(formData);
      } else {
        this.daleteCSV(formData);
      }
    }
    event.currentTarget.value = null;
    this.setState({ isRegisterPopup: !this.state.isRegisterPopup });
  };

  handleSetIsSuggest = (): void => {
    this.setState({ isSuggest: !this.state.isSuggest });
  };

  createUploadCSV = (formData: FormData) => {
    QaAPI.post(formData).then(response => {
      if (response.status === 200) {
        alert('保存しました。');
        this.insertQaHistory(formData);
      } else {
        alert(`保存できませんでした。${response.data.error}`);
      }
      this.setState({ registered: { isLoading: false } });
    });
  };

  insertQaHistory = (formData: FormData) => {
    let file;
    if (formData.get('csv') instanceof File) {
      const val = formData.get('csv') as File;
      file = val.name;
    } else {
      file = formData.get('csv');
    }
    const addParam = {
      file_format: this.state.isUpload ? 0 : 1,
      user_id: this.props.user.userId,
      skill_id: this.props.skills.length > 1 ? 0 : this.props.skills[0].skillId,
      file_name: file,
    };
    QaHistoryAPI.add(addParam).then(response => {
      if (response.status !== 204) {
        alert(`保存できませんでした。${response.data.message}`);
      }
      this.fetchQaHistories();
    });
  };

  daleteCSV = (formData: FormData) => {
    QaAPI.delete(formData).then(response => {
      if (response.status === 200) {
        alert('保存しました。');
        this.insertQaHistory(formData);
      } else {
        alert(`保存できませんでした。${response.data.error}`);
      }
      this.setState({ registered: { isLoading: false } });
    });
  };

  fileUpload = () => {
    this.myRef.current.click();
  };

  render() {
    const {
      from,
      to,
      optionGroups,
      optionOperatorGroups,
      selectedOption,
      selectedOperatorOption,
      output,
      registered,
      isLoading,
      isPopup,
      isRegisterPopup,
      isUpload,
      isSuggest,
    } = this.state;
    const { qaManagements, stats } = this;
    return (
      <Base>
        <Header>
          <StyleIconArrow size={14} />
          <TitleLabel text="QA管理" />
        </Header>
        <Area>
          <WrapSearchLabel>
            <StyleSearchLabel text="■検索" size={14} />
          </WrapSearchLabel>
          <WrapCond>
            <WrapCond1>
              <StyleLabel text="窓口の絞り込み" size={12} />
              <WrapCondItem>
                <StyleSelectGroupBox
                  name={'skills'}
                  optionGroups={optionGroups}
                  selectedOption={selectedOption}
                  theme="gray"
                  loading={isLoading}
                  disabled={isLoading}
                  onChanged={this.handleOnSkillChanged}
                />
              </WrapCondItem>
            </WrapCond1>
            <WrapCond2>
              <StyleLabel text="文言検索" size={12} />
              <WrapCondItem>
                <StyleIconLoupe size={14} />
                <StyleSearchInput
                  type={stats.TextProps.type}
                  theme="gray"
                  name={stats.TextProps.name}
                  value={this.state.keyword}
                  placeholder={stats.TextProps.placeholder}
                  onChange={this.handleOnKeywordChange}
                />
              </WrapCondItem>
            </WrapCond2>
          </WrapCond>
          <WrapCond>
            <WrapCond1>
              <StyleLabel text="案件ID" size={12} />
              <WrapCondItem>
                <StyleIconLoupe size={14} />
                <StyleSearchRoomInput
                  type={stats.RoomTextProps.type}
                  theme="gray"
                  name={stats.RoomTextProps.name}
                  value={this.state.roomId}
                  placeholder={stats.RoomTextProps.placeholder}
                  onChange={this.handleOnRoomIdChange}
                />
              </WrapCondItem>
            </WrapCond1>
            <WrapCond2>
              <StyleLabel text="最終更新者" size={12} />
              <WrapCondItem2>
                <StyleSelectGroupBox
                  name={'operators'}
                  optionGroups={optionOperatorGroups}
                  selectedOption={selectedOperatorOption}
                  theme="gray"
                  loading={isLoading}
                  disabled={isLoading}
                  onChanged={this.handleOnOperatorChanged}
                />
              </WrapCondItem2>
            </WrapCond2>
          </WrapCond>
          <WrapCond>
            <WrapCond1>
              <WrapSubTitle>
                <StyleLabel text={'発話日付'} size={12} />
              </WrapSubTitle>
              <WrapCondItem>
                <DateRangePicker
                  from={from}
                  to={to}
                  onDayChange={(from, to) => {
                    if (from) this.setState({ from });
                    if (to) this.setState({ to });
                  }}
                />
              </WrapCondItem>
            </WrapCond1>
            <WrapCond2>
              <WrapSubTitle>
                <StyleLabel text={'「回答にコピー／はい押下日」列を追加'} size={12} />
              </WrapSubTitle>
              <WrapCondItem>
            <Checkbox
             label={'回答にコピー／はい押下日'}
                    disabled={isLoading}
                    checked={isSuggest}
                    onClick={() => this.handleSetIsSuggest()} 
                  />
             </WrapCondItem>
             </WrapCond2>
          </WrapCond>
          <WrapButton>
            <AddButton
              type="button"
              label={'一括登録'}
              kind={'primary'}
              disabled={registered.isLoading}
              onClick={this.handleOnClickRegisterPopup}
            />
            <AddButton
              type="button"
              label={'データ抽出'}
              kind={'primary'}
              disabled={output.isLoading}
              onClick={this.handleOnClickPopup}
            />
          </WrapButton>
          <Table>
            <THeader>
              <HeaderCell>登録 / 解除</HeaderCell>
              <HeaderCell>オペレータ名</HeaderCell>
              <HeaderCell>対象窓口</HeaderCell>
              <HeaderCell>アップロードファイル</HeaderCell>
              <HeaderCell>実施日時</HeaderCell>
            </THeader>
            {isLoading ? (
              <InfoRow />
            ) : (
              qaManagements.map((qaManagement: qaHistoryModels.QaHistory, i: number) => (
                <InfoRow key={i}>
                  <InfoCell>{qaManagement.fileFormat === 0 ? '登録' : '解除'}</InfoCell>
                  <InfoCell>{qaManagement.userName}</InfoCell>
                  <InfoCell>{qaManagement.skillName}</InfoCell>
                  <InfoCell>{qaManagement.fileName}</InfoCell>
                  <InfoCell>{`${moment(qaManagement.createdAt).format('YYYY/MM/DD HH:mm')}`}</InfoCell>
                </InfoRow>
              ))
            )}
          </Table>
        </Area>
        <ModalWindow
          title="ダウンロード最終確認"
          text="指定した条件でダウンロードを開始します。よろしいですか？"
          isShow={isPopup}
        >
          <WrapModalButton>
            <AddButton type="button" label={'OK'} kind={'primary'} onClick={this.handleOnDownload} />
            <AddButton type="button" label={'キャンセル'} kind={'primary'} onClick={this.handleOnClickPopup} />
          </WrapModalButton>
        </ModalWindow>

        <ModalWindow
          title="ファイル形式選択"
          text="アップロードするファイルを選択し、OKボタンを押してください"
          isShow={isRegisterPopup}
        >
          <WrapModalButton>
            <section>
              <RadioButton value="1" name="chat" text="新規" checked={isUpload} onChange={this.handleOnUploadPopup} />
              <RadioButton value="0" name="chat" text="解除" checked={!isUpload} onChange={this.handleOnUploadPopup} />
            </section>
          </WrapModalButton>
          <InputFile onFileInputChange={this.handleUploadCsv} ref={this.myRef} />
          <WrapModalButton>
            <AddButton type="button" label={'OK'} kind={'primary'} onClick={this.fileUpload} />
            <AddButton type="button" label={'キャンセル'} kind={'primary'} onClick={this.handleOnClickRegisterPopup} />
          </WrapModalButton>
        </ModalWindow>
      </Base>
    );
  }
}

const Header = styled.div`
  margin-bottom: 10px;
`;

const WrapCond = styled.div`
  display: flex;
`;

const WrapCond1 = styled.div`
  width: 50%;
  margin: 10px;
  padding-left: 180px;
`;

const WrapCond2 = styled.div`
  width: 50%;
  margin: 10px;
`;

const WrapCondItem = styled.div`
  margin-top: 20px;
`;

const WrapCondItem2 = styled.div`
  margin-top: 10px;
`;

const WrapButton = styled.div`
  margin: 10px 10px 20px 10px;
  padding-right: 180px;
  display: flex;
  justify-content: flex-end;
`;

const AddButton = styled(Button)`
  width: 150px;
  margin: 5px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleLabel = styled(Label)`
  color: #338cd8;
`;

const WrapSearchLabel = styled.div`
  margin: 30px 0px 10px 10px;
`;

const StyleSearchLabel = styled(Label)`
  padding-left: 180px;
`;

const StyleSelectGroupBox = styled(SelectGroupBox)`
  width: 300px;
`;

const StyleIconLoupe = styled(IconLoupe)`
  position: relative;
  left: 10px;
`;

const StyleSearchInput = styled(Input)`
  padding: 10px 5px 10px 30px;
  margin-left: -15px;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  width: 50%;
  height: 100%;
`;

const StyleSearchRoomInput = styled(Input)`
  padding: 10px 5px 10px 30px;
  margin-left: -15px;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  width: 50%;
  height: 100%;
`;

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  margin-bottom: 30px;
`;

const WrapSubTitle = styled.div`
  margin-bottom: 20px;
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const WrapModalButton = styled.div`
  display: flex;
  justify-content: center;
`;

const HeaderCell = styled(Cell)`
  :nth-child(3) {
    cursor: pointer;
  }
  :nth-child(4) {
    cursor: pointer;
  }
  :nth-child(7) {
    cursor: pointer;
  }
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

export default connect(mapStateToProps, mapDispatchToProps)(QaAdmin);
