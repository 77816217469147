import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import IconArrow from '../../components/atoms/icons/IconArrow';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import ShopUseLimitList from '../../components/molecules/ShopUseLimitList';
import { ShopUseLimit as ShopUseLimitAPI } from '../../api/admin/shopUseLimit';
import PopupFrame from '../../components/molecules/PopupFrame';
import IconClose from '../../components/atoms/icons/IconClose';
import ScreenLoading from '../../components/atoms/ScreenLoading';

type Props = {
  token: string;
  children?: React.ReactNode;
};

type State = {
  isLoading: boolean;
  isWorkingJob: boolean;
  isDownload: boolean;
  isPopup: boolean;
  inUsedCsvName: string;
  rollbackCsvName: string;
  fileId: number;
  downloadFileId: number;
  errorMeassge: string;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    token: state.auth.token,
  };
};

const mapDispatchToProps = {};

class ShopUseLimit extends React.Component<Props, State> {
  readonly state = {
    isLoading: false,
    isWorkingJob: false,
    isDownload: false,
    shopUseLimitStats: [],
    isPopup: false,
    inUsedCsvName: '',
    rollbackCsvName: '',
    fileId: -1,
    downloadFileId: -1,
    errorMessage: '',
  };

  constructor(props: Props) {
    super(props);

    this.uploadCSV = React.createRef();
  }

  componentDidMount() {
    this.getShopUseLimit();

    setInterval(this.getJobProgress, 15000);
  }

  sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

  getJobProgress = () => {
    ShopUseLimitAPI.getProgress().then(res => {
      if (res.status === 200) {
        console.log('=== progres ===');
        if (res.data.downloaded_file_id !== null) {
          this.setState({ downloadFileId: res.data.downloaded_file_id, isDownload: true });
        } else {
          this.setState({ isDownload: false });
        }
        if (res.data.message !== null) {
          this.setState({ errorMessage: res.data.message });
        }
        if (this.state.isWorkingJob === true && res.data.working === false) {
          this.getShopUseLimit();
        }
        this.setState({ isWorkingJob: res.data.working });
      } else {
        alert('処理状況の取得に失敗しました。');
      }
    });
  };

  compare = (a, b) => {
    const createdAt1 = a.attributes.created_at;
    const createdAt2 = b.attributes.created_at;
    let comparison = 0;
    if (createdAt1 < createdAt2) {
      comparison = 1;
    } else if (createdAt1 > createdAt2) {
      comparison = -1;
    }
    return comparison;
  };

  getShopUseLimit = async () => {
    this.setState({ isLoading: true });
    const res = await ShopUseLimitAPI.get();
    if (res.status === 200) {
      if (res.data.length > 0) {
        this.setState({ shopUseLimitStats: res.data.sort(this.compare) });
        const inUsedCsvFile = this.state.shopUseLimitStats.filter(item => {
          if (item.attributes.use_flag) {
            return true;
          } else {
            return false;
          }
        });
        if (inUsedCsvFile.length > 0) {
          this.setState({ inUsedCsvName: inUsedCsvFile[0].attributes.filename });
        }
      }
    } else {
      alert('APIサーバーで問題が発生しました。');
    }
    const resProg = await ShopUseLimitAPI.getProgress();
    if (resProg.status === 200) {
      console.log('=== progres ===');
      if (resProg.data.downloaded_file_id !== null) {
        this.setState({ downloadFileId: resProg.data.downloaded_file_id, isDownload: true });
      } else {
        this.setState({ isDownload: false });
      }
      if (resProg.data.message !== null) {
        this.setState({ errorMessage: resProg.data.message });
      }
      this.setState({ isWorkingJob: resProg.data.working });
    } else {
      alert('処理状況の取得に失敗しました。');
    }
    console.log(this.state.isDownload);
    console.log(this.state.isWorkingJob);
    this.setState({ isLoading: false });
  };

  createUploadCSV = async fileData => {
    this.setState({ isWorkingJob: true });
    this.setState({ isLoading: true });
    const res = await ShopUseLimitAPI.post(fileData);
    if (res.status === 204) {
      alert('アップロードを開始しました。');
    } else {
      alert('アップロードが失敗しました。');
    }
    this.setState({ isLoading: false });
  };

  handleClickUploadCSV = () => {
    this.uploadCSV.current.click();
  };

  handleUploadCsv = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const isUpload = window.confirm(`${file.name}をアップロードしますか？処理に20分ほどかかります。`);
    if (isUpload) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const fileData = new FormData();
      fileData.append('file', file);
      this.createUploadCSV(fileData);
    }
  };

  rollbackCsvFile = async (fileId: number) => {
    this.setState({ isWorkingJob: true });
    this.setState({ isPopup: false });
    this.setState({ isLoading: true });
    const res = await ShopUseLimitAPI.getRollback(fileId);
    if (res.status === 204) {
      await this.getShopUseLimit();
      alert('利用中のCSVファイルを変更を開始しました。');
    } else {
      alert('CSVファイルの変更に失敗しました。');
    }
    this.setState({ isLoading: false });
  };

  handleOnClickPopup = (fileName: string, fileId: number) => {
    this.setState({ fileId: fileId, rollbackCsvName: fileName, isPopup: !this.state.isPopup });
  };

  downloadCsv = async (fileId: number) => {
    this.setState({ isLoading: true });
    const fileData = this.state.shopUseLimitStats.filter(item => item.attributes.file_id === fileId);
    const res = await ShopUseLimitAPI.getDownload(fileId);
    if (res.status === 200) {
      const element = document.createElement('a');
      element.setAttribute('href', `${res.data.attributes.url}`);
      element.setAttribute('download', fileData[0].attributes.filename);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    this.setState({ isLoading: false });
  };

  makeDownloadCsv = async (fileId: number) => {
    this.setState({ isWorkingJob: true });
    const isConfirm = window.confirm(`ダウンロードの準備をしますか？処理に1時間半ほどかかります。`);
    if (isConfirm) {
      this.setState({ isLoading: true });
      const res = await ShopUseLimitAPI.getMakeDownload(fileId);
      if (res.status === 204) {
        alert('ダウンロードの準備を開始しました');
      } else {
        alert('ダウンロードの準備が失敗しました。');
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      shopUseLimitStats,
      isPopup,
      isLoading,
      downloadFileId,
      isDownload,
      isWorkingJob,
      errorMessage,
    } = this.state;
    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <StyleIconArrow size={14} />
          <TitleLabel text="店舗別利用制限" />
        </Header>
        <Area>
          <WrapCond1>
            <StyleLabel text="利用制限CSVの管理" size={12} />
            <Describe>CSVを利用して代理店・店舗ごとに利用できるように窓口を制限することができます。</Describe>
            <WorkingJobLabel text={`処理を実行中です。`} size={12} visible={isWorkingJob} />
            <ErrorMeassge>{errorMessage}</ErrorMeassge>
          </WrapCond1>
          <ImportButton
            type="button"
            label="+ CSVで店舗を追加"
            kind="primary"
            disabled={isWorkingJob}
            onClick={this.handleClickUploadCSV}
          />
          <FileInput type="file" name="uploadCSV" value="" onChange={this.handleUploadCsv} ref={this.uploadCSV} />
          <WrapCond2>
            <ShopUseLimitList
              shopUseLimit={shopUseLimitStats}
              downloadFileId={downloadFileId}
              isWorkingJob={isWorkingJob}
              isDownload={isDownload}
              onClick={this.handleOnClickPopup}
              makeDownloadCsv={this.makeDownloadCsv}
              downloadCsv={this.downloadCsv}
            />
          </WrapCond2>
        </Area>
        <BlackListAdd
          isPopup={isPopup}
          inUsedCsvName={this.state.inUsedCsvName}
          rollbackCsvName={this.state.rollbackCsvName}
          fileId={this.state.fileId}
          onPopupClose={this.handleOnClickPopup}
          onRollback={this.rollbackCsvFile}
        />
      </Base>
    );
  }
}

type VisibleProps = {
  visible?: boolean;
};

const Header = styled.div`
  margin-bottom: 10px;
`;

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding-bottom: 20px;
`;

const WrapCond1 = styled.div`
  float: left;
  margin: 10px;
`;

const WrapCond2 = styled.div`
  margin-right: 20px;
`;

const Describe = styled.div`
  font-size: 15px;
  margin: 5px 10px 10px 10px;
`;

const ErrorMeassge = styled.div`
  font-size: 12px;
  color: #ff0000;
  margin: 5px 10px 10px 10px;
`;

const ImportButton = styled(Button)`
  font-size: 12px;
  float: right;
  height: auto;
  margin: 20px 10px 10px 10px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  font-size: 20px;
  margin: 5px 10px 10px 10px;
`;

const WorkingJobLabel = styled(Label)`
  font-weight: bold;
  font-size: 20px;
  margin: 5px 10px 10px 10px;
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const FileInput = styled.input`
  display: none;
`;

type PopupProps = {
  isPopup: boolean;
  inUsedCsvName: string;
  rollbackCsvName: string;
  fileId: number;
  onPopupClose: (fileName: string, fileId: number) => void;
  onRollback: (fileId: number) => void;
};

class BlackListAdd extends React.Component<PopupProps, State> {
  readonly stats = {
    //assistText: 'クルーIDを入力すると自動で名前を入力します',
    assistText: 'クルーIDを入力後にEnterを押すと該当する名前を入力します',
  };

  render() {
    const { isPopup, fileId, onPopupClose, onRollback } = this.props;

    if (!isPopup) {
      return <div />;
    }
    return (
      <Base>
        <PopupFrame isPopup={isPopup}>
          <PopupTitile>
            <PopupTitleLabel size={16} text="利用制限CSVの巻き戻し" />
            <PopupCloseArea>
              <IconClose onClick={() => onPopupClose('', 0)} />
            </PopupCloseArea>
          </PopupTitile>
          <PopupDescribe>
            <PopupCond>
              過去にアップロードしたCSVファイルに切り替えることで利用制限状況を巻き戻します。
              <br />
              この変更は1時間半ほどかかります。利用中のCSVファイルを切り替えてもよろしいでしょうか？
            </PopupCond>
          </PopupDescribe>
          <PopupCsvName>
            <PopupCond>
              <PopupFileName text="利用中のCSVファイル" />
              {this.props.inUsedCsvName}
            </PopupCond>
            <PopupCond>
              <PopupFileName text="切り替えるCSVファイル" />
              {this.props.rollbackCsvName}
            </PopupCond>
          </PopupCsvName>
          <PopupFooter>
            <PopupCancelButton type="button" label="キャンセル" onClick={() => onPopupClose('', 0)} />
            <PopupSaveButton type="button" label="切り替える" kind="primary" onClick={() => onRollback(fileId)} />
          </PopupFooter>
        </PopupFrame>
      </Base>
    );
  }
}

const PopupFooter = styled.div`
  background-color: #ffffff;
  padding: 0px 30px 0px 0px;
  text-align: right;
  border-top: 1px solid #fafafa;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PopupTitile = styled.div`
  height: 45px;
  padding: 10px 0px 40px 10px;
  margin: 10px 0px 10px 0px;
  border-bottom: 1px solid #e8e8e8;
`;

const PopupDescribe = styled.div`
  padding: 20px 50px 20px 50px;
  margin: 20px 30px 0px 30px
  border: 1px solid #e8e8e8;
  -webkit-border-top-left-radius: 16px;
  -moz-border-top-left-radius: 16px;
  border-top-left-radius: 16px;
  -webkit-border-top-rigth-radius: 16px;
  -moz-border-top-right-radius: 16px;
  border-top-right-radius: 16px;
`;

const PopupCsvName = styled.div`
  padding: 20px 50px 20px 50px;
  margin: 0px 30px 0px 30px
  border: 1px solid #e8e8e8;
  -webkit-border-bottom-left-radius: 16px;
  -moz-border-bottom-left-radius: 16px;
  border-bottom-left-radius: 16px;
  -webkit-border-bottom-rigth-radius: 16px;
  -moz-border-bottom-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const PopupCond = styled.span`
  display: block;
`;

const PopupTitleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  padding: 10px;
`;

const PopupFileName = styled(Label)`
  font-weight: bold;
  padding: 10px;
`;

const PopupCloseArea = styled.div`
  float: right;
  padding-top: 5px;
  margin-right: 20px;
`;

const PopupSaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const PopupCancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ShopUseLimit);
