import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET, OP_SET } from './constants';
import { Skill, OperatorSkill } from './models';

export type SkillsState = {
  skills: Skill[];
  operatorSkills: OperatorSkill[];
};

const initialState: SkillsState = {
  skills: [] as Skill[],
  operatorSkills: [] as OperatorSkill[],
};

const skills = createReducer<SkillsState['skills'], Action>(initialState.skills).handleType(
  SET,
  (state: SkillsState['skills'], action: Action) => action.payload,
);

const operatorSkills = createReducer<SkillsState['operatorSkills'], Action>(initialState.operatorSkills).handleType(
  OP_SET,
  (state: SkillsState['operatorSkills'], action: Action) => action.payload,
);

export default combineReducers({
  skills,
  operatorSkills,
});
