import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import Input from '../../components/atoms/Input';
import IconDelete from '../../components/atoms/icons/IconDelete';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconLoupe from '../../components/atoms/icons/IconLoupe';
import IconClose from '../../components/atoms/icons/IconClose';
import Loading from '../../components/atoms/Loading';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import Table, { Cell, Header as THeader } from '../../components/atoms/Table';
import { Blacklists as BlacklistAPI } from '../../api/admin';
import { blackListActions, blackListModels } from '../../features/blacklist';
import PopupFrame from '../../components/molecules/PopupFrame';
import ScreenLoading from '../../components/atoms/ScreenLoading';

type Props = {
  children?: React.ReactNode;
  blacklists: blackListModels.BlackList[];

  setBlackLists: (blacklists: blackListModels.BlackListJSON[]) => void;
};

type State = {
  blacklists: blackListModels.BlackList[];
  crewCode: string;
  crewName: string;
  keyword: string;
  isLoading: boolean;
  isSearching: boolean;
  isPopup: boolean;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    blacklists: state.blacklist.blacklists,
  };
};

const mapDispatchToProps = {
  setBlackLists: blackListActions.set,
};

class BlackListAdmin extends React.Component<Props, State> {
  readonly state: State = {
    isLoading: true,
    isSearching: false,
    isPopup: false,
    blacklists: [],
    crewCode: '',
    crewName: '',
    keyword: '',
  };

  readonly stats = {
    TextProps: {
      type: 'text',
      name: 'condKeyword',
      placeholder: '検索したいキーワードを入力',
      value: '',
    },
  };

  componentDidMount(): void {
    // TODO: 一覧取得できるまでローディング表示？
    this.fetchBlackListAll();
  }

  fetchBlackListAll(): void {
    this.setState({ isLoading: true });

    BlacklistAPI.getAll({ all: true })
      .then(response => {
        if (response.status === 200) {
          this.props.setBlackLists(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ isLoading: false, blacklists: this.props.blacklists });
      });
  }

  searchBlackList(crewCode: string): void {
    BlacklistAPI.get(crewCode)
      .then(response => {
        if (response.status === 200) {
          this.setState({ crewName: response.data.attributes.kana });
        } else if (response.status === 400) {
          this.setState({ crewName: '' });
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ isSearching: false });
      });
  }

  handleOnChangedKeyword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.state.isLoading) return;
    this.setState({ keyword: event.target.value });
  };

  handleOnKeyPressCrewCode = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      this.setState({ isSearching: true });
      this.searchBlackList(this.state.crewCode);
    }
  };

  handleOnChangedCrewCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.state.isSearching) return;
    this.setState({ crewCode: event.target.value });
  };

  handleOnClickDelete = (crewCode: string): void => {
    this.setState({ isLoading: true });
    const copyBlackLists = this.state.blacklists.slice();

    BlacklistAPI.delete(crewCode)
      .then(response => {
        if (response.status === 204) {
          let index = -1;
          for (let i = 0; i < copyBlackLists.length; i++) {
            if (copyBlackLists[i].crewCode === crewCode) {
              index = i;
              break;
            }
          }

          if (index >= 0) {
            copyBlackLists.splice(index, 1);
            this.setState({ isLoading: false, blacklists: copyBlackLists }, () => {
              alert('ブラックリストを解除しました');
            });
          } else {
            this.setState({ isLoading: false });
            alert('該当データが見つかりません');
          }
        } else {
          this.setState({ isLoading: false });
          console.log(response);
          alert(response.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        alert('エラーが発生しました');
      });
  };

  handleOnClickAdd = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isLoading: true });

    BlacklistAPI.put(this.state.crewCode).then(response => {
      if (response.status === 204) {
        this.setState(
          {
            isPopup: false,
            crewCode: '',
            crewName: '',
          },
          () => {
            this.setState({ isLoading: false });
            alert('登録が完了しました');
          },
        );
      } else {
        console.log(response);
        this.setState({ isLoading: false });
        alert(response.data.message);
      }
    });
  };

  handleOnClickPopup = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isPopup: !this.state.isPopup, crewCode: '', crewName: '' });
  };

  render() {
    const { stats } = this;
    const { isLoading, isSearching, isPopup, keyword, blacklists, crewCode, crewName } = this.state;
    //const { blacklists } = this.props;

    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <StyleIconArrow size={16} />
          <Title size={16} text="ブラックリスト" />
        </Header>
        <Area>
          <WrapCond>
            <StyleLabel text="クルーIDの絞り込み" />
            <WrapCondItem>
              <StyleIconLoupe size={14} />
              <StyleSearchInput
                type={stats.TextProps.type}
                theme="gray"
                name={stats.TextProps.name}
                value={keyword}
                placeholder={stats.TextProps.placeholder}
                onChange={this.handleOnChangedKeyword}
              />
            </WrapCondItem>
          </WrapCond>
          <Se />
          <WrapButton>
            <AddButton
              type="button"
              label={'＋ブラックリストを追加'}
              kind={'primary'}
              onClick={this.handleOnClickPopup}
              disabled={isLoading}
            />
          </WrapButton>
          <WrapList>
            <Table>
              <THeader>
                <Cell>クルーID</Cell>
                <Cell>クルー名</Cell>
                <Cell>登録日</Cell>
                <Cell></Cell>
              </THeader>
              {blacklists
                .filter(blacklist => keyword === '' || blacklist.crewCode.indexOf(keyword) >= 0)
                .map((blacklist, i) => (
                  <InfoRow key={i}>
                    <InfoCell>{blacklist.crewCode}</InfoCell>
                    <InfoCell>{blacklist.kana}</InfoCell>
                    <InfoCell>{blacklist.blackListedAt}</InfoCell>
                    <InfoCell>
                      <IconDelete
                        size={14}
                        onClick={() => this.handleOnClickDelete(blacklist.crewCode)}
                        disabled={isLoading || isPopup}
                      />
                    </InfoCell>
                  </InfoRow>
                ))}
            </Table>
          </WrapList>
        </Area>
        <BlackListAdd
          crewCode={crewCode}
          crewName={crewName}
          isPopup={isPopup}
          isSearching={isSearching}
          onPopupClose={this.handleOnClickPopup}
          onClickAdd={this.handleOnClickAdd}
          onChange={this.handleOnChangedCrewCode}
          onKeypress={this.handleOnKeyPressCrewCode}
        />
      </Base>
    );
  }
}

const Header = styled.div`
  margin-bottom: 10px;
`;

const Title = styled(Label)`
  font-weight: bold;
`;

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  margin-bottom: 50px;
`;

const WrapCond = styled.div`
  padding: 20px 0px 10px 25px;
`;

const WrapButton = styled.div`
  float: right;
  padding: 0px 15px 20px 0px;
`;

const WrapList = styled.div``;

const AddButton = styled(Button)`
  width: 200px;
`;

const WrapCondItem = styled.div`
  margin-top: 10px;
  margin-left: 5px;
`;

const StyleLabel = styled(Label)`
  color: #338cd8;
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const StyleIconLoupe = styled(IconLoupe)`
  position: relative;
  left: 10px;
`;

const StyleSearchInput = styled(Input)`
  padding: 10px 5px 10px 30px;
  margin-left: -15px;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  width: 50%;
`;

const Se = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  border: none;
  border-top: 1px #f5f5f5 solid;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  //cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;

  :nth-child(4n + 1) {
    width: 40px;
  }
  :nth-child(4n + 2) {
    width: 80px;
  }
  :nth-child(4n + 3) {
    width: 50%;
  }
  :nth-child(4n) {
    width: 20px;
    text-align: center;
  }
`;

type PopupProps = {
  isPopup: boolean;
  isSearching: boolean;
  crewCode: string;
  crewName: string;
  onPopupClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickAdd: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeypress: (event: React.KeyboardEvent) => void;
};

class BlackListAdd extends React.Component<PopupProps, State> {
  readonly stats = {
    //assistText: 'クルーIDを入力すると自動で名前を入力します',
    assistText: 'クルーIDを入力後にEnterを押すと、該当する名前を表示します',
  };

  render() {
    const { assistText } = this.stats;
    const { isPopup, isSearching, onPopupClose, onClickAdd, onChange, onKeypress, crewCode, crewName } = this.props;
    const isMatched: boolean = crewName !== '';

    if (!isPopup) {
      return <div />;
    }
    return (
      <Base>
        <PopupFrame isPopup={isPopup}>
          <PopupTitile>
            <PopupTitleLabel size={16} text="ブラックリストの登録" />
            <PopupCloseArea>
              <IconClose onClick={!isSearching && onPopupClose} />
            </PopupCloseArea>
          </PopupTitile>
          <PopupContents>
            <PopupCondTitle>
              <Label text="クルーID" />
            </PopupCondTitle>
            <PopupCond>
              <CrewIdInput
                key="crewId"
                name="crewId"
                theme="gray"
                value={crewCode}
                placeholder={'クルーIDを入力してください'}
                onChange={onChange}
                onKeyPress={crewCode !== '' && onKeypress}
              />
              {isSearching && (
                <LoadingArea>
                  <StyleLoading width={20} height={20} type={'spinningBubbles'} color={'gray'} />
                </LoadingArea>
              )}
            </PopupCond>
          </PopupContents>
          <PopupContents>
            <PopupCondTitle>
              <Label text="クルー名" />
            </PopupCondTitle>
            <PopupCond>
              <Label text={isMatched ? crewName : '－'} />
              <PopupSubMessage>
                <Label text={assistText} size={12} />
              </PopupSubMessage>
            </PopupCond>
          </PopupContents>
          <PopupFooter>
            <PopupCancelButton type="button" label="キャンセル" onClick={onPopupClose} disabled={isSearching} />
            <PopupSaveButton
              type="button"
              label="追加"
              kind="primary"
              onClick={onClickAdd}
              disabled={!isMatched || isSearching}
            />
          </PopupFooter>
        </PopupFrame>
      </Base>
    );
  }
}

const LoadingArea = styled.div`
  margin-top: 5px;
  margin-left: 10px;
  float: right;
`;

const StyleLoading = styled(Loading)`
  z-index: 99;
`;

const PopupFooter = styled.div`
  background-color: #ffffff;
  padding: 0px 30px 0px 0px;
  text-align: right;
  border-top: 1px solid #fafafa;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PopupTitile = styled.div`
  height: 45px;
  padding: 10px 0px 40px 10px;
  margin: 10px 0px 10px 0px;
  border-bottom: 1px solid #e8e8e8;
`;

const PopupContents = styled.div`
  padding: 20px 0px 40px 0px;
  border-bottom: 1px solid #e8e8e8;
`;

const PopupCondTitle = styled.span`
  display: table-cell;
  padding-left: 20px;
  width: 100px;
`;

const PopupCond = styled.span`
  display: table-cell;
`;

const PopupSubMessage = styled.div`
  margin-top: 10px;
`;

const CrewIdInput = styled(Input)`
  border-radius: 5px;
  height: 40px;
  padding: 10px;
  width: 400px;
`;

const PopupTitleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  padding: 10px;
`;

const PopupCloseArea = styled.div`
  float: right;
  padding-top: 5px;
  margin-right: 20px;
`;

const PopupSaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const PopupCancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BlackListAdmin);
