import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import { Skills } from '../../api/admin';
import Input from '../../components/atoms/Input';
import TextArea from '../../components/atoms/TextArea';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import SquareWeekButton from '../../components/atoms/SquareWeekButton';
import SelectBox from '../../components/atoms/SelectBox';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconBack from '../../components/atoms/icons/IconBack';
import ColorRadio from '../../components/atoms/ColorRadio';
import RadioButton from '../../components/atoms/RadioButton';
import { Link, RouteComponentProps } from 'react-router-dom';
import { businessModels } from '../../features/business';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import { skillModels } from '../../features/skill';

type Props = {
  token: string;
  children?: React.ReactNode;
  businesses: businessModels.Business[];
};

type State = {
  isLoading: boolean;
  skillId: number;
  skillsDetailStats: skillModels.SkillsDetailStatsJSON;
};

type PageProps = Props & RouteComponentProps<{ skill_id: string }>;

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    businesses: state.business.businesses,
  };
};

const mapDispatchToProps = {};

interface RadioProps {
  selected?: boolean;
}

class SkillsEdit extends React.Component<PageProps, State> {
  readonly state = {
    isLoading: false,
    skillId: parseInt(this.props.match.params.skill_id, 10),
    skillsDetailStats: this.initStats(),
  };

  initStats(): skillModels.SkillsDetailStatsJSON {
    return {
      skill_id: 0,
      name: '',
      color: '',
      business_id: 0,
      business_day_of_weeks: [],
      auto_response_message: '',
      after_business_hours_message: '',
      waiting_person_exceeded_message: '',
      visible: false,
      max_num_of_waiting_items: 0,
      order: 0,
      business_hours_start: '00:00:00',
      business_hours_end: '00:00:00',
      lighting: '00:00:00',
      blinking: '00:00:00',
      hard_blinking: '00:00:00',
      timeout_manned: '00:00:00',
      timeout_unmanned: '00:00:00',
      shop_use_initial_state_flag: false,
    };
  }

  readonly stats = {
    operator_code: process.env.REACT_APP_OPERATOR_CODE,
    timeout: {
      hoursOptions: [
        { key: 'hour_sel', label: '00', value: '00', checked: false },
        { key: 'hour_sel', label: '01', value: '01', checked: false },
        { key: 'hour_sel', label: '02', value: '02', checked: false },
        { key: 'hour_sel', label: '03', value: '03', checked: false },
        { key: 'hour_sel', label: '04', value: '04', checked: true },
        { key: 'hour_sel', label: '05', value: '05', checked: false },
        { key: 'hour_sel', label: '06', value: '06', checked: false },
        { key: 'hour_sel', label: '07', value: '07', checked: false },
        { key: 'hour_sel', label: '08', value: '08', checked: false },
        { key: 'hour_sel', label: '09', value: '09', checked: false },
        { key: 'hour_sel', label: '10', value: '10', checked: true },
        { key: 'hour_sel', label: '11', value: '11', checked: false },
        { key: 'hour_sel', label: '12', value: '12', checked: false },
        { key: 'hour_sel', label: '13', value: '13', checked: false },
        { key: 'hour_sel', label: '14', value: '14', checked: false },
        { key: 'hour_sel', label: '15', value: '15', checked: false },
        { key: 'hour_sel', label: '16', value: '16', checked: true },
        { key: 'hour_sel', label: '17', value: '17', checked: false },
        { key: 'hour_sel', label: '18', value: '18', checked: false },
        { key: 'hour_sel', label: '19', value: '19', checked: false },
        { key: 'hour_sel', label: '20', value: '20', checked: false },
        { key: 'hour_sel', label: '21', value: '21', checked: false },
        { key: 'hour_sel', label: '22', value: '22', checked: true },
        { key: 'hour_sel', label: '23', value: '23', checked: false },
      ],
      minutesOptions: [
        { key: 'min_sel', label: '00', value: '00', checked: false },
        { key: 'min_sel', label: '10', value: '10', checked: false },
        { key: 'min_sel', label: '20', value: '20', checked: false },
        { key: 'min_sel', label: '30', value: '30', checked: false },
        { key: 'min_sel', label: '40', value: '40', checked: false },
        { key: 'min_sel', label: '50', value: '50', checked: false },
      ],
      minuteslightingOptions: [
        { key: 'min_sel_lighting', label: '0', value: '00', checked: false },
        { key: 'min_sel_lighting', label: '1', value: '01', checked: false },
        { key: 'min_sel_lighting', label: '2', value: '02', checked: false },
        { key: 'min_sel_lighting', label: '3', value: '03', checked: false },
        { key: 'min_sel_lighting', label: '4', value: '04', checked: false },
        { key: 'min_sel_lighting', label: '5', value: '05', checked: false },
        { key: 'min_sel_lighting', label: '6', value: '06', checked: false },
        { key: 'min_sel_lighting', label: '7', value: '07', checked: false },
        { key: 'min_sel_lighting', label: '8', value: '08', checked: false },
        { key: 'min_sel_lighting', label: '9', value: '09', checked: false },
        { key: 'min_sel_lighting', label: '10', value: '10', checked: false },
      ],
      secondsOptions: [
        { key: 'sec_sel', label: '00', value: '00', checked: false },
        { key: 'sec_sel', label: '10', value: '10', checked: false },
        { key: 'sec_sel', label: '20', value: '20', checked: false },
        { key: 'sec_sel', label: '30', value: '30', checked: false },
        { key: 'sec_sel', label: '40', value: '40', checked: false },
        { key: 'sec_sel', label: '50', value: '50', checked: false },
      ],
      secondslightingOptions: [
        { key: 'sec_sel_lighting', label: '00', value: '00', checked: false },
        { key: 'sec_sel_lighting', label: '10', value: '10', checked: false },
        { key: 'sec_sel_lighting', label: '20', value: '20', checked: false },
        { key: 'sec_sel_lighting', label: '30', value: '30', checked: false },
        { key: 'sec_sel_lighting', label: '40', value: '40', checked: false },
        { key: 'sec_sel_lighting', label: '50', value: '50', checked: false },
      ],
    },
    max_num_of_waiting_items: [
      { key: 'wait_sel', label: '100', value: '100', checked: false },
      { key: 'wait_sel', label: '200', value: '200', checked: false },
      { key: 'wait_sel', label: '300', value: '300', checked: false },
      { key: 'wait_sel', label: '400', value: '400', checked: true },
      { key: 'wait_sel', label: '500', value: '500', checked: false },
      { key: 'wait_sel', label: '600', value: '600', checked: false },
      { key: 'wait_sel', label: '700', value: '700', checked: false },
      { key: 'wait_sel', label: '800', value: '800', checked: false },
      { key: 'wait_sel', label: '900', value: '900', checked: false },
      { key: 'wait_sel', label: '1000', value: '1000', checked: false },
    ],
    colorRadios: [
      { fillColor: '#808080', checked: false, strokeColor: '#000000' },
      { fillColor: '#c0c0c0', checked: false, strokeColor: '#000000' },
      { fillColor: '#fffaf0', checked: false, strokeColor: '#000000' },
      { fillColor: '#ffe4e1', checked: false, strokeColor: '#000000' },
      { fillColor: '#fff0f5', checked: false, strokeColor: '#000000' },
      { fillColor: '#fffff0', checked: false, strokeColor: '#000000' },
      { fillColor: '#f0fff0', checked: false, strokeColor: '#000000' },
      { fillColor: '#f5fffa', checked: false, strokeColor: '#000000' },
      { fillColor: '#f0f8ff', checked: false, strokeColor: '#000000' },
      { fillColor: '#e6e6fa', checked: false, strokeColor: '#000000' },
      { fillColor: '#4169e1', checked: false, strokeColor: '#000000' },
      { fillColor: '#4285F4', checked: false, strokeColor: '#000000' },
      { fillColor: '#40e0d0', checked: false, strokeColor: '#000000' },
      { fillColor: '#008b8b', checked: false, strokeColor: '#000000' },
      { fillColor: '#808000', checked: false, strokeColor: '#000000' },
      { fillColor: '#d2691e', checked: false, strokeColor: '#000000' },
      { fillColor: '#a52a2a', checked: false, strokeColor: '#000000' },
      { fillColor: '#bc8f8f', checked: false, strokeColor: '#000000' },
      { fillColor: '#ff0000', checked: false, strokeColor: '#000000' },
      { fillColor: '#ff1493', checked: false, strokeColor: '#000000' },
      { fillColor: '#ff00ff', checked: false, strokeColor: '#000000' },
      { fillColor: '#800080', checked: false, strokeColor: '#000000' },
      { fillColor: '#8a2be2', checked: false, strokeColor: '#000000' },
    ],
    weekList: [
      { label: '月', value: 'mon', checked: false },
      { label: '火', value: 'tue', checked: false },
      { label: '水', value: 'wed', checked: false },
      { label: '木', value: 'thu', checked: false },
      { label: '金', value: 'fri', checked: false },
      { label: '土', value: 'sat', checked: false },
      { label: '日', value: 'sun', checked: false },
    ],
  };

  componentDidMount() {
    if (!isNaN(this.state.skillId)) {
      this.fetchSkillsDetail();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.businesses !== prevProps.businesses) {
      this.setState({ isLoading: false });
    }
  }

  fetchSkillsDetail = async () => {
    this.setState({ isLoading: true });
    const resSkillsDetail = await Skills.get(this.state.skillId);
    if (resSkillsDetail.status === 200) {
      this.setState({
        isLoading: false,
        skillsDetailStats: resSkillsDetail.data.attributes,
      });
    } else {
      this.setState({ isLoading: false });
      alert('API サーバーで問題が発生しました');
    }
  };

  handleCancel = async () => {
    if (isNaN(this.state.skillId)) {
      //登録時
      this.setState({ skillsDetailStats: this.initStats() });
    } else {
      //更新時
      this.fetchSkillsDetail();
    }
  };

  handleUpdateSkillsDetail = async () => {
    //Validate
    if (this.state.skillsDetailStats.business_id === 0) {
      alert('事業者が未選択です');
      return;
    }
    if (this.state.skillsDetailStats.name === '') {
      alert('窓口名が未入力です');
      return;
    }
    if (this.state.skillsDetailStats.color === '') {
      alert('窓口のカラーが未選択です');
      return;
    }
    if (this.state.skillsDetailStats.business_day_of_weeks.length === 0) {
      alert('曜日が未選択です');
      return;
    }
    this.setState({ isLoading: true });
    if (isNaN(this.state.skillId)) {
      //登録
      // Record Not Found となるので登録の際は skill_id を削除
      delete this.state.skillsDetailStats.skill_id;
      const res = await Skills.post(this.state.skillsDetailStats);
      if (res.status === 200) {
        const skill = this.state.skillsDetailStats;
        skill.skill_id = res.data.id;
        this.setState({ isLoading: false, skillsDetailStats: skill, skillId: skill.skill_id });
        alert('窓口を登録しました。');
      } else {
        if (res.data.message.name) {
          this.setState({ isLoading: false });
          alert(`窓口名${res.data.message.name}`);
        } else {
          this.setState({ isLoading: false });
          alert('API サーバーで問題が発生しました');
        }
      }
    } else {
      //変更
      const res = await Skills.put(this.state.skillsDetailStats);
      if (res.status === 204) {
        this.setState({ isLoading: false });
        alert('変更を保存しました。');
      } else {
        if (res.data.message.name) {
          this.setState({ isLoading: false });
          alert(`窓口名${res.data.message.name}`);
        } else {
          this.setState({ isLoading: false });
          alert('API サーバーで問題が発生しました');
        }
      }
    }
  };

  // 事業者名が変更されたときの処理
  handleBusinessSetState = (e: React.MouseEvent<HTMLInputElement>) => {
    const businessId = Number(e.currentTarget.value);
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, business_id: businessId } });
  };

  // 色が変更されたときの処理
  handleColorSetState = (index: number) => {
    this.setState({
      skillsDetailStats: { ...this.state.skillsDetailStats, color: this.stats.colorRadios[index].fillColor },
    });
  };

  // 営業曜日を変更が変更されたときの処理
  handleWeekSetState = (index: number) => {
    let selectWeek = this.state.skillsDetailStats.business_day_of_weeks;
    if (selectWeek.indexOf(this.stats.weekList[index].value) >= 0) {
      // 選択された曜日が skillsDetailStats に含まれていたら削除する
      selectWeek = selectWeek.filter(data => data !== this.stats.weekList[index].value);
    } else if (selectWeek.indexOf(this.stats.weekList[index].value) < 0) {
      // 選択された曜日が skillsDetailStats に含まれたいなかったら追加する
      selectWeek.push(this.stats.weekList[index].value);
    }
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, business_day_of_weeks: selectWeek } });
  };

  // テキスト関係のが変更されたときの処理
  handleTextSetState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyName = e.target.name;
    const value = e.target.value;
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, [keyName]: value } });
  };

  handleTextAreaSetState = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const keyName = e.target.name;
    const value = e.target.value;
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, [keyName]: value } });
  };

  // 表示・非表示を変更されたときの処理
  handleVisibleSetState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isVisible = e.target.value === '0';
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, visible: isVisible } });
  };

  handleShopUseInitialSetState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isShopUse = e.target.value === '0';
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, shop_use_initial_state_flag: isShopUse } });
  };

  // 時間(select box 系)を変更されたときの処理
  handleTimeState = (name, value) => {
    const dataArray = this.state.skillsDetailStats[name].split(':');
    if (value.key === 'hour_sel') {
      dataArray[0] = value.value;
    } else if (value.key === 'min_sel' || value.key === 'min_sel_lighting') {
      dataArray[1] = value.value;
    } else if (value.key === 'sec_sel' || value.key === 'sec_sel_lighting') {
      dataArray[2] = value.value;
    }
    const time = dataArray.join(':');
    this.setState({ skillsDetailStats: { ...this.state.skillsDetailStats, [name]: time } });
  };

  render() {
    const { stats } = this;
    const { isLoading, skillsDetailStats } = this.state;

    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <RouteLink to="/skills_admin">
            <StyleIconBack size={16} />
            <BackLabel text="窓口一覧に戻る" />
          </RouteLink>
        </Header>
        <Wrap>
          <Title>
            <StyleIconArrow size={16} />
            <TitleLabel text="窓口の内容設定" />
          </Title>
          <Area>
            <EditArea>
              <SubTitle text="窓口の事業者" size={16} />
              <WrapCond>
                <BizList>
                  {this.props.businesses !== undefined &&
                    this.props.businesses.map((business, index) => (
                      <BizElem
                        type="button"
                        label={business.name}
                        value={String(business.businessId)}
                        selected={skillsDetailStats.business_id === business.businessId}
                        onClick={this.handleBusinessSetState}
                      />
                    ))}
                </BizList>
              </WrapCond>
              <Se />
              <SubTitle text="窓口名" size={16} />
              <WrapCond>
                <SkillNameInput
                  type="text"
                  theme="gray"
                  name="name"
                  placeholder="料金・キャンペーン・受付基準"
                  value={skillsDetailStats.name}
                  onChange={this.handleTextSetState}
                />
              </WrapCond>
              <Se />
              <SubTitle text="窓口のカラー" size={16} />
              <WrapCond>
                {this.stats.colorRadios.map((colorRadio, index) => {
                  return (
                    <StyleColorRadio
                      size={25}
                      fill={colorRadio.fillColor}
                      strokeColor={colorRadio.strokeColor}
                      index={index}
                      onColorToggle={this.handleColorSetState}
                      checked={skillsDetailStats.color === colorRadio.fillColor}
                    />
                  );
                })}
              </WrapCond>
            </EditArea>
            <EditArea>
              <SubTitle text="窓口の営業時間" size={16} />
              <CondArea>
                <CondRow>
                  <CondHead>
                    <Label text="営業時間" />
                  </CondHead>
                  <CondElem>
                    <SelectBox
                      name="business_hours_start"
                      theme="gray"
                      options={stats.timeout.hoursOptions}
                      selectedOption={{
                        key: 'hour_sel',
                        label: skillsDetailStats.business_hours_start.split(':')[0],
                        value: skillsDetailStats.business_hours_start.split(':')[0],
                      }}
                      onChanged={this.handleTimeState.bind('business_hours_start')}
                    />
                    <UnitLabel text=":" />
                    <SelectBox
                      name="business_hours_start"
                      theme="gray"
                      options={stats.timeout.minutesOptions}
                      selectedOption={{
                        key: 'min_sel',
                        label: skillsDetailStats.business_hours_start.split(':')[1],
                        value: skillsDetailStats.business_hours_start.split(':')[1],
                      }}
                      onChanged={this.handleTimeState.bind('business_hours_start')}
                    />
                    <UnitLabel text="～" />
                    <SelectBox
                      name="business_hours_end"
                      theme="gray"
                      options={stats.timeout.hoursOptions}
                      selectedOption={{
                        key: 'hour_sel',
                        label: skillsDetailStats.business_hours_end.split(':')[0],
                        value: skillsDetailStats.business_hours_end.split(':')[0],
                      }}
                      onChanged={this.handleTimeState.bind('business_hours_end')}
                    />
                    <UnitLabel text=":" />
                    <SelectBox
                      name="business_hours_end"
                      theme="gray"
                      options={stats.timeout.minutesOptions}
                      selectedOption={{
                        key: 'min_sel',
                        label: skillsDetailStats.business_hours_end.split(':')[1],
                        value: skillsDetailStats.business_hours_end.split(':')[1],
                      }}
                      onChanged={this.handleTimeState.bind('business_hours_end')}
                    />
                  </CondElem>
                </CondRow>
                <CondRow>
                  <CondHead>
                    <Label text="営業曜日" />
                  </CondHead>
                  <CondElem>
                    {this.stats.weekList.map((data, index) => {
                      return (
                        <SquareWeekButton
                          label={data.label}
                          selected={skillsDetailStats.business_day_of_weeks.indexOf(data.value) >= 0}
                          index={index}
                          onClick={this.handleWeekSetState}
                        />
                      );
                    })}
                  </CondElem>
                </CondRow>
                <CondRow>
                  <CondHead>
                    <Label text="待ち人数の上限" />
                  </CondHead>
                  <CondElem>
                    <SkillNameInput
                      type="text"
                      theme="gray"
                      name="max_num_of_waiting_items"
                      placeholder="0"
                      value={String(skillsDetailStats.max_num_of_waiting_items)}
                      onChange={this.handleTextSetState}
                    />
                  </CondElem>
                </CondRow>
              </CondArea>
              <Se visible={false} />
              <SubTitle text="タイムアウト時間" size={16} visible={false} />
              <CondArea visible={false}>
                <CondRow>
                  <CondHead>
                    <Label text="有人発話の場合" />
                  </CondHead>
                  <CondElem>
                    <SelectBox
                      name="timeout_manned"
                      theme="gray"
                      options={stats.timeout.minutesOptions}
                      selectedOption={{
                        key: 'min_sel',
                        label: skillsDetailStats.timeout_manned.split(':')[1],
                        value: skillsDetailStats.timeout_manned.split(':')[1],
                      }}
                      onChanged={this.handleTimeState.bind('timeout_manned')}
                    />
                    <UnitLabel text="分" />
                    <SelectBox
                      name="timeout_manned"
                      theme="gray"
                      options={stats.timeout.secondsOptions}
                      selectedOption={{
                        key: 'sec_sel',
                        label: skillsDetailStats.timeout_manned.split(':')[2],
                        value: skillsDetailStats.timeout_manned.split(':')[2],
                      }}
                      onChanged={this.handleTimeState.bind('timeout_manned')}
                    />
                    <UnitLabel text="秒" />
                  </CondElem>
                </CondRow>
                <CondRow>
                  <CondHead>
                    <Label text="自動発話の場合" />
                  </CondHead>
                  <CondElem>
                    <SelectBox
                      name="timeout_unmanned"
                      theme="gray"
                      options={stats.timeout.minutesOptions}
                      selectedOption={{
                        key: 'min_sel',
                        label: skillsDetailStats.timeout_unmanned.split(':')[1],
                        value: skillsDetailStats.timeout_unmanned.split(':')[1],
                      }}
                      onChanged={this.handleTimeState.bind('timeout_unmanned')}
                    />
                    <UnitLabel text="分" />
                    <SelectBox
                      name="timeout_unmanned"
                      theme="gray"
                      options={stats.timeout.secondsOptions}
                      selectedOption={{
                        key: 'sec_sel',
                        label: skillsDetailStats.timeout_unmanned.split(':')[2],
                        value: skillsDetailStats.timeout_unmanned.split(':')[2],
                      }}
                      onChanged={this.handleTimeState.bind('timeout_unmanned')}
                    />
                    <UnitLabel text="秒" />
                  </CondElem>
                </CondRow>
              </CondArea>
              {false && <Se />}
              {false && <SubTitle text="点灯・点滅までの時間設定" size={16} />}
              {false && (
                <CondArea>
                  <CondRow>
                    <CondHead>
                      <Label text="点灯まで" />
                    </CondHead>
                    <CondElem>
                      <SelectBox
                        name="lighting"
                        theme="gray"
                        options={stats.timeout.minuteslightingOptions}
                        selectedOption={{
                          key: 'min_sel_lighting',
                          label: skillsDetailStats.lighting.split(':')[1],
                          value:
                            skillsDetailStats.lighting.split(':')[1] === '00'
                              ? '-'
                              : skillsDetailStats.lighting.split(':')[1],
                        }}
                        onChanged={this.handleTimeState.bind('lighting')}
                      />
                      <UnitLabel text="分" />
                      <SelectBox
                        name="lighting"
                        theme="gray"
                        options={stats.timeout.secondslightingOptions}
                        selectedOption={{
                          key: 'sec_sel_lighting',
                          label: skillsDetailStats.lighting.split(':')[2],
                          value: skillsDetailStats.lighting.split(':')[2],
                        }}
                        onChanged={this.handleTimeState.bind('lighting')}
                      />
                      <UnitLabel text="秒" />
                    </CondElem>
                    <LigthingText>
                      <Label text="未設定にしたい場合は00:00を指定してください。" />
                    </LigthingText>
                  </CondRow>
                  <CondRow>
                    <CondHead>
                      <Label text="点滅まで" />
                    </CondHead>
                    <CondElem>
                      <SelectBox
                        name="blinking"
                        theme="gray"
                        options={stats.timeout.minuteslightingOptions}
                        selectedOption={{
                          key: 'min_sel_lighting',
                          label: skillsDetailStats.blinking.split(':')[1],
                          value: skillsDetailStats.blinking.split(':')[1],
                        }}
                        onChanged={this.handleTimeState.bind('blinking')}
                      />
                      <UnitLabel text="分" />
                      <SelectBox
                        name="blinking"
                        theme="gray"
                        options={stats.timeout.secondslightingOptions}
                        selectedOption={{
                          key: 'sec_sel_lighting',
                          label: skillsDetailStats.blinking.split(':')[2],
                          value: skillsDetailStats.blinking.split(':')[2],
                        }}
                        onChanged={this.handleTimeState.bind('blinking')}
                      />
                      <UnitLabel text="秒" />
                    </CondElem>
                    <LigthingText>
                      <Label text="未設定にしたい場合は00:00を指定してください。" />
                    </LigthingText>
                  </CondRow>
                  <CondRow>
                    <CondHead>
                      <Label text="激しい点滅まで" />
                    </CondHead>
                    <CondElem>
                      <SelectBox
                        name="hard_blinking"
                        theme="gray"
                        options={stats.timeout.minuteslightingOptions}
                        selectedOption={{
                          key: 'min_sel_lighting',
                          label: skillsDetailStats.hard_blinking.split(':')[1],
                          value: skillsDetailStats.hard_blinking.split(':')[1],
                        }}
                        onChanged={this.handleTimeState.bind('hard_blinking')}
                      />
                      <UnitLabel text="分" />
                      <SelectBox
                        name="hard_blinking"
                        theme="gray"
                        options={stats.timeout.secondslightingOptions}
                        selectedOption={{
                          key: 'sec_sel_lighting',
                          label: skillsDetailStats.hard_blinking.split(':')[2],
                          value: skillsDetailStats.hard_blinking.split(':')[2],
                        }}
                        onChanged={this.handleTimeState.bind('hard_blinking')}
                      />
                      <UnitLabel text="秒" />
                    </CondElem>
                    <LigthingText>
                      <Label text="未設定にしたい場合は00:00を指定してください。" />
                    </LigthingText>
                  </CondRow>
                </CondArea>
              )}
              <Se />
              <SubTitle text="窓口自動メッセージ" size={16} />
              <WrapCond>
                <CondTitle text={'営業時間超過後'} size={14} />
                <MessageText
                  theme="gray"
                  name="after_business_hours_message"
                  placeholder="なし"
                  rows={5}
                  onChange={this.handleTextAreaSetState}
                  value={skillsDetailStats.after_business_hours_message}
                />
              </WrapCond>
              <WrapCond>
                <CondTitle text={'待ち件数上限超過時'} size={14} />
                <MessageText
                  theme="gray"
                  name="waiting_person_exceeded_message"
                  placeholder="なし"
                  rows={5}
                  onChange={this.handleTextAreaSetState}
                  value={skillsDetailStats.waiting_person_exceeded_message}
                />
              </WrapCond>
              <WrapCond>
                <CondTitle text={'全オペレーターの受付上限超過時'} size={14} />
                <MessageText
                  theme="gray"
                  name="auto_response_message"
                  placeholder="なし"
                  rows={5}
                  onChange={this.handleTextAreaSetState}
                  value={skillsDetailStats.auto_response_message}
                />
              </WrapCond>
            </EditArea>
            <EditArea>
              <SubTitle text="窓口の常時・非表示" size={16} />
              <Label text="窓口の表示・非表示を切り替えることができます。" size={14} />
              <br />
              <Label
                text="非表示を選択するとオペレーター管理やお知らせ管理などで選択肢として表示されなくなります。"
                size={14}
              />
              <WrapCond>
                <WrapRadio>
                  <RadioButton
                    name="isVisible"
                    value="0"
                    text="表示"
                    checked={skillsDetailStats.visible}
                    onChange={this.handleVisibleSetState}
                  />
                </WrapRadio>
                <WrapRadio>
                  <RadioButton
                    name="isHidden"
                    value="1"
                    text="非表示"
                    checked={!skillsDetailStats.visible}
                    onChange={this.handleVisibleSetState}
                  />
                </WrapRadio>
              </WrapCond>
              <Padding />
            </EditArea>
            <EditArea>
              <SubTitle text="店舗作成時の初期利用フラグ" size={16} />
              <Label text="店舗を新規作成時にデフォルトで利用可か利用不可か設定できます。" size={14} />
              <br />
              <WrapCond>
                <WrapRadio>
                  <RadioButton
                    name="isShopUse"
                    value="0"
                    text="利用可"
                    checked={skillsDetailStats.shop_use_initial_state_flag}
                    onChange={this.handleShopUseInitialSetState}
                  />
                </WrapRadio>
                <WrapRadio>
                  <RadioButton
                    name="isHidden"
                    value="1"
                    text="利用不可"
                    checked={!skillsDetailStats.shop_use_initial_state_flag}
                    onChange={this.handleShopUseInitialSetState}
                  />
                </WrapRadio>
              </WrapCond>
              <Padding />
            </EditArea>
            <Padding />
            <Padding>&nbsp;</Padding>
            <Padding>&nbsp;</Padding>
          </Area>
          <Footer>
            <Label text="変更内容を" />
            <SaveButton
              type="button"
              label="保存"
              kind="primary"
              disabled={isLoading}
              onClick={this.handleUpdateSkillsDetail}
            />
            <CancelButton type="button" label="キャンセル" disabled={isLoading} onClick={() => this.handleCancel()} />
          </Footer>
        </Wrap>
      </Base>
    );
  }
}

type VisibleProps = {
  visible?: boolean;
};

const Padding = styled.div`
  margin-bottom: 30px;
`;

const Header = styled.div`
  background-color: #ffffff;
  margin-bottom: 30px;
  margin-left: -20px;
  margin-top: -20px;
  padding-top: 15px;
  padding-left: 20px;
  width: calc(100% + 50px);
  height: 50px;
`;

const Footer = styled.div`
  background-color: #ffffff;
  padding: 15px 50px 0 0;
  margin-left: -20px;
  text-align: right;
  border-top: 2px solid #fafafa;
  position: fixed;
  bottom: 0;
  width: calc(100% - 180px);
  height: 70px;
`;

const Title = styled.div`
  height: 45px;
`;

const SubTitle = styled(Label)`
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
  color: #338cd8;
  font-weight: bold;
  margin-bottom: 15px;
`;

const CondTitle = styled(Label)`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
`;

const BackLabel = styled(Label)``;

const StyleIconBack = styled(IconBack)`
  stroke: #707070;
  padding-right: 10px;
`;

const Wrap = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const WrapCond = styled.div``;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const BizList = styled.div`
  display: flex;
`;

const BizElem = styled(Button)`
  border-radius: 5px;

  text-align: center;
  width: 200px;
  height: 50px;
  margin-right: 10px;
  padding-top: 12px;
  cursor: pointer;

  ${(props: RadioProps) => activeBizStyle(props.selected)};
`;

const Se = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  border: none;
  border-top: 1px #f5f5f5 solid;
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
`;

const SkillNameInput = styled(Input)`
  border-radius: 5px;
  height: 50px;
  width: 600px;
  padding: 10px;
`;

const StyleColorRadio = styled(ColorRadio)`
  margin-right: 5px;
  cursor: pointer;
`;

const activeBizStyle = (isActive: boolean | undefined) => {
  if (isActive) {
    return `
      color: #ffffff;
      background-color: #0783F4;
    `;
  } else {
    return `
      background-color: #f4f4f4;
    `;
  }
};

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const RouteLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

const Area = styled.div``;

const EditArea = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding: 20px 0px 30px 20px;

  margin-bottom: 20px;
`;

const CondArea = styled.table`
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
`;

const CondRow = styled.tr``;

const CondHead = styled.th`
  width: 150px;
  text-align: right;
  padding-right: 20px;
  height: 50px;
  vertical-align: middle;
`;

const CondElem = styled.td`
  padding: 10px 0px 10px 10px;
  height: 50px;
  vertical-align: middle;
`;

const LigthingText = styled.th`
  text-align: left;
  padding-left: 20px;
  height: 50px;
  vertical-align: middle;
`;

const UnitLabel = styled(Label)`
  margin-left: 10px;
  margin-right: 10px;
`;

const MessageText = styled(TextArea)`
  border-radius: 5px;
  padding: 10px;
  width: 90%;
  margin-bottom: 25px;
`;

const WrapRadio = styled.div`
  width: 100px;
  float: left;
  margin-top: 10px;
`;

const SaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const CancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SkillsEdit);
