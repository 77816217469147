import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import { authActions } from '../../features/auth';
import Base from '../../components/molecules/Base';
import { Skills as SkillsAPI } from '../../api/admin';
import SkillCard from '../../components/molecules/SkillCard';
import IconLoupe from '../../components/atoms/icons/IconLoupe';
import Input from '../../components/atoms/Input';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import IconAdd from '../../components/atoms/icons/IconAdd';
import Tab from '../../components/atoms/Tab';
import { Link } from 'react-router-dom';
import { businessModels } from '../../features/business';
import { skillModels, skillActions } from '../../features/skill';
import SkillsSort from '../../components/molecules/SkillsSort';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import { getParam } from '../../api/utils';

type Props = {
  token: string;
  businessId: string;
  children?: React.ReactNode;
  businesses: businessModels.Business[];
  skills: skillModels.Skill[];
  operatorSkills: skillModels.OperatorSkill[];
  setOperatorSkills: (json: skillModels.OperatorSkillJSON[]) => void;
};

type State = {
  skillCards: skillModels.SkillDetailJSON[];
  keyword: string;
  tabIndex: number;
  isPopup: boolean;
  isLoading: boolean;
  isFiltered: boolean;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    token: state.auth.token,
    businesses: state.business.businesses,
    skills: state.skill.skills,
    operatorSkills: state.skill.operatorSkills,
  };
};

const mapDispatchToProps = {
  setToken: authActions.set,
  setOperatorSkills: skillActions.opSet,
};

class SkillsAdmin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
  }

  readonly state: State = {
    skillCards: [],
    keyword: '',
    tabIndex: -1,
    isPopup: false,
    isLoading: true,
    isFiltered: false,
  };

  readonly stats = {
    TextProps: {
      type: 'text',
      name: 'condFlag',
      placeholder: '検索したいキーワードを入力',
      value: '',
    },
    OperatorProps: {
      operator_code: process.env.REACT_APP_OPERATOR_CODE,
    },
  };

  componentDidMount() {
    this.fetchSkills();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.businesses.length > 0 && this.state.tabIndex < 0) {
      let tabIndex = 0;
      if (getParam('businessId')) {
        tabIndex = parseInt(getParam('businessId'), 10);
        if (isNaN(tabIndex)) tabIndex = 0;
      }
      this.handleChangeTab(tabIndex);
    }

    const isPropsLoaded =
      this.props.businesses.length > 0 && this.props.skills.length > 0 && this.props.operatorSkills.length > 0;
    if (this.state.isLoading && isPropsLoaded) {
      this.setState({ isLoading: false, isFiltered: false });
    }
  }

  handleOnClickPopup = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isPopup: !this.state.isPopup });
  };

  handleOnClickSave = (sortedSkillCards: skillModels.SkillDetailJSON[]): void => {
    const errorMessages: string[] = [];
    const promises: Promise<any>[] = [];
    this.setState({ isLoading: true, isPopup: false });

    //配列の並び順にorderを指定
    sortedSkillCards.forEach((skillCard, i) => {
      skillCard.overview.order = i + 1;
    });

    //全窓口のorder設定
    const skillCards = this.state.skillCards.slice();
    skillCards.forEach(skillCard => {
      const sortedCards = sortedSkillCards.filter(card => card.overview.skill_id === skillCard.overview.skill_id);
      if (sortedCards.length > 0) {
        skillCard.overview.order = sortedCards[0].overview.order;
      }
    });

    for (let i = 0; i < this.props.businesses.length; i++) {
      const business = this.props.businesses[i];
      const filteredSkills = skillCards.filter(skill => skill.overview.business_id === business.businessId);
      if (filteredSkills.length === 0) {
        continue;
      }

      const paramSkills = [];
      for (let j = 0; j < filteredSkills.length; j++) {
        paramSkills.push({
          skill_id: filteredSkills[j].overview.skill_id,
          order: filteredSkills[j].overview.order,
        });
      }
      const paramReorder = {
        business_id: business.businessId,
        skills: paramSkills,
      };

      promises.push(SkillsAPI.reorder(paramReorder));
    }

    Promise.all(promises)
      .then(responses => {
        responses.forEach(response => {
          if (response.status !== 204) {
            errorMessages.push(response.data.message);
          }
        });
      })
      .catch(error => {
        console.log(error);
        errorMessages.push(error.message);
      });

    if (errorMessages.length === 0) {
      this.setState({ skillCards: skillCards.sort(this.sortSkillDetails), isLoading: false });
      const newOperatorSkills: skillModels.OperatorSkillJSON[] = [];
      this.props.operatorSkills.map(opSkill => {
        const newOrder = skillCards.filter(skill => skill.overview.skill_id === opSkill.skillId)[0].overview.order;
        return newOperatorSkills.push({
          attributes: {
            business_id: opSkill.businessId,
            skill_id: opSkill.skillId,
            name: opSkill.name,
            order: newOrder,
          },
        });
      });
      this.props.setOperatorSkills(newOperatorSkills);
      alert('更新が完了しました');
    } else {
      this.setState({ isLoading: false });
      alert(`エラーが発生しました: ${errorMessages}`);
    }
  };

  fetchSkills(): void {
    this.setState({ isLoading: true, isFiltered: false });

    SkillsAPI.getStatsAll({ all: true })
      .then(response => {
        if (response.status === 200) {
          const sortedSkillCards: skillModels.SkillDetailJSON[] = response.data.sort(this.sortSkills);
          this.setState({ skillCards: sortedSkillCards });
        } else {
          this.setState({ isLoading: false, isFiltered: false });
          console.log(response);
          alert('API サーバーで問題が発生しました');
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
        alert('API サーバーで問題が発生しました');
      });
  }

  handleChangeTab(tabIndex: number): void {
    this.setState({ tabIndex: tabIndex });
  }

  handleChangeKeyword(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ keyword: event.target.value });
  }

  get skillCards() {
    const keyword = this.state.keyword;
    const tabIndex = this.state.tabIndex;
    let copySkills = this.state.skillCards.slice();

    if (keyword !== '') {
      copySkills = copySkills.filter(skill => skill.overview.name.indexOf(keyword) !== -1);
    }
    if (tabIndex > 0 && this.props.businesses.length > 0) {
      const businessId = this.props.businesses[tabIndex - 1].businessId;
      copySkills = copySkills.filter(skill => skill.overview.business_id === businessId);
    }
    return copySkills;
  }

  get skillCardsForSort() {
    const tabIndex = this.state.tabIndex;
    let copySkills = this.state.skillCards.slice();
    if (tabIndex > 0 && this.props.businesses.length > 0) {
      const businessId = this.props.businesses[tabIndex - 1].businessId;
      copySkills = copySkills.filter(skill => skill.overview.business_id === businessId);
    }
    return copySkills.sort(this.sortSkillDetails);
  }

  sortSkills(a: skillModels.Skill, b: skillModels.Skill): number {
    if (a.order !== b.order) {
      return a.order - b.order;
    } else {
      return a.skillId - b.skillId;
    }
  }

  sortSkillDetails(a: skillModels.SkillDetailJSON, b: skillModels.SkillDetailJSON): number {
    if (a.overview.order !== b.overview.order) {
      return a.overview.order - b.overview.order;
    } else {
      return a.overview.skill_id - b.overview.skill_id;
    }
  }

  render() {
    const { stats, skillCards, sortSkills } = this;
    const { keyword, isPopup, isLoading } = this.state;
    const { operatorSkills } = this.props;

    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Wrapper>
          <TabArea>
            <StyleTab
              onClick={() => this.handleChangeTab(0)}
              selected={this.state.tabIndex === 0}
              label={'全ての事業者'}
            />
            {this.props.businesses.map((business, i) => (
              <StyleTab
                key={`Tab${business.businessId}`}
                onClick={() => this.handleChangeTab(business.businessId)}
                selected={this.state.tabIndex === business.businessId}
                label={business.name}
              />
            ))}
          </TabArea>
          <Area>
            <WrapperCond>
              <Wrapper>
                <StyleLabel text="窓口の絞り込み" size={12} />
                <Wrapper>
                  <StyleIconLoupe size={14} />
                  <StyleSearchInput
                    type={stats.TextProps.type}
                    theme="gray"
                    name={stats.TextProps.name}
                    value={keyword}
                    placeholder={stats.TextProps.placeholder}
                    onChange={this.handleChangeKeyword}
                  />
                </Wrapper>
              </Wrapper>
              <Se />
              <Wrapper>
                {operatorSkills.length > 0 && (
                  <WrapperChange>
                    <Button type="button" label="窓口の並び順変更" onClick={this.handleOnClickPopup} />
                  </WrapperChange>
                )}
                <WrapperAdd>
                  <RouteLink to={'/skills_create'}>
                    <WrapIconButton>
                      <IconAdd size={14} stroke={'#ffffff'} />
                      <IconButton type="button" label="窓口を登録" kind="primary" />
                    </WrapIconButton>
                  </RouteLink>
                </WrapperAdd>
              </Wrapper>
            </WrapperCond>
            {operatorSkills.length > 0 && (
              <SkillList>
                <List>
                  {operatorSkills.sort(sortSkills).map((operatorSkill, i) => {
                    const skill = skillCards.filter(skill => operatorSkill.skillId === skill.overview.skill_id)[0];
                    return (
                      skill && (
                        <RouteLink key={i} to={`/skills_detail/${skill.overview.skill_id}`}>
                          <Item key={skill.overview.skill_id}>
                            <SkillCard stats={skill} />
                          </Item>
                        </RouteLink>
                      )
                    );
                  })}
                </List>
              </SkillList>
            )}
          </Area>
        </Wrapper>
        <SkillsSort
          isPopup={isPopup}
          skillCards={this.skillCardsForSort}
          onPopupClose={this.handleOnClickPopup}
          onClickSave={this.handleOnClickSave}
        />
      </Base>
    );
  }
}

const Wrapper = styled.div``;

const WrapperCond = styled.div`
  margin: 25px;
  height: 140px;
`;

const WrapperChange = styled.div`
  float: left;
`;

const WrapperAdd = styled.div`
  float: right;
`;

const SkillList = styled.div`
  clear: both;
  padding-top: 10px;
`;

const List = styled.ul``;

const Item = styled.li`
  margin: 25px;
`;

const TabArea = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
`;

const StyleTab = styled(Tab)`
  width: calc(100% / 4);
`;

const Area = styled.div`
  clear: both;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding-top: 10px;
  margin: 0 25px 20px;
`;

const StyleLabel = styled(Label)`
  color: #338cd8;
`;

const StyleIconLoupe = styled(IconLoupe)`
  position: relative;
  left: 10px;
`;

const StyleSearchInput = styled(Input)`
  padding: 10px 5px 10px 30px;
  margin-left: -15px;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
`;

const Se = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  border: none;
  border-top: 1px #f5f5f5 solid;
`;

const IconButton = styled(Button)`
  border: none;
  background-color: #338ed8;
  font-weight: normal;
`;

const WrapIconButton = styled.div`
  border-radius: 5px;
  background-color: #338ed8;
  width: 150px;
  text-align: center;
`;

const RouteLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SkillsAdmin);
