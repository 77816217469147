import styled from 'styled-components';
import { ReactComponent as Add } from './svg/add.svg';

type Props = {
  size: number;
  onClick?: () => void;
};

const IconAdd = styled(Add)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  cursor: pointer;
`;

export default IconAdd;
