import { createAction } from 'typesafe-actions';
//import { SET, ADD, EDIT, DELETE, CHECK, CHECK_ALL } from './constants';
import { SET } from './constants';
import { Period, PeriodJson } from './models';

export const set = createAction(SET, (json: PeriodJson[]) => {
  const periods: Period[] = [];

  json.forEach(period => {
    periods.push({
      isEditing: false,
      periodId: period.attributes.period_id,
      skillId: period.attributes.skill_id,
      periodStart: period.attributes.period_start,
      periodEnd: period.attributes.period_end,
      businessHoursStart: period.attributes.business_hours_start,
      businessHoursEnd: period.attributes.business_hours_end,
    });
  });

  return periods;
})<Period[]>();

//export const add = createAction(CHECK, (index: number) => index)<number>();
//export const check = createAction(CHECK, (index: number) => index)<number>();
//export const checkAll = createAction(CHECK_ALL, () => null)<null>();
//export const editFlag = createAction(EDIT, (index: number) => index)<number>();
//export const deleteFlag = createAction(DELETE, (index: number) => index)<number>();
