export const SET = 'operator/SET';
export const GET = 'operator/GET';
export const CHECK = 'operator/CHECK';
export const CHECK_ALL = 'operator/CHECK_ALL';
export const CHANGE_CHAT_FLAG = 'operator/CHANGE_CHAT_FLAG';
export const CHANGE_ITEMS = 'operator/CHANGE_ITEMS';
export const CHANGE_POINTS = 'operator/CHANGE_POINTS';
export const CHECK_SKILL = 'operator/CHECK_SKILL';
export const TOGGLE_BIZ = 'operator/TOGGLE_BIZ';
export const SET_SKILL_NUM = 'operator/SET_SKILL_NUM';

export const SET_DETAIL = 'operator-detail/SET';
export const SET_BIZ = 'operator-businesses/SET';
