import React from 'react';
import styled from 'styled-components';
import { theme } from '../../Theme';
import moment from 'moment';
import { ShopUseLimitStats } from '../../utils/interface';
import Button from '../atoms/Button';

interface Props {
  stats: ShopUseLimitStats;
  isWorkingJob: boolean;
  isDownload: boolean;
  downloadFileId: number;
  onClick?: (fileName: string, fileId: number) => void;
  makeDownloadCsv?: (fileId: number) => void;
  downloadCsv?: (fileId: number) => void;
}

const ShopUseLimitRow: React.FC<Props> = props => {
  const { stats, onClick, makeDownloadCsv, downloadCsv, isWorkingJob, isDownload, downloadFileId } = props;

  return (
    <InfoRow>
      <InfoCell>
        {stats.attributes.use_flag === true && <UseFlagTrue>利用中</UseFlagTrue>}
        {stats.attributes.use_flag === false && (
          <UseFlagFalseButton
            type="button"
            label="巻き戻す"
            disabled={isWorkingJob}
            onClick={() => {
              onClick(stats.attributes.filename, stats.attributes.file_id);
            }}
          />
        )}
      </InfoCell>
      <InfoCell>{stats.attributes.filename}</InfoCell>
      <InfoCell>{moment(stats.attributes.created_at).format('YYYY/M/D')}</InfoCell>
      <InfoCell>
        <MakeDownloadButton
          type="button"
          label="ダウンロード準備"
          disabled={isWorkingJob}
          onClick={() => {
            makeDownloadCsv(stats.attributes.file_id);
          }}
        />
      </InfoCell>
      {stats.attributes.file_id === downloadFileId ? (
        <InfoCell>
          <DownloadButton
            type="button"
            label="CSVダウンロード"
            kind="primary"
            visible={isDownload}
            onClick={() => downloadCsv(downloadFileId)}
          />
        </InfoCell>
      ) : (
        <InfoCell></InfoCell>
      )}
    </InfoRow>
  );
};

type VisibleProps = {
  visible?: boolean;
};

const DownloadButton = styled(Button)`
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
  font-size: 10px;
  border: none;
  height: auto;
  width: auto;
  padding: 12px 10px 10px;
  text-align: center;
  font-weight: bold;
  transition: opacity 0.15s ease-out;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const UseFlagTrue = styled.div`
  color: #ffffff;
  margin: 10px auto;
  font-size: 10px;
  background-color: ${theme.primaryColor};
  border: 1px solid #1674cb;
  display: inline-block;
  text-align: center;
  width: auto;
  height: auto;
  padding: 12px 10px 10px;
  border-radius: 4px;
  transition: opacity 0.15s ease-out;
`;

const UseFlagFalseButton = styled(Button)`
  font-size: 10px;
  height: auto;
  background-color: #ffffff;
  border: none;
  color: #e8e8e8;
  display: inline-block;
  width: auto;
  padding: 12px 10px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: normal;
  transition: opacity 0.15s ease-out;
`;

const MakeDownloadButton = styled(Button)`
  font-size: 10px;
  border: none;
  height: auto;
  display: inline-block;
  width: auto;
  padding: 12px 10px 10px;
  text-align: center;
  font-weight: bold;
  transition: opacity 0.15s ease-out;
  background-color: #ffffff;
  color: #0783f4;
  text-decoration: underline;
`;

export default ShopUseLimitRow;
