import * as React from 'react';
import Box from './Box';
import { Select, Wrapper } from './SelectBox';
import { OptionGroupInterface, OptionInterface } from '../../utils/interface';

type Props = {
  theme?: 'white' | 'gray';
  name: string;
  optionGroups: OptionGroupInterface[];
  selectedOption?: OptionInterface;
  onChanged: (name: string, value?: OptionInterface) => void;
  loading?: boolean;
  disabled?: boolean;
};

const SelectGroupBox: React.FC<Props> = props => {
  const { name, theme, loading, disabled, optionGroups, onChanged } = props;
  const { selectedOption } = props;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value.split(' ');
    const selectGroup = optionGroups.find(
      (optionGroup: OptionGroupInterface) => optionGroup.optionGroupId.toString() === value[0],
    );
    if (selectGroup === undefined) return;

    const selectedOption = selectGroup.options.find(
      (option: OptionInterface) => option.value.toString() === e.target.value,
    );
    onChanged(name, selectedOption);
  };

  return (
    <Wrapper>
      <Box theme={theme} label={selectedOption ? selectedOption.label : ''} loading={loading} />
      <Select
        name={name}
        disabled={disabled}
        value={selectedOption ? selectedOption.value : ''}
        onChange={handleChange}
      >
        {optionGroups.map((optionGroup, i) => (
          <optgroup key={i} label={optionGroup.optionGroupLabel}>
            {optionGroup.options.map((option, j) => (
              <option key={j} value={option.value}>
                {option.label}
              </option>
            ))}
          </optgroup>
        ))}
      </Select>
    </Wrapper>
  );
};

SelectGroupBox.defaultProps = {
  loading: false,
  disabled: false,
};

export default SelectGroupBox;
