import axios from './httpClient';

type AddFlagParam = {
  qa: string;
  content: string;
};

type EditFlagParam = {
  answer_setting_id: number;
  qa: string;
  content: string;
};

type DeleteFlagParam = {
  answer_setting_id: number;
};

export const AnswerSettings = {
  getAll: () => axios.get('answer_settings').catch(error => error.response),
  add: (argParams: AddFlagParam) =>
    axios.post('answer_settings', { qa: argParams.qa, content: argParams.content }).catch(error => error.response),
  edit: (argParams: EditFlagParam) => axios.put('answer_settings', argParams, {}).catch(error => error.response),
  delete: (argParams: DeleteFlagParam) =>
    axios.delete(`answer_settings/${argParams.answer_setting_id}`, {}).catch(error => error.response),
};
