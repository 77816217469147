import styled from 'styled-components';
import { ReactComponent as Logo } from './svg/softbank.svg';

type Props = {
  size: number;
};

const IconSoftbank = styled(Logo)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size * 0.146}px`};
`;

export default IconSoftbank;
