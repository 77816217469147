import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import RadioButton from '../../components/atoms/RadioButton';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconBack from '../../components/atoms/icons/IconBack';
import OperatorSkillList from '../../components/molecules/OperatorSkillList';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Operators as OperatorAPI } from '../../api/admin';
import { operatorActions, operatorModels } from '../../features/operator';
import { businessModels } from '../../features/business';
import { skillActions, skillModels } from '../../features/skill';
import { userModels } from '../../features/user';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import moment from 'moment';

type Props = {
  children?: React.ReactNode;
  token: string;
  operatorId: string;
  operator: operatorModels.OperatorDetail;
  businesses: businessModels.Business[];
  skills: skillModels.Skill[];
  user: userModels.User;
  operatorSkills: skillModels.OperatorSkill[];
  setOperator: (operator: operatorModels.OperatorDetailJSON) => {};
  setOperatorChatFlag: (flag: boolean) => {};
  setSkillNum: (value: number) => {};
  setOperatorSkills: (json: skillModels.OperatorSkillJSON[]) => void;
  //changePoints: (bizIndex: number, skillIndex: number, value: number) => {};
  //changeItems: (bizIndex: number, skillIndex: number, value: number) => {};
  //checkSkill: (bizIndex: number, skillIndex: number) => {};
  //toggleBiz: (bizIndex: number) => {};
};

type State = {
  operator: operatorModels.OperatorDetail;
  operatorSkills: operatorModels.Business[];
  isLoading: boolean;
  isSaving: boolean;
  isSkillSaved: boolean;
  isSettingSaved: boolean;
};

const mapStateToProps = (state: Types.RootState, ownProps: RouteComponentProps<{ operator_id: string }>) => {
  return {
    ...ownProps,
    ...state.user,
    operatorId: ownProps.match.params.operator_id,
    operator: state.operator.operatorDetail,
    businesses: state.business.businesses,
    skills: state.skill.skills,
  };
};

const mapDispatchToProps = {
  setOperator: operatorActions.setDetail,
  setOperatorChatFlag: operatorActions.setChatFlag,
  setSkillNum: operatorActions.setSkillNum,
  //changePoints: operatorActions.changePoints,
  //changeItems: operatorActions.changeItems,
  //checkSkill: operatorActions.checkSkill,
  //toggleBiz: operatorActions.toggleBiz,

  setSkills: skillActions.set,
  setOperatorSkills: skillActions.opSet,
};

class OperatorEdit extends React.Component<Props, State> {
  readonly state: State = {
    operator: {
      name: '',
      type: '',
      userId: 0,
      businesses: [],
      chatUsable: true,
      fontSize: 0,
      operatorCode: '',
      status: '',
      skillNum: 0,
    },
    operatorSkills: [],
    isLoading: true,
    isSaving: false,
    isSettingSaved: false,
    isSkillSaved: false,
  };

  componentDidMount(): void {
    this.fetchOperator();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isStorePropsExist = this.props.skills.length > 0 && this.props.businesses.length > 0;
    const isFetchedOperator = this.props.operator.businesses;

    if (isStorePropsExist && isFetchedOperator && this.state.operatorSkills.length === 0) {
      const operatorSkills: operatorModels.Business[] = [];
      for (let i = 0; i < this.props.businesses.length; i++) {
        const propBusiness = this.props.businesses[i];
        const businessId = propBusiness.businessId;
        const businessName = propBusiness.name;
        const business: operatorModels.Business = {
          businessId: businessId,
          name: businessName,
          isOpen: true,
          skills: [],
        };

        const propSkills = this.props.skills.filter(skill => skill.businessId === businessId);
        for (let j = 0; j < propSkills.length; j++) {
          const skillId = propSkills[j].skillId;

          const operatorBusiness = this.props.operator.businesses.filter(
            business => business.businessId === businessId,
          );
          const skills: operatorModels.Skill[] = [];
          if (operatorBusiness.length > 0) {
            operatorBusiness[0].skills.filter(skill => skill.skillId === skillId).forEach(skill => skills.push(skill));
          }

          const operatorSkill: operatorModels.Skill = {
            isChecked: skills.length !== 0,
            businessId: propSkills[j].businessId,
            skillId: propSkills[j].skillId,
            name: propSkills[j].name,
            skillPoints: skills.length === 0 ? 0 : skills[0].skillPoints,
            maxAcceptableItems: skills.length === 0 ? 0 : skills[0].maxAcceptableItems,
            createdAt: skills.length === 0 ? '' : skills[0].createdAt,
            order: skills.length === 0 ? 99 : skills[0].order,
          };
          business.skills.push(operatorSkill);
        }
        operatorSkills.push(business);
      }
      this.setState({ isLoading: false, operatorSkills: operatorSkills });
    }
  }

  fetchOperator = () => {
    console.log('----- fetchOperator -----');
    this.setState({ isLoading: true });
    OperatorAPI.get(this.props.operatorId)
      .then(response => {
        if (response.status === 200) {
          this.props.setOperator(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => this.setState({ operator: this.props.operator, operatorSkills: [] }))
      .catch(error => {
        alert(error);
        this.setState({ operator: this.props.operator });
      });
  };

  handleChatFlgChange = (flag: boolean) => {
    /*
    const editedOperator = this.props.operator;
    editedOperator.chatUsable = flag;
    this.setState({
      operator: editedOperator,
    });
    */
    this.props.setOperatorChatFlag(flag);
    this.setState({ operator: this.props.operator });
  };

  handleBusinessToggle = (bizIdx: number) => {
    //this.props.toggleBiz(bizIdx);
    const copyBusinesses = this.state.operatorSkills.slice();
    copyBusinesses[bizIdx].isOpen = !copyBusinesses[bizIdx].isOpen;
    this.setState({ operatorSkills: copyBusinesses });
  };

  handleSkillCheckChange = (bizIdx: number, skillIdx: number) => {
    //this.props.checkSkill(bizIdx, skillIdx);
    const copyBusinesses = this.state.operatorSkills.slice();
    const copySkills = copyBusinesses[bizIdx].skills.slice();

    //チェック変更
    copySkills[skillIdx].isChecked = !copySkills[skillIdx].isChecked;
    copyBusinesses[bizIdx].skills = copySkills;

    //チェック数変更
    let skillNum = 0;
    copyBusinesses.forEach(business => {
      skillNum += business.skills.filter(skill => skill.isChecked).length;
    });
    //this.state.operator.skillNum = skillNum;
    this.props.setSkillNum(skillNum);

    this.setState({ operatorSkills: copyBusinesses });
  };

  handleOperatorSkillPointsChange = (value: string, bizIdx: number, skillsIdx: number) => {
    const operatorSkills = this.state.operatorSkills;
    operatorSkills[bizIdx].skills[skillsIdx].skillPoints = Number(value);
    this.setState({ operatorSkills: operatorSkills });
  };

  handleMaxAcceptableItemsChange = (value: string, bizIdx: number, skillsIdx: number) => {
    const operatorSkills = this.state.operatorSkills;
    operatorSkills[bizIdx].skills[skillsIdx].maxAcceptableItems = Number(value);
    this.setState({ operatorSkills: operatorSkills });
  };

  SaveOperator = () => {
    this.setState(
      {
        isSaving: true,
        isLoading: true,
      },
      this.CallbackSaveOperator,
    );
  };

  CallbackSaveOperator = () => {
    const skills: operatorModels.Skill[] = [];
    const businesses = this.state.operatorSkills;

    for (let i = 0; i < businesses.length; i++) {
      for (let j = 0; j < businesses[i].skills.length; j++) {
        const skill = businesses[i].skills[j];
        if (skill.isChecked) {
          skills.push({
            isChecked: false,
            businessId: skill.businessId,
            skillId: skill.skillId,
            skillPoints: skill.skillPoints,
            maxAcceptableItems: skill.maxAcceptableItems,
            name: skill.name,
            createdAt: skill.createdAt,
            order: skill.order,
          });
        }
      }
    }

    const skillParam: operatorModels.PutSkillsParam = {
      operators: [this.props.operator.userId],
      skills: skills.map(skill => {
        return {
          skill_id: skill.skillId,
          skill_points: skill.skillPoints,
          max_acceptable_items: skill.maxAcceptableItems,
        };
      }),
    };

    const settingParam: operatorModels.PutSettingsParam = {
      operator_ids: [this.props.operator.userId],
      chat_usable: this.props.operator.chatUsable,
    };

    const promises: Promise<any>[] = [];
    promises.push(OperatorAPI.putSettings(settingParam));
    promises.push(OperatorAPI.putSkills(skillParam));

    Promise.all(promises)
      .then(response => {
        this.setState(
          {
            isSkillSaved: true,
            isSettingSaved: true,
            isLoading: false,
            isSaving: false,
          },
          () => {
            //APIから取得すると更新前情報が取得されてしまうため、プログラムで見た目の初期化を行う
            const copyBusinesses = this.state.operatorSkills.slice();
            copyBusinesses.forEach(business => {
              const copySkills = business.skills.slice();
              copySkills.forEach(skill => {
                if (skill.isChecked && skill.createdAt.length === 0) {
                  skill.createdAt = moment().format('YYYY/MM/DD');
                }
                if (!skill.isChecked) {
                  skill.skillPoints = 0;
                  skill.maxAcceptableItems = 0;
                  skill.createdAt = '';
                }
              });

              business.skills = copySkills;
            });

            this.setState({ operatorSkills: copyBusinesses });
            if (response[0].status === 204 && response[1].status === 204) {
              if (this.props.operator.userId === this.props.user.userId) {
                //更新対象がログインユーザの場合、スキルを更新
                const operatorsSkillJson: skillModels.OperatorSkillJSON[] = [];
                skills
                  .sort((a, b) => {
                    if (a.order !== b.order) {
                      return a.order - b.order;
                    } else {
                      return a.skillId - b.skillId;
                    }
                  })
                  .forEach(skill => {
                    operatorsSkillJson.push({
                      attributes: {
                        business_id: skill.businessId,
                        skill_id: skill.skillId,
                        name: skill.name,
                        order: skill.order,
                      },
                    });
                  });
                this.props.setOperatorSkills(operatorsSkillJson);
              }
              alert('更新が完了しました');
            } else {
              console.log(response);
              alert(`エラーが発生しました`);
            }
          },
        );
      })
      .catch(error => {
        console.log(error);
        alert('エラーが発生しました');
      });
  };

  CancelOperator = () => {
    this.fetchOperator();
  };

  render() {
    const { isLoading, isSaving } = this.state;
    const { operator } = this.props;
    const { operatorSkills } = this.state;

    return (
      <StyleBase>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <StyleIconBack size={16} />
          <RouteLink to={'/operator'}>
            <Label text="オペレーター一覧に戻る" />
          </RouteLink>
        </Header>
        <Wrapper>
          <Title>
            <OperatorIconArrow size={16} />
            <TitleLabel size={16} text="オペレーター情報" />
          </Title>
          <Container>
            <InfoTable>
              <InfoBody>
                <InfoRow>
                  <InfoCellLeft>
                    <InnerTitle text="名前" size={16} />
                    <Label text={operator.name} size={14} />
                  </InfoCellLeft>
                  <InfoCell>
                    <InnerTitle text="権限" size={16} />
                    <Label text={operator.type} size={14} />
                  </InfoCell>
                  <InfoCell>
                    <InnerTitle text="ID" size={16} />
                    <Label text={operator.operatorCode} size={14} />
                  </InfoCell>
                  <InfoCell>
                    <InnerTitle text="登録窓口数" size={16} />
                    <Label text={operator.skillNum === undefined ? '' : `${operator.skillNum}`} size={14} />
                  </InfoCell>
                  <InfoCellRight>
                    <InnerTitle text="チャット利用フラグ" size={16} />
                    <section>
                      <RadioButton
                        value="1"
                        name="chat"
                        text="表示"
                        checked={operator.chatUsable}
                        onChange={() => this.handleChatFlgChange(true)}
                      />
                      <RadioButton
                        value="0"
                        name="chat"
                        text="非表示"
                        checked={!operator.chatUsable}
                        onChange={() => this.handleChatFlgChange(false)}
                      />
                    </section>
                  </InfoCellRight>
                </InfoRow>
              </InfoBody>
            </InfoTable>
          </Container>
          <Title>
            <SkillsIconArrow size={16} />
            <TitleLabel size={16} text="担当窓口" />
          </Title>
          <Container>
            <OperatorSkillList
              businesses={operatorSkills}
              onBusinessToggle={this.handleBusinessToggle}
              onSkillCheckChange={this.handleSkillCheckChange}
              onSkillPointChange={this.handleOperatorSkillPointsChange}
              onMaxAcceptableItemsChange={this.handleMaxAcceptableItemsChange}
            />
          </Container>
          <Padding>&nbsp;</Padding>
        </Wrapper>
        <Footer>
          <Label text="変更内容を" />
          <SaveButton
            type="button"
            label="保存"
            kind="primary"
            onClick={() => this.SaveOperator()}
            disabled={isSaving || isLoading}
          />
          <CancelButton type="button" label="キャンセル" disabled={isLoading} onClick={() => this.CancelOperator()} />
        </Footer>
      </StyleBase>
    );
  }
}

const Container = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 50px;
`;

const StyleBase = styled(Base)`
  position: relative;
  overflow: scroll;
`;

const Padding = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.div`
  background-color: #ffffff;
  margin-bottom: 30px;
  margin-left: -20px;
  margin-top: -20px;
  padding-top: 15px;
  padding-left: 20px;
  width: calc(100% + 50px);
  height: 50px;
`;

const Footer = styled.div`
  background-color: #ffffff;
  padding: 15px 50px 0px 0px;
  margin-left: -20px;
  text-align: right;
  border-top: 2px solid #fafafa;
  position: fixed;
  bottom: 0;
  width: calc(100% - 180px);
  height: 70px;
`;

const Title = styled.div`
  height: 45px;
`;

const InfoTable = styled.table``;

const InfoBody = styled.tbody``;

const InfoRow = styled.tr``;

const InfoCell = styled.td`
  padding: 18px;
  width: 18%;
  margin: 5px;
  border-right: 1px solid #e8e8e8;

  :nth-child(2) {
    width: 22%;
  }
`;

const InfoCellLeft = styled(InfoCell)`
  width: 20%;
`;
const InfoCellRight = styled(InfoCell)`
  width: 25%;
  border: none;
`;

const InnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  margin-bottom: 15px;
`;

const StyleIconBack = styled(IconBack)`
  stroke: #707070;
  padding-right: 10px;
`;

const Wrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const OperatorIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

const SkillsIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

const SaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const CancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

const RouteLink = styled(Link)`
  cursor: pointer;
  color: #000000;
  text-decoration: none;
`;

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEdit);
