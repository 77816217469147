import axios from './httpClient';
import Responses from 'Responses';

export const System = {
  getMessages: () => axios.get('system/messages').catch(error => error.response),
  putMessages: (data: Responses.SystemMessages) =>
    axios
      .put(`system/messages/${data.id}`, {
        ...data,
      })
      .catch(error => error.response),
};
