import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { Business } from './models';

export type BusinessState = {
  businesses: Business[];
};

const initialState: BusinessState = {
  businesses: [],
};

const businesses = createReducer<Business[], Action>(initialState.businesses).handleType(
  SET,
  (state, action: Action) => action.payload,
);

export default combineReducers({ businesses });
