import * as Axios from 'axios';
import { ADMIN_API } from '../config';
import { configure } from '../utils';

const axios = Axios.default.create({
  baseURL: `${ADMIN_API.apiUrl}/${ADMIN_API.apiVer}`,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
  transformResponse: [
    function(data) {
      if (data) {
        return JSON.parse(data).data;
      }
      return data;
    },
  ],
});

configure(axios);

export default axios;
