import axios from './httpClient';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
  filter?: object;
  keyword?: string;
};

export const Blacklists = {
  getAll: (option: Option = { all: false }) =>
    axios.get('blacklists', { params: option }).catch(error => error.response),
  get: (crewCode: string) => axios.get(`blacklists/${crewCode}`).catch(error => error.response),
  put: (crewCode: string) => axios.put(`blacklists/${crewCode}/blacklisted`, {}, {}).catch(error => error.response),
  delete: (crewCode: string) => axios.put(`blacklists/${crewCode}/clear`, {}, {}).catch(error => error.response),
};
