import axios from './httpClient';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
};

export const ShopUseLimit = {
  get: (option: Option = {}) => {
    return axios.get(`shopuselimit`, { params: option }).catch(error => error.response);
  },
  getRollback: (fileId: number) => axios.get(`shopuselimit/${fileId}/rollback`, {}).catch(error => error.response),
  post: (data: FormData) => {
    return axios
      .post(`shopuselimit`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => error.response);
  },
  getFormat: () => {
    return axios.get(`shopuselimit/csvformat`, {}).catch(error => error.response);
  },
  getDownload: (fileId: number) => {
    return axios.get(`shopuselimit/${fileId}/download`, {}).catch(error => error.response);
  },
  getMakeDownload: (fileId: number) => {
    return axios.get(`shopuselimit/${fileId}/make_download`, {}).catch(error => error.response);
  },
  getProgress: () => {
    return axios.get(`shopuselimit/progress`, {}).catch(error => error.response);
  },
};
