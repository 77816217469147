import * as React from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  selected?: true | false;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

const Tab: React.FC<Props> = props => {
  const { label } = props;
  return (
    <Wrapper {...props} onClick={props.onClick}>
      {label}
    </Wrapper>
  );
};

const selectStyle = (isSelected: boolean | undefined) => {
  if (isSelected === true) {
    return `
      -webkit-border-radius: 16px 16px 0px 0px;
      -moz-border-radius: 16px 16px 0px 0px;
      border-radius: 16px 16px 0px 0px;
    
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #e8e8e8;
      border-bottom: none;
      cursor: default;
      margin-bottom: -1px;
    `;
  } else {
    return `
      //border: 1px solid #fafafa;
      color: #338CD8;
    `;
  }
};

const Wrapper = styled.div`
  line-height: 50px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;

  ${(props: Props) => selectStyle(props.selected)};
`;

export default Tab;
