import React from 'react';
import styled from 'styled-components';
import OperatorSkillBizElem from './OperatorSkillBizElem';
import { operatorModels } from '../../features/operator';

type Props = {
  businesses: operatorModels.Business[];
  onBusinessToggle: (bizIdx: number) => void;
  onSkillCheckChange: (bizIdx: number, skillIdx: number) => void;
  onSkillPointChange: (value: string, bizIdx: number, skillIdx: number) => void;
  onMaxAcceptableItemsChange: (value: string, bizIdx: number, skillIdx: number) => void;
};

const OperatorSkillList: React.FC<Props> = props => {
  if (props.businesses === undefined) {
    return <div />;
  }
  return (
    <div>
      {props.businesses.map((business, i) => (
        <div key={i}>
          <BizList>
            <BizElem
              business={business}
              bizIdx={i}
              onBusinessToggle={props.onBusinessToggle}
              onSkillCheckChange={props.onSkillCheckChange}
              onSkillPointChange={props.onSkillPointChange}
              onMaxAcceptableItemsChange={props.onMaxAcceptableItemsChange}
            />
          </BizList>
        </div>
      ))}
    </div>
  );
};

const BizList = styled.div`
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin: 30px;
`;

const BizElem = styled(OperatorSkillBizElem)`
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0 10px 10px;
`;

export default OperatorSkillList;
