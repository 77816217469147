import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Types from 'MyTypes';
import { OptionGroupInterface, OptionInterface } from '../../utils/interface';
import { operatorActions, operatorModels } from '../../features/operator';
import { businessModels } from '../../features/business';
import { skillActions, skillModels } from '../../features/skill';
import { userModels } from '../../features/user';
import Base from '../../components/molecules/Base';
import Input from '../../components/atoms/Input';
import RadioButton from '../../components/atoms/RadioButton';
import SelectGroupBox from '../../components/atoms/SelectGroupBox';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconLoupe from '../../components/atoms/icons/IconLoupe';
import IconClose from '../../components/atoms/icons/IconClose';
import Label from '../../components/atoms/Label';
import Checkbox from '../../components/atoms/Checkbox';
import Button from '../../components/atoms/Button';
import Table, { Cell, Header as THeader } from '../../components/atoms/TableScroll';
import OperatorSkillList from '../../components/molecules/OperatorSkillList';
import { Operators as OperatorAPI } from '../../api/admin';
import PopupFrame from '../../components/molecules/PopupFrame';
import ScreenLoading from '../../components/atoms/ScreenLoading';

interface Params {
  all?: boolean;
  skill_ids?: number[];
  order?: string;
  limit?: number;
}

const ORDER_INDEX_TYPE = 0;
const ORDER_INDEX_ID = 1;
const ORDER_INDEX_HIER = 2;

const ORDER_NONE = 0;
const ORDER_ASC = 1;
const ORDER_DESC = 2;

const orderArrows = ['', '↑', '↓'];

type Props = {
  children?: React.ReactNode;
  operators: operatorModels.Operator[];
  skills: skillModels.Skill[];
  operatorSkills: skillModels.OperatorSkill[];
  user: userModels.User;
  setOperators: (operators: operatorModels.OperatorJSON) => {};
  checkOperator: (index: number) => {};
  checkAllOperators: () => {};
  setOperatorSkills: (json: skillModels.OperatorSkillJSON[]) => void;

  businesses: businessModels.Business[];
  businessTable: operatorModels.Business[];
  setBusinesses: (businesses: businessModels.Business[], skills: operatorModels.Skill[]) => {};
};

type State = {
  operators: operatorModels.Operator[];
  optionGroups: OptionGroupInterface[];
  selectedOption: OptionInterface;
  isVisibleOnlyUnassigned: boolean;
  isLoading: boolean;
  isSkillLoading: boolean;
  order: { index: number; order: number };

  businessTable: operatorModels.Business[];
  keyword: string;
  isPopup: boolean;
  isChatUsable: boolean;
  isSaving: boolean;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    ...state.user,
    operators: state.operator.operators,
    skills: state.skill.skills,
    operatorSkills: state.skill.operatorSkills,
    businesses: state.business.businesses,
    businessTable: state.operator.businesses,
  };
};

const mapDispatchToProps = {
  setOperators: operatorActions.set,
  checkOperator: operatorActions.check,
  checkAllOperators: operatorActions.checkAll,

  setBusinesses: operatorActions.setBiz,
  setOperatorSkills: skillActions.opSet,
};

class Operators extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleChatFlgChange = this.handleChatFlgChange.bind(this);
    this.handleSkillCheckChange = this.handleSkillCheckChange.bind(this);
    this.handleSkillPointsChange = this.handleSkillPointsChange.bind(this);
    this.handleMaxAcceptableItemsChange = this.handleMaxAcceptableItemsChange.bind(this);
    this.handleBusinessToggle = this.handleBusinessToggle.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  getInitOptionGroup(): OptionGroupInterface[] {
    return [
      {
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [this.getInitOption()],
      },
    ];
  }

  getInitOption(): OptionInterface {
    return {
      key: 'opt_0',
      label: '全ての窓口',
      value: '0 0',
      checked: true,
    };
  }

  readonly state: State = {
    isLoading: true,
    isSkillLoading: true,
    isVisibleOnlyUnassigned: false,
    operators: [],
    optionGroups: this.getInitOptionGroup(),
    selectedOption: this.getInitOption(),
    order: { index: ORDER_INDEX_TYPE, order: ORDER_NONE },
    businessTable: [],
    isPopup: false,
    isChatUsable: true,
    isSaving: false,
    keyword: '',
  };

  readonly stats = {
    TextProps: {
      type: 'text',
      name: 'condKeyword',
      placeholder: '検索したいキーワードを入力',
      value: '',
    },
  };

  convertOperatorSkills(businesses: businessModels.Business[], skills: skillModels.Skill[]): operatorModels.Skill[] {
    const result: operatorModels.Skill[] = [];
    businesses.forEach(business => {
      const filteredSkills = skills.filter(skill => skill.businessId === business.businessId);
      filteredSkills.forEach(filteredSkill => {
        const skill: operatorModels.Skill = {
          businessId: filteredSkill.businessId,
          skillId: filteredSkill.skillId,
          name: filteredSkill.name,
          skillPoints: 0,
          maxAcceptableItems: 0,
          isChecked: false,
          createdAt: '',
          order: filteredSkill.order,
        };
        result.push(skill);
      });
    });
    return result;
  }

  componentDidMount(): void {
    if (this.props.operatorSkills.length > 0) {
      const ids = this.props.operatorSkills.map(operatorSkill => operatorSkill.skillId);
      this.fetchOperators({ skill_ids: ids, order: this.getOrderParam() });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isStorePropsExist = this.props.skills.length > 0 && this.props.businesses.length > 0;
    if (this.props.operators.length > 0 && this.state.isLoading) {
      this.setState({ isLoading: false });
    }

    if (isStorePropsExist && this.state.businessTable.length === 0) {
      //一括編集用スキル一覧
      const skills = this.convertOperatorSkills(this.props.businesses, this.props.skills);
      this.props.setBusinesses(this.props.businesses, skills);
      this.setState({ businessTable: this.props.businessTable });
    }

    if (
      this.props.operatorSkills !== prevProps.operatorSkills ||
      (this.props.operatorSkills.length > 0 && this.state.isSkillLoading)
    ) {
      if (this.props.operators.length === 0) {
        const ids = this.props.operatorSkills.map(operatorSkill => operatorSkill.skillId);
        this.fetchOperators({ skill_ids: ids, order: this.getOrderParam() });
      }

      const optionGroups: OptionGroupInterface[] = [];
      optionGroups.push({
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [
          {
            label: '全ての窓口',
            value: '0 0',
            checked: true,
            key: 'opt_0',
          },
        ],
      });
      for (let i = 0; i < this.props.businesses.length; i++) {
        const business = this.props.businesses[i];
        const options: OptionInterface[] = [];
        const operatorSkills = this.props.operatorSkills.filter(skill => skill.businessId === business.businessId);

        for (let j = 0; j < operatorSkills.length; j++) {
          options.push({
            label: operatorSkills[j].name,
            value: `${business.businessId} ${operatorSkills[j].skillId}`,
            checked: false,
            key: `opt_${operatorSkills[j].skillId}`,
          });
        }

        optionGroups.push({
          optionGroupId: this.props.businesses[i].businessId,
          optionGroupLabel: this.props.businesses[i].name,
          options: options,
        });
      }
      this.setState({ optionGroups: optionGroups, isSkillLoading: false, isLoading: false });
    }
  }

  fetchOperators(option: Params = {}): void {
    this.setState({ isLoading: true });

    OperatorAPI.getAll(option)
      .then(response => {
        if (response.status === 200) {
          this.props.setOperators(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => this.setState({ isLoading: false, operators: this.props.operators }))
      .catch(response => {
        alert(response);
        this.setState({ isLoading: false, operators: this.props.operators });
      });
  }

  handleOnKeywordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ keyword: event.target.value });
  };

  handleOnSkillChanged = (name: string, option?: OptionInterface): void => {
    if (option === undefined) return;
    const optionValue = option.value.toString().split(' ')[1];

    if (`${optionValue}` === '0') {
      const ids = this.props.operatorSkills.map(operatorSkill => operatorSkill.skillId);
      this.fetchOperators({ skill_ids: ids, order: this.getOrderParam() });
    } else {
      this.fetchOperators({ all: true, skill_ids: [+optionValue], order: this.getOrderParam() });
    }

    const optionGroups = this.state.optionGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      console.log(optionGroup.options);
      for (let j = 0; j < optionGroup.options.length; j++) {
        if (optionGroup.options[j].value === option.value) {
          optionGroup.options[j].checked = true;
          this.setState({
            selectedOption: optionGroup.options[j],
            optionGroups: optionGroups,
            isVisibleOnlyUnassigned: false,
          });
          return;
        }
      }
    }
  };

  resetOptionGroups(): OptionGroupInterface[] {
    const optionGroups = this.state.optionGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      for (let j = 0; j < optionGroup.options.length; j++) {
        optionGroup.options[j].checked = false;
      }
    }
    return optionGroups;
  }

  toggleOnlyUnassigned = (): void => {
    let { optionGroups } = this.state;

    const { isVisibleOnlyUnassigned } = this.state;
    if (isVisibleOnlyUnassigned) {
      const ids = this.props.operatorSkills.map(operatorSkill => operatorSkill.skillId);
      this.fetchOperators({ skill_ids: ids, order: this.getOrderParam() });
    } else {
      this.fetchOperators({ all: true, skill_ids: [-1], order: this.getOrderParam() });
      optionGroups = this.resetOptionGroups();
    }
    this.setState({
      optionGroups,
      selectedOption: optionGroups[0].options[0],
      isVisibleOnlyUnassigned: !isVisibleOnlyUnassigned,
    });
  };

  //-------------------------------------------------------------------
  //一括編集対応[START]
  //-------------------------------------------------------------------
  popUp = (): void => {
    this.setState({ isPopup: true });
  };

  popUpClose = (): void => {
    this.setState({ isPopup: false, businessTable: [] });
  };

  handleChatFlgChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ isChatUsable: !this.state.isChatUsable });
  };

  handleBusinessToggle = (bizIdx: number): void => {
    const copyBusinesses = this.props.businessTable.slice();
    copyBusinesses[bizIdx].isOpen = !copyBusinesses[bizIdx].isOpen;

    this.setState({ businessTable: copyBusinesses });
  };

  handleSkillCheckChange = (bizIdx: number, skillsIdx: number): void => {
    const copyBusinesses = this.props.businessTable.slice();
    const copySkills = copyBusinesses[bizIdx].skills.slice();

    copySkills[skillsIdx].isChecked = !copySkills[skillsIdx].isChecked;
    copyBusinesses[bizIdx].skills = copySkills;
    this.setState({ businessTable: copyBusinesses });
  };

  handleSkillPointsChange = (value: string, bizIdx: number, skillsIdx: number): void => {
    const operatorSkill = this.state.businessTable;
    operatorSkill[bizIdx].skills[skillsIdx].skillPoints = Number(value);
    this.setState({ businessTable: operatorSkill });
  };

  handleMaxAcceptableItemsChange = (value: string, bizIdx: number, skillsIdx: number): void => {
    const copyBusinesses = this.state.businessTable.slice();
    const business = copyBusinesses[bizIdx];
    const copySkills = business.skills.slice();
    copySkills[skillsIdx].maxAcceptableItems = Number(value);

    copyBusinesses[bizIdx].skills = copySkills;
    this.setState({ businessTable: copyBusinesses });
  };

  handleCheckOperator = (index: number): void => {
    if (this.state.keyword !== '') {
      const operators = this.props.operators.filter(operator => operator.name.indexOf(this.state.keyword) >= 0);
      for (let i = 0; i < this.props.operators.length; i++) {
        if (this.props.operators[i].userId === operators[index].userId) {
          this.props.checkOperator(i);
          break;
        }
      }
    } else {
      this.props.checkOperator(index);
    }
  };

  handleSave = (e: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState({ isSaving: true, isLoading: true });

    const skills: operatorModels.Skill[] = [];
    this.props.businessTable.forEach((business, i) => {
      business.skills.forEach((skill, j) => {
        if (skill.isChecked) {
          skills.push({
            businessId: skill.businessId,
            skillId: skill.skillId,
            name: skill.name,
            skillPoints: skill.skillPoints,
            maxAcceptableItems: skill.maxAcceptableItems,
            createdAt: skill.createdAt,
            isChecked: skill.isChecked,
            order: skill.order,
          });
        }
      });
    });

    const skillParam: operatorModels.PutSkillsParam = {
      operators: this.props.operators.filter(operator => operator.isChecked).map(operator => operator.userId),
      skills: skills.map(skill => {
        return {
          skill_id: skill.skillId,
          skill_points: skill.skillPoints,
          max_acceptable_items: skill.maxAcceptableItems,
        };
      }),
    };

    const settingParam: operatorModels.PutSettingsParam = {
      operator_ids: this.props.operators.filter(operator => operator.isChecked).map(operator => operator.userId),
      chat_usable: this.state.isChatUsable,
    };

    console.log('----- handleSave -----');
    const promises: Promise<any>[] = [];
    promises.push(OperatorAPI.putSettings(settingParam));
    promises.push(OperatorAPI.putSkills(skillParam));

    Promise.all(promises).then(response => {
      if (response[0].status === 204 && response[1].status === 204) {
        this.setState({ isPopup: false, businessTable: [] });

        const { selectedOption } = this.state;
        const optionValue = selectedOption.value.toString().split(' ')[1];
        if (`${optionValue}` === '0') {
          this.fetchOperators({ all: true, order: this.getOrderParam() });
        } else {
          this.fetchOperators({ all: true, skill_ids: [+optionValue], order: this.getOrderParam() });
        }
        this.setState({ isSaving: false });

        if (skillParam.operators.indexOf(this.props.user.userId) >= 0) {
          //更新対象がログインユーザの場合、スキルを更新
          const operatorsSkillJson: skillModels.OperatorSkillJSON[] = [];
          skills
            .sort((a, b) => {
              if (a.order !== b.order) {
                return a.order - b.order;
              } else {
                return a.skillId - b.skillId;
              }
            })
            .forEach(skill => {
              operatorsSkillJson.push({
                attributes: {
                  business_id: skill.businessId,
                  skill_id: skill.skillId,
                  name: skill.name,
                  order: skill.order,
                },
              });
            });
          this.props.setOperatorSkills(operatorsSkillJson);
        }
        alert(`更新が完了しました`);
      } else {
        this.setState({ isSaving: false });
        alert('API サーバーで問題が発生しました');
      }
    });
  };

  isBulkEditDisabled = (): boolean => {
    if (this.state.isLoading) return true;
    if (this.props.operators === undefined || this.props.operators.length === 0) return true;
    return this.props.operators.filter(operator => operator.isChecked).length === 0;
  };

  get operators(): operatorModels.Operator[] {
    if (this.state.keyword !== '') {
      return this.props.operators.filter(operator => operator.name.indexOf(this.state.keyword) >= 0);
    } else {
      if (this.state.isLoading) {
        const operators: operatorModels.Operator[] = [];
        return operators;
      } else {
        return [...this.props.operators];
      }
    }
  }

  get optionGroups(): OptionGroupInterface[] {
    const optionGroups: OptionGroupInterface[] = this.getInitOptionGroup();
    for (let i = 0; i < this.props.businesses.length; i++) {
      const business = this.props.businesses[i];
      const options: OptionInterface[] = [];
      const operatorSkills = this.props.operatorSkills.filter(skill => skill.businessId === business.businessId);

      for (let j = 0; j < operatorSkills.length; j++) {
        options.push({
          label: operatorSkills[j].name,
          value: `${business.businessId} ${operatorSkills[j].skillId}`,
          checked: false,
          key: `opt_${operatorSkills[j].skillId}`,
        });
      }

      optionGroups.push({
        optionGroupId: this.props.businesses[i].businessId,
        optionGroupLabel: this.props.businesses[i].name,
        options: options,
      });
    }

    return optionGroups;
  }
  //-------------------------------------------------------------------
  //一括編集対応[END]
  //-------------------------------------------------------------------

  //-------------------------------------------------------------------
  //ソート[START]
  //-------------------------------------------------------------------
  orderValue(index: number): string {
    if (index === this.state.order.index) {
      return orderArrows[this.state.order.order];
    } else {
      return '';
    }
  }

  orderChange(index: number): void {
    let order = ORDER_NONE;
    if (this.state.order.index === index) {
      order = (this.state.order.order + 1) % 3;
    } else {
      order = ORDER_ASC;
    }

    this.setState(
      {
        order: { index: index, order: order },
      },
      () => {
        this.fetchOperators({ all: true, order: this.getOrderParam() });
      },
    );
  }

  getOrderParam(): string {
    if (this.state.order.order === ORDER_NONE) return null;

    let order = '';
    if (this.state.order.index === 0) order = 'operators.type';
    if (this.state.order.index === 1) order = 'users.user_id';
    if (this.state.order.index === 2) order = 'operators.created_at';

    if (this.state.order.order === ORDER_DESC) order = '-' + order;

    return order;
  }
  //-------------------------------------------------------------------
  //ソート[END]
  //-------------------------------------------------------------------

  render() {
    const { operators, stats, optionGroups } = this;
    const {
      //optionGroups,
      selectedOption,
      isVisibleOnlyUnassigned,
      isLoading,
      isSkillLoading,
      isSaving,
      isPopup,
      isChatUsable,
      businessTable,
    } = this.state;
    const { checkAllOperators } = this.props;

    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <StyleIconArrow size={14} />
          <TitleLabel text="オペレーター管理" />
        </Header>
        <Area>
          <WrapCond1>
            <StyleLabel text="窓口の絞り込み" size={12} />
            <WrapCondItem>
              <StyleSelectGroupBox
                name={'skills'}
                optionGroups={optionGroups}
                selectedOption={selectedOption}
                theme="gray"
                loading={isSkillLoading}
                disabled={isSkillLoading}
                onChanged={this.handleOnSkillChanged}
              />
            </WrapCondItem>
          </WrapCond1>
          <WrapCond2>
            <StyleLabel text="オペレーターの絞り込み" size={12} />
            <WrapCondItem>
              <StyleIconLoupe size={14} />
              <StyleSearchInput
                type={stats.TextProps.type}
                theme="gray"
                name={stats.TextProps.name}
                value={this.state.keyword}
                placeholder={stats.TextProps.placeholder}
                onChange={this.handleOnKeywordChange}
              />
            </WrapCondItem>
          </WrapCond2>
          <Se />
          <WrapCond3>
            <Checkbox
              disabled={isLoading}
              checked={isVisibleOnlyUnassigned}
              label="担当窓口のないオペレーターのみを表示"
              onClick={this.toggleOnlyUnassigned}
            />
          </WrapCond3>
          <Se />
          <WrapCond3>
            <BulkEditButton type="button" label="一括編集" disabled={this.isBulkEditDisabled()} onClick={this.popUp} />
          </WrapCond3>
          <WrapCond4>
            <Table>
              <THeader>
                <HeaderCell>
                  <Checkbox
                    disabled={isLoading}
                    checked={operators.length > 0 && operators.every(operator => operator.isChecked)}
                    onClick={checkAllOperators}
                  />
                </HeaderCell>
                <HeaderCell>オペレーター名</HeaderCell>
                <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_TYPE)}>
                  権限<Order>{this.orderValue(ORDER_INDEX_TYPE)}</Order>
                </HeaderCell>
                <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_ID)}>
                  ID<Order>{this.orderValue(ORDER_INDEX_ID)}</Order>
                </HeaderCell>
                <HeaderCell>担当窓口</HeaderCell>
                <HeaderCell />
                <HeaderCell onClick={() => this.orderChange(ORDER_INDEX_HIER)}>
                  入社時期<Order>{this.orderValue(ORDER_INDEX_HIER)}</Order>
                </HeaderCell>
              </THeader>
              {isLoading ? (
                <InfoRow />
              ) : (
                operators.map((operator: operatorModels.Operator, i: number) => (
                  <InfoRow key={i}>
                    <InfoCell>
                      <Checkbox checked={operator.isChecked} onClick={() => this.handleCheckOperator(i)} />
                    </InfoCell>
                    <InfoCell>
                      <StyledLink to={`/operator_edit/${operator.userId}`}>
                        {operator.name} {operator.first_name}
                      </StyledLink>
                    </InfoCell>
                    <InfoCell>{operator.type}</InfoCell>
                    <InfoCell>{operator.userId}</InfoCell>
                    <InfoCell>{operator.skillName}</InfoCell>
                    {operator.otherSkills > 0 ? <InfoCell>…他{operator.otherSkills}窓口</InfoCell> : <InfoCell />}
                    <InfoCell>{operator.createdAt}</InfoCell>
                  </InfoRow>
                ))
              )}
            </Table>
          </WrapCond4>
        </Area>
        <OperatorBulkEdit
          isLoading={isLoading}
          isSaving={isSaving}
          isPopup={isPopup}
          isChatUsable={isChatUsable}
          operators={operators}
          businesses={businessTable}
          onPopupClose={this.popUpClose}
          onChangeChatUsable={this.handleChatFlgChange}
          onToggleBusiness={this.handleBusinessToggle}
          onChangeSkillCheck={this.handleSkillCheckChange}
          onChangeSkillPoints={this.handleSkillPointsChange}
          onChangeMaxAcceptableItems={this.handleMaxAcceptableItemsChange}
          onClickSave={this.handleSave}
        />
      </Base>
    );
  }
}

const Header = styled.div`
  margin-bottom: 10px;
`;

const WrapCond1 = styled.div`
  width: 35%;
  float: left;
  margin: 10px;
  padding: 15px;
`;

const WrapCond2 = styled.div`
  margin: 10px;
  padding: 15px;
`;

const WrapCond3 = styled.div`
  padding-left: 25px;
`;

const WrapCond4 = styled.div`
  margin-top: 30px;
`;

const BulkEditButton = styled(Button)`
  width: 120px;
`;

const WrapCondItem = styled.div`
  margin-top: 30px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleLabel = styled(Label)`
  color: #338cd8;
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const StyleSelectGroupBox = styled(SelectGroupBox)`
  width: 300px;
`;

const StyleIconLoupe = styled(IconLoupe)`
  position: relative;
  left: 10px;
`;

const StyleSearchInput = styled(Input)`
  padding: 10px 5px 10px 30px;
  margin-left: -15px;
  border-radius: 5px 5px 5px 5px;
  font-size: 14px;
  width: 50%;
`;

const Se = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  border: none;
  border-top: 1px #f5f5f5 solid;
`;

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  margin-bottom: 30px;
`;

const HeaderCell = styled(Cell)`
  :nth-child(3) {
    cursor: pointer;
  }
  :nth-child(4) {
    cursor: pointer;
  }
  :nth-child(7) {
    cursor: pointer;
  }
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  //cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: #000000;
`;

const Order = styled.span``;

type PopupProps = {
  isLoading: boolean;
  isSaving: boolean;
  isPopup: boolean;
  operators: operatorModels.Operator[];
  businesses: operatorModels.Business[];
  isChatUsable: boolean;
  onPopupClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeChatUsable: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleBusiness: (bizIdx: number) => void;
  onChangeSkillCheck: (bizIdx: number, skillIdx: number) => void;
  onChangeSkillPoints: (value: string, bizIdx: number, skillIdx: number) => void;
  onChangeMaxAcceptableItems: (value: string, bizIdx: number, skillIdx: number) => void;
  onClickSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

class OperatorBulkEdit extends React.Component<PopupProps, State> {
  render() {
    const {
      businesses,
      isSaving,
      isPopup,
      isChatUsable,
      onPopupClose,
      onChangeChatUsable,
      onToggleBusiness,
      onChangeSkillCheck,
      onChangeSkillPoints,
      onChangeMaxAcceptableItems,
      onClickSave,
    } = this.props;

    if (!isPopup) {
      return <div />;
    }
    return (
      <Base>
        <PopupFrame isPopup={isPopup}>
          <PopupContents>
            <PopupTitile>
              <PopupTitleLabel size={16} text="オペレーター一括編集" />
              <PopupCloseArea>
                <IconClose onClick={onPopupClose} />
              </PopupCloseArea>
            </PopupTitile>
            <PopupEditArea>
              <PopupInfoTable>
                <PopupInfoBody>
                  <PopupInfoRow>
                    <PopupInfoCell>
                      <PopupInnerTitle text="チャット利用フラグ" size={16} />
                      <PopupWrapCond>
                        <RadioButton
                          value="1"
                          name="chat"
                          text="表示"
                          checked={isChatUsable}
                          onChange={onChangeChatUsable}
                        />
                        <RadioButton
                          value="0"
                          name="chat"
                          text="非表示"
                          checked={!isChatUsable}
                          onChange={onChangeChatUsable}
                        />
                      </PopupWrapCond>
                    </PopupInfoCell>
                  </PopupInfoRow>
                </PopupInfoBody>
              </PopupInfoTable>
            </PopupEditArea>
            <OperatorSkillList
              businesses={businesses}
              onBusinessToggle={onToggleBusiness}
              onSkillCheckChange={onChangeSkillCheck}
              onSkillPointChange={onChangeSkillPoints}
              onMaxAcceptableItemsChange={onChangeMaxAcceptableItems}
            />
            <PopupFooter>
              <PopupSaveButton type="button" label="保存" kind="primary" onClick={onClickSave} disabled={isSaving} />
              <PopupCancelButton type="button" label="キャンセル" onClick={onPopupClose} />
            </PopupFooter>
          </PopupContents>
        </PopupFrame>
      </Base>
    );
  }
}

const PopupEditArea = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;

  margin: 30px;
`;

const PopupWrapCond = styled.section``;

const PopupFooter = styled.div`
  background-color: #ffffff;
  padding: 0px 30px 0px 0px;
  text-align: right;
  border-top: 1px solid #fafafa;
  margin-bottom: 20px;
`;

const PopupTitile = styled.div`
  height: 45px;
  padding: 10px 0 40px 10px;
  margin: 10px 0 30px 0;
  border-bottom: 1px solid #e8e8e8;
`;

const PopupInfoTable = styled.table``;

const PopupInfoBody = styled.tbody``;

const PopupInfoRow = styled.tr``;

const PopupInfoCell = styled.td`
  padding: 18px;
  width: 18%;
  margin: 5px;
`;

const PopupInnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  margin-bottom: 15px;
`;

const PopupContents = styled.div``;

const PopupTitleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  padding: 10px;
`;

const PopupCloseArea = styled.div`
  float: right;
  padding-top: 5px;
  margin-right: 20px;
`;

const PopupSaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
`;

const PopupCancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Operators);
