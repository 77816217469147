import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import IconArrow from '../../components/atoms/icons/IconArrow';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import { MorphologicalAnalysis as MorphologicalAnalysisAPI } from '../../api/admin/morphologicalAnalysis';
import ScreenLoading from '../../components/atoms/ScreenLoading';

type Props = {
  token: string;
  children?: React.ReactNode;
};

type State = {
  isLoading: boolean;
  isWorkingJob: boolean;
  isDownload: boolean;
  isPopup: boolean;
  inUsedCsvName: string;
  rollbackCsvName: string;
  fileId: number;
  downloadFileId: number;
  errorMeassge: string;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    token: state.auth.token,
  };
};

const mapDispatchToProps = {};

class MorphologicalAnalysis extends React.Component<Props, State> {
  readonly state = {
    isLoading: false,
    isWorkingJob: false,
    isDownload: false,
    isPopup: false,
    inUsedCsvName: '',
    rollbackCsvName: '',
    fileId: -1,
    downloadFileId: -1,
    errorMessage: '',
  };

  constructor(props: Props) {
    super(props);

    this.uploadCSV = React.createRef();
  }

  componentDidMount() {
    // this.getShopUseLimit();
    // setInterval(this.getJobProgress, 15000);
  }

  sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

  createUploadCSV = async fileData => {
    this.setState({ isWorkingJob: true });
    this.setState({ isLoading: true });
    const res = await MorphologicalAnalysisAPI.post(fileData);
    if (res.status === 200 && res.data != 'undefined') {
      await this.downloadCsv(res.data);
    } else {
      alert('形態素解析に失敗しました。');
    }

    this.setState({ isLoading: false });
    this.setState({ isWorkingJob: false });
  };

  handleClickUploadCSV = () => {
    this.uploadCSV.current.click();
  };

  handleUploadCsv = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const isUpload = window.confirm(`${file.name}をアップロードしますか？`);
    if (isUpload) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const fileData = new FormData();
      fileData.append('file', file);
      this.createUploadCSV(fileData);
    }
  };

  handleOnClickPopup = (fileName: string, fileId: number) => {
    this.setState({ fileId: fileId, rollbackCsvName: fileName, isPopup: !this.state.isPopup });
  };

  downloadCsv = async (fileId: number) => {
    const element = document.createElement('a');
    element.setAttribute('href', MorphologicalAnalysisAPI.getDownloadUrl(fileId));
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    console.log('fin.');
  };

  render() {
    const { isLoading, isWorkingJob } = this.state;
    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <StyleIconArrow size={14} />
          <TitleLabel text="形態素解析結果の取得" />
        </Header>
        <Area>
          <WrapCond1>
            <StyleLabel text="形態素解析結果の取得" size={12} />
            <WrapCondItem>
              <ImportButton
                type="button"
                label="+ CSVで形態素解析結果を取得"
                kind="primary"
                disabled={isWorkingJob}
                onClick={this.handleClickUploadCSV}
              />
              <FileInput type="file" name="uploadCSV" value="" onChange={this.handleUploadCsv} ref={this.uploadCSV} />
            </WrapCondItem>
            <WorkingJobLabel text={`処理を実行中です。`} size={12} visible={isWorkingJob} />
          </WrapCond1>
        </Area>
      </Base>
    );
  }
}

type VisibleProps = {
  visible?: boolean;
};

const Header = styled.div`
  margin-bottom: 10px;
`;

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding-bottom: 20px;
`;

const WrapCond1 = styled.div`
  width: 35%;
  margin: 10px;
  padding: 15px;
`;

const WrapCondItem = styled.div`
  margin-top: 30px;
`;

const ImportButton = styled(Button)`
  font-size: 12px;
  height: auto;
  margin: 20px 10px 10px 10px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  font-size: 20px;
  margin: 5px 10px 10px 10px;
`;

const WorkingJobLabel = styled(Label)`
  font-weight: bold;
  font-size: 20px;
  margin: 5px 10px 10px 10px;
  display: ${(props: VisibleProps) => (props.visible === false ? 'none' : 'block')};
`;

const StyleIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
`;

const FileInput = styled.input`
  display: none;
`;

export default connect(mapStateToProps, mapDispatchToProps)(MorphologicalAnalysis);
