import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: none;
  height: 500px;
  overflow-x: scroll;
`;

const InfoTable = styled.div`
  display: table;
  width: 100%;
`;

export const Row = styled.div`
  display: table-row;
  white-space: nowrap;
`;

export const Header = styled(Row)`
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Cell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

type Props = {
  children: React.ReactNode;
};

const Table: React.FC<Props> = props => {
  return (
    <Wrapper>
      <InfoTable>{props.children}</InfoTable>
    </Wrapper>
  );
};

export default Table;
