import { createAction } from 'typesafe-actions';
//import { SET, ADD, EDIT, DELETE, CHECK, CHECK_ALL } from './constants';
import { SET } from './constants';
import { Tag, TagJSON } from './models';

export const set = createAction(SET, (json: TagJSON[]) => {
  return json.map(
    (tag: TagJSON) =>
      ({
        isEditing: false,
        tagId: tag.attributes.tag_id,
        skillId: tag.attributes.skill_id,
        name: tag.attributes.name,
      } as Tag),
  ) as Tag[];
})<Tag[]>();

//export const add = createAction(CHECK, (index: number) => index)<number>();
//export const check = createAction(CHECK, (index: number) => index)<number>();
//export const checkAll = createAction(CHECK_ALL, () => null)<null>();
//export const editFlag = createAction(EDIT, (index: number) => index)<number>();
//export const deleteFlag = createAction(DELETE, (index: number) => index)<number>();
