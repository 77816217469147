import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { Category } from './models';

export type CategoryState = {
  categories: Category[];
};

const initialState: CategoryState = {
  categories: [],
};

const categories = createReducer<Category[], Action>(initialState.categories).handleType(
  SET,
  (state: Category[], action: Action) => action.payload,
);

export default combineReducers({ categories });
