import * as React from 'react';
import styled from 'styled-components';
import IconCheck from '../../components/atoms/icons/IconCheck';

type Props = {
  size: number;
  fill?: string;
  strokeColor?: string;
  checked?: true | false;
  index?: number;
  onColorToggle?: (index: number) => void;
};

const ColorRadio: React.FC<Props> = props => {
  return (
    <span>
      <StyleIconCheck
        size={props.size}
        fill={props.fill}
        checked={props.checked}
        index={props.index}
        strokeColor={props.strokeColor}
        onColorToggle={props.onColorToggle}
      />
    </span>
  );
};

const selectStyle = (stroke: string, isActive: boolean | undefined) => {
  if (isActive) {
    if (stroke === undefined) {
      return `stroke: #ffffff;`;
    } else {
      return `stroke: ${stroke}`;
    }
  } else {
    return ``;
  }
};

const StyleIconCheck = styled(IconCheck)`
  ${(props: Props) => selectStyle(props.strokeColor, props.checked)};
`;

export default ColorRadio;
