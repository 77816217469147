import React, { forwardRef } from 'react';

interface HiddenInputProps {
  onFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputFile = forwardRef<HTMLInputElement, HiddenInputProps>(({ onFileInputChange }, inputRef) => {
  return <input hidden ref={inputRef} name="csv" type="file" accept="csv/*" onChange={onFileInputChange} />;
});

export default InputFile;
