import * as React from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  theme?: 'gray';
  value: string;
  rows: number;
  maxRows?: number;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
};

export type Ref = HTMLTextAreaElement;

const TextArea = React.forwardRef<Ref, Props>((props, ref) => {
  const { name, value, theme, rows, maxRows, placeholder, autoFocus, onChange, onKeyDown, onKeyUp, ...rest } = props;

  const resizeTextArea = (target: EventTarget & HTMLTextAreaElement) => {
    target.style.height = '24px';
    target.style.height = target.scrollHeight + 'px';
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    resizeTextArea(event.target);
    onChange(event);
  };

  return (
    <StyleTextArea
      {...rest}
      ref={ref}
      name={name}
      value={value}
      theme={theme ? theme : ''}
      minHeight={rows * 23}
      maxHeight={maxRows ? maxRows * 23 : null}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
    />
  );
});

type TextAreaProps = {
  theme: string;
  minHeight: number;
  maxHeight: number;
};

const themeStyle = (theme: string) => {
  if (theme === 'gray') {
    return `
      background-color: #fafafa;
      border: 1px solid #d0d0d0;
    `;
  }
  return `
    background-color: #ffffff;
  `;
};

const StyleTextArea = styled.textarea`
  display: block;
  width: 100%;
  min-height: ${(props: TextAreaProps) => `${props.minHeight}px`};
  max-height: ${(props: TextAreaProps) => (props.maxHeight ? `${props.maxHeight}px` : `${props.minHeight}px`)};
  border: none;
  border-radius: 0;
  appearance: none;
  resize: none;
  outline: none;
  ${(props: TextAreaProps) => themeStyle(props.theme)};
`;

export default TextArea;
