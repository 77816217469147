import React from 'react';
import styled from 'styled-components';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import IconArrow from '../../components/atoms/icons/IconArrow';
// import IconForward from '../../components/atoms/icons/IconForward';
import { Link } from 'react-router-dom';
import { skillModels } from '../../features/skill';

interface Props {
  stats: skillModels.SkillsDetailStatsJSON;
  isOpen: boolean;
  onClick?: (eventName: string) => void;
}

const SkillSetting: React.FC<Props> = props => {
  const { stats } = props;

  return (
    <Wrap>
      <Header>
        <StyleIconArrow isOpen={props.isOpen} size={16} onClick={() => props.onClick('toggle')} />
        <TitleLabel text="窓口の内容設定" />
        <EditWrap>
          <RouteLink to={`/skills_edit/${stats.skill_id}`}>
            <EditButton type={'button'} label={'窓口情報変更'} />
          </RouteLink>
        </EditWrap>
      </Header>
      {!props.isOpen ? (
        <div />
      ) : (
        <Area>
          <InfoTable>
            <InfoHead>
              <InfoRow>
                <InfoCellLeft>
                  <InnerTitle text="窓口の営業設定" size={16} />
                  <InnerSemiTitle text="営業時間" size={14} />
                  <Label text={`${stats.business_hours_start} ～ ${stats.business_hours_end}`} size={14} />
                  <InnerSemiTitle text="営業曜日" size={14} />
                  <Label text={getJpnDayOfWeek(stats.business_day_of_weeks)} size={14} />
                  <InnerSemiTitle text="待ち人数の上限" size={14} />
                  <Label text={stats.max_num_of_waiting_items.toString()} size={14} />
                </InfoCellLeft>
                {false && (
                  <InfoCell>
                    <InnerTitle text="点灯・点滅までの時間" size={16} />
                    <InnerSemiTitle text="点灯まで" size={14} />
                    <Label text={stats.lighting} size={14} />
                    <InnerSemiTitle text="点滅まで" size={14} />
                    <Label text={stats.blinking} size={14} />
                    <InnerSemiTitle text="激しい点灯まで" size={14} />
                    <Label text={stats.hard_blinking} size={14} />
                  </InfoCell>
                )}
                <InfoCellRight>
                  <InnerTitle text="窓口自動応答メッセージ" size={16} />
                  <InnerSemiTitle text="営業時間超過後" size={14} />
                  <Label text={stats.after_business_hours_message} size={14} />
                  <InnerSemiTitle text="待ち件数上限超過時" size={14} />
                  <Label text={stats.waiting_person_exceeded_message} size={14} />
                  <InnerSemiTitle text="全オペレーターの受付上限超過時" size={14} />
                  <Label text={stats.auto_response_message} size={14} />
                </InfoCellRight>
              </InfoRow>
            </InfoHead>
          </InfoTable>
        </Area>
      )}
    </Wrap>
  );
};

const getJpnDayOfWeek = (dow: string[]) => {
  const result: string[] = [];
  dow.forEach(d => {
    if (d === 'mon') result.push('月');
    if (d === 'tue') result.push('火');
    if (d === 'wed') result.push('水');
    if (d === 'thu') result.push('木');
    if (d === 'fri') result.push('金');
    if (d === 'sat') result.push('土');
    if (d === 'sun') result.push('日');
  });

  return result.join('・');
};

interface IconArrowInterface {
  size: number;
  isOpen: boolean;
}

const Header = styled.div`
  height: 45px;
`;

const Wrap = styled.div``;

const EditWrap = styled.div`
  float: right;
`;

const EditButton = styled(Button)`
  padding: 5px 10px 5px 10px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleIconArrow = styled(IconArrow)`
  width: ${(props: IconArrowInterface) => `${props.size}px`};
  transform: ${(props: IconArrowInterface) => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #000000;
  margin-right: 12px;
`;

const InfoTable = styled.table``;

const InfoHead = styled.thead``;

const InfoRow = styled.tr``;

const InfoCell = styled.td`
  padding: 20px;
  width: 22%;
  margin: 5px;
  border-right: 1px solid #e8e8e8;
`;
const InfoCellLeft = styled(InfoCell)`
  width: 22%;
`;
const InfoCellRight = styled(InfoCell)`
  width: 30%;
  border: none;
`;

const Area = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
  clear: both;
`;

const InnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  font-weight: bold;
  margin-bottom: 15px;
`;

const InnerSemiTitle = styled(Label)`
  margin-top: 10px;
  display: block;
  font-weight: bold;
`;

const RouteLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export default SkillSetting;
