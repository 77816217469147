import styled from 'styled-components';
import { ReactComponent as Forward } from './svg/forward.svg';

type Props = {
  size: number;
  onClick?: () => void;
};

const IconForward = styled(Forward)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  cursor: pointer;
`;

export default IconForward;
