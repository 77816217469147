import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET, SET_BIZ, CHECK, CHECK_ALL, CHECK_ALL_SKILLS, TOGGLE } from './constants';
import { Notification, Business } from './models';

export type NotificationState = {
  notifications: Notification[];
  businessTable: Business[];
};

const initialState: NotificationState = {
  notifications: [],
  businessTable: [],
};

const notifications = createReducer<Notification[], Action>(initialState.notifications).handleType(
  SET,
  (state, action: Action) => action.payload,
);
//.handleType(ADD, (state, action: Action) => action.payload);

const businessTable = createReducer<Business[], Action>(initialState.businessTable)
  .handleType(SET_BIZ, (state, action: Action) => action.payload)
  .handleType(CHECK, (state, action: Action) => {
    const business = state[action.payload.bizIndex];
    const skill = business.skills[action.payload.skillIndex];
    skill.isChecked = !skill.isChecked;

    if (!skill.isChecked) {
      business.isChecked = false;
    }
    return [...state];
  })
  .handleType(CHECK_ALL_SKILLS, (state, action: Action) => {
    const index = action.payload;
    const business = state[index];

    business.isChecked = !business.isChecked;
    const isCheckedAll = business.skills.every(skill => skill.isChecked);
    business.skills.forEach(skill => (skill.isChecked = !isCheckedAll));

    return [...state];
  })
  .handleType(CHECK_ALL, (state, action: Action) => {
    let skillsNum = 0;
    state.forEach(business => {
      skillsNum += business.skills.length;
    });

    let checkedSkillsNum = 0;
    state.forEach(business => {
      checkedSkillsNum += business.skills.filter(skill => skill.isChecked).length;
    });

    const check = skillsNum !== checkedSkillsNum;
    state.forEach(business => {
      business.isChecked = check;
      business.skills.forEach(skill => {
        skill.isChecked = check;
      });
    });

    return [...state];
  })
  .handleType(TOGGLE, (state, action: Action) => {
    const index = action.payload;
    state[index].isOpen = !state[index].isOpen;
    return [...state];
  });

export default combineReducers({ notifications, businessTable });
