import React from 'react';
import styled from 'styled-components';
import Label from '../atoms/Label';
import IconArrow from '../atoms/icons/IconArrow';
import { operatorModels } from '../../features/operator';
import Input from '../atoms/Input';
import Checkbox from '../atoms/Checkbox';

type Props = {
  business: operatorModels.Business;
  bizIdx: number;
  onBusinessToggle: (bizIdx: number) => void;
  onSkillCheckChange: (bizIdx: number, skillIdx: number) => void;
  onSkillPointChange: (value: string, bizIdx: number, skillIdx: number) => void;
  onMaxAcceptableItemsChange: (value: string, bizIdx: number, skillIdx: number) => void;
};

const validatedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  switch (true) {
    case '' === value:
      return '0';
    case /^\d+$/.test(value):
      return value;
    default:
      event.preventDefault();
      return;
  }
};

const OperatorSkillBizElem: React.FC<Props> = props => {
  const {
    bizIdx,
    business,
    onBusinessToggle,
    onSkillCheckChange,
    onSkillPointChange,
    onMaxAcceptableItemsChange,
  } = props;
  return (
    <div>
      <BizName>
        <SkillIconArrow size={14} onClick={() => onBusinessToggle(bizIdx)} isOpen={business.isOpen} />
        <SkillName text={business.name} />
        <SkillNumLabel text="登録窓口数" />
        <SkillNum text={`${business.skills.filter(skill => skill.isChecked).length}`} />
      </BizName>
      {business.isOpen && (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <Th width={'10px'} />
              <Th width={'40%'}>窓口名</Th>
              <Th width={'80px'}>スキルポイント</Th>
              <Th width={'80px'}>受可上限</Th>
              <Th width={'80px'}>登録日</Th>
            </tr>
          </thead>
          <tbody>
            {business.skills.map((skill, i) => (
              <tr key={i}>
                <Td>
                  <Checkbox
                    onClick={() => {
                      onSkillCheckChange(bizIdx, i);
                    }}
                    checked={skill.isChecked}
                  />
                </Td>
                <Td width={'40%'}>{skill.name}</Td>
                <Td width={'80px'}>
                  <StyledInput
                    type="tel"
                    theme="gray"
                    name="skill_point"
                    value={`${skill.skillPoints}`}
                    onChange={e => {
                      const value = validatedValue(e);
                      if (value) onSkillPointChange(value, bizIdx, i);
                    }}
                  />
                </Td>
                <Td width={'80px'}>
                  <StyledInput
                    type="tel"
                    theme="gray"
                    name="accept_max_limit"
                    value={`${skill.maxAcceptableItems}`}
                    onChange={e => {
                      const value = validatedValue(e);
                      console.log(value);
                      if (value) onMaxAcceptableItemsChange(value, bizIdx, i);
                    }}
                  />
                </Td>
                <Td width={'80px'}>{skill.createdAt}</Td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const BizName = styled.div`
  padding: 10px;
  width: 400px;
`;

type IconArrowProps = {
  size: number;
  isOpen: boolean;
};

const SkillIconArrow = styled(IconArrow)<IconArrowProps>`
  width: ${props => `${props.size}px`};
  transform: ${props => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #707070;
  margin-right: 12px;
`;

const SkillName = styled(Label)`
  font-weight: bold;
`;

const SkillNumLabel = styled(Label)`
  margin-left: 30px;
`;

const SkillNum = styled(Label)`
  margin-left: 10px;
`;

type WidthProps = {
  width?: string;
};

const Th = styled.th<WidthProps>`
  background-color: #f5f5f5;
  text-align: left;
  padding: 10px;
  width: ${props => props.width || 'auto'};
`;

const Td = styled.td<WidthProps>`
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  width: ${props => props.width || 'auto'};
`;

const StyledInput = styled(Input)`
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-left: 10px;
  height: 40px;
  width: 90%;
  ime-mode: disabled;
`;

export default OperatorSkillBizElem;
