import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import {
  SET,
  CHECK,
  CHECK_ALL,
  SET_DETAIL,
  SET_BIZ,
  CHANGE_CHAT_FLAG,
  CHANGE_ITEMS,
  CHANGE_POINTS,
  CHECK_SKILL,
  TOGGLE_BIZ,
  SET_SKILL_NUM,
} from './constants';
import { Operator, Business, OperatorDetail } from './models';

export type OperatorState = {
  operators: Operator[];
  businesses: Business[];
  operator: OperatorDetail;
};

const initialState: OperatorState = {
  operators: [],
  businesses: [],
  operator: {
    name: '',
    type: '',
    userId: 0,
    businesses: [],
    chatUsable: true,
    fontSize: 0,
    operatorCode: '',
    status: '',
    skillNum: 0,
  },
};

const operators = createReducer<Operator[], Action>(initialState.operators)
  .handleType(SET, (state, action: Action) => action.payload)
  .handleType(CHECK, (state, action: Action) => {
    const index = action.payload;
    state[index].isChecked = !state[index].isChecked;
    return [...state];
  })
  .handleType(CHECK_ALL, (state, action: Action) => {
    const isCheckedAll = state.every(operator => operator.isChecked);
    return state.map(operator => ({ ...operator, isChecked: !isCheckedAll }));
  });

const businesses = createReducer<Business[], Action>(initialState.businesses).handleType(
  SET_BIZ,
  (state, action: Action) => action.payload,
);

const operatorDetail = createReducer<OperatorDetail, Action>(initialState.operator)
  .handleType(SET_DETAIL, (state, action: Action) => action.payload)
  .handleType(CHANGE_CHAT_FLAG, (state, action: Action) => {
    state.chatUsable = action.payload;
    console.log(state);
    return state;
  })
  .handleType(SET_SKILL_NUM, (state, action: Action) => {
    state.skillNum = action.payload;
    return state;
  })
  .handleType(TOGGLE_BIZ, (state, action: Action) => {
    const bizIndex = action.payload;
    state.businesses[bizIndex].isOpen = !state.businesses[bizIndex].isOpen;
    return state;
  })
  .handleType(CHECK_SKILL, (state, action: Action) => {
    const bizIndex = action.payload.bizIndex;
    const skillIndex = action.payload.skillIndex;
    const skill = state.businesses[bizIndex].skills[skillIndex];

    let skillNum = 0;
    state.businesses.forEach(business => {
      skillNum += business.skills.filter(skill => skill.isChecked).length;
    });

    skill.isChecked = !skill.isChecked;
    state.skillNum = skillNum;
    return state;
  })
  .handleType(CHANGE_ITEMS, (state, action: Action) => {
    const bizIndex = action.payload.bizIndex;
    const skillIndex = action.payload.skillIndex;
    const value = action.payload.value;

    state.businesses[bizIndex].skills[skillIndex].skillPoints = value;
    return state;
  })
  .handleType(CHANGE_POINTS, (state, action: Action) => {
    const bizIndex = action.payload.bizIndex;
    const skillIndex = action.payload.skillIndex;
    const value = action.payload.value;

    state.businesses[bizIndex].skills[skillIndex].maxAcceptableItems = value;
    return state;
  });

export default combineReducers({ operators, businesses, operatorDetail });
