import { createAction } from 'typesafe-actions';
//import { SET, ADD, EDIT, DELETE, CHECK, CHECK_ALL } from './constants';
import { SET } from './constants';
import { Flag, FlagJson } from './models';

export const set = createAction(SET, (json: FlagJson[]) =>
  json.map(flag => {
    const attributes = flag.attributes;

    return {
      isChecked: false,
      isEditing: false,
      isDeleting: false,
      flagId: attributes.flag_id,
      name: attributes.name,
      visible: attributes.visible,
      createdAt: attributes.created_at,
      updatedAt: attributes.updated_at,
    } as Flag;
  }),
)<Flag[]>();

//export const add = createAction(CHECK, (index: number) => index)<number>();
//export const check = createAction(CHECK, (index: number) => index)<number>();
//export const checkAll = createAction(CHECK_ALL, () => null)<null>();
//export const editFlag = createAction(EDIT, (index: number) => index)<number>();
//export const deleteFlag = createAction(DELETE, (index: number) => index)<number>();
