import axios from './httpClient';

type EditCategoryParam = {
  skill_id: number;
  category_id: number;
  name: string;
};

type EditSubCategoryParam = {
  category_id: number;
  subcategory_id: number;
  name: string;
};

type EditSubSubCategoryParam = {
  subcategory_id: number;
  subsubcategory_id: number;
  name: string;
};

export const Categories = {
  getAll: (skillId: number) =>
    axios.get('categories', { params: { skill_id: skillId } }).catch(error => error.response),
  putCategory: (argParams: EditCategoryParam) => axios.put('categories', argParams, {}).catch(error => error.response),
  putSubCategory: (argParams: EditSubCategoryParam) =>
    axios.put('subcategories', argParams, {}).catch(error => error.response),
  putSubSubCategory: (argParams: EditSubSubCategoryParam) =>
    axios.put('subsubcategories', argParams, {}).catch(error => error.response),
  deleteCategory: (categoryId: number) => axios.delete(`categories/${categoryId}`, {}).catch(error => error.response),
  deleteSubCategory: (subcategoryId: number) =>
    axios.delete(`subcategories/${subcategoryId}`, {}).catch(error => error.response),
  deleteSubSubCategory: (subsubcategoryId: number) =>
    axios.delete(`subsubcategories/${subsubcategoryId}`, {}).catch(error => error.response),
  addCategory: (skillId: number, name: string) =>
    axios.post('categories', { skill_id: skillId, name: name }).catch(error => error.response),
  addSubCategory: (categoryId: number, name: string) =>
    axios.post('subcategories', { category_id: categoryId, name: name }).catch(error => error.response),
  addSubSubCategory: (subCategoryId: number, name: string) =>
    axios.post('subsubcategories', { subcategory_id: subCategoryId, name: name }).catch(error => error.response),
};
