import React from 'react';
import { createGlobalStyle } from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
import SelectBox from '../atoms/SelectBox';
import { OptionInterface } from '../../utils/interface';
import styled from 'styled-components';

type Props = {
  from?: Date;
  to?: Date;
  fromHours?: string;
  toHours?: string;
  options?: OptionInterface[];
  onDayChange?: (from?: Date, to?: Date) => void;
  onHoursChange?: (fromHours?: string, toHours?: string) => void;
};

type State = Props & {};

const modifiersStyles = {
  sundays: {
    color: '#fa3f4b',
  },
  saturdays: {
    color: '#7188fa',
  },
};

const defaultDayPickerProps = {
  localeUtils: MomentLocaleUtils,
  locale: 'ja',
  numberOfMonths: 2,
  modifiersStyles,
};

class ReportDownloadDateRangePickerWrapper extends React.Component<Props, State> {
  static readonly defaultProps = {
    from: new Date(),
    to: new Date(),
    fromHours: '00',
    toHours: '00',
  };

  readonly state: State = {
    from: undefined,
    to: undefined,
    fromHours: undefined,
    toHours: undefined,
    options: [],
  };

  to: DayPickerInput | null = null;

  constructor(props: Props) {
    super(props);

    const { from, to, fromHours, toHours, options } = this.props;
    this.state = { ...this.state, from, to, fromHours, toHours, options };
  }

  handleFromChange = (from: Date) => {
    this.setState({ from }, this.handleOnDayChange);
  };

  handleToChange = (to: Date) => {
    this.setState({ to }, this.handleOnDayChange);
  };

  handleFromHoursChange = (name: string, option?: OptionInterface) => {
    this.setState({ fromHours: String(option.value) }, this.handleOnHoursChange);
  };

  handleToHoursChange = (name: string, option?: OptionInterface) => {
    this.setState({ toHours: String(option.value) }, this.handleOnHoursChange);
  };

  handleOnDayChange = () => {
    const { from, to } = this.state;
    const { onDayChange } = this.props;
    if (onDayChange) onDayChange(from, to);
  };

  handleOnHoursChange = () => {
    const { fromHours, toHours } = this.state;
    const { onHoursChange } = this.props;
    if (onHoursChange) onHoursChange(fromHours, toHours);
  };

  render() {
    const { from, to, fromHours, toHours, options } = this.state;
    const dayPickerInputProps = {
      format: 'L',
      formatDate: MomentLocaleUtils.formatDate,
      parseDate: MomentLocaleUtils.parseDate,
    };
    const modifiers = {
      start: from,
      end: to,
      sundays: { daysOfWeek: [0] },
      saturdays: { daysOfWeek: [6] },
    };
    const dayPickerProps = {
      ...defaultDayPickerProps,
      selectedDays: [from, { from, to }],
      modifiers,
    };

    return (
      <div>
        <DatePickerInputStyle />
        <span className={'InputFromTo-from'}>
          <DayPickerInput
            value={from}
            {...dayPickerInputProps}
            dayPickerProps={{
              ...dayPickerProps,
              toMonth: to,
              onDayClick: () => {
                if (this.to) {
                  this.to.getInput().focus();
                }
              },
            }}
            onDayChange={this.handleFromChange}
          />
        </span>
        <HoursSelect
          name="from_hours"
          theme="gray"
          options={options}
          selectedOption={{
            key: 'hour_sel',
            label: fromHours,
            value: fromHours,
          }}
          onChanged={this.handleFromHoursChange}
        />
        {' 時 '}
        {' 〜 '}
        <span className={'InputFromTo-to'}>
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            {...dayPickerInputProps}
            dayPickerProps={{
              ...dayPickerProps,
              disabledDays: { before: from },
              month: from,
              fromMonth: from,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
        <HoursSelect
          name="to_hours"
          theme="gray"
          options={options}
          selectedOption={{
            key: 'hour_sel',
            label: toHours,
            value: toHours,
          }}
          onChanged={this.handleToHoursChange}
        />
        {' 時 '}
      </div>
    );
  }
}

const HoursSelect = styled(SelectBox)`
  margin-left: 10px;
`;

const DatePickerInputStyle = createGlobalStyle`
  .DayPickerInput-OverlayWrapper {
    border-radius: 50%;
  }
    
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  
  .DayPickerInput-Overlay {
     margin-left: -198px;
  }
  
  .DayPickerInput {
    > input {
      color: #000000;
      font-size: 1.4rem;
      outline: none;
      box-sizing: border-box;
      background-color: #fafafa;
      border: 1px solid #d0d0d0;
      border-radius: 5px 5px 5px 5px;
      padding: 10px 5px;
      
      &:disabled {
        background-color: #ffffff;
        opacity: 1;
      }
      
      &::placeholder {
        color: #7c8a98;
      }
    }
  }
`;

export default ReportDownloadDateRangePickerWrapper;
