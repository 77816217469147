import axios from './httpClient';
import { operatorModels } from '../../features/operator';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
  skill_ids?: number[];
  filter?: {
    'name[like]': string;
  };
};

export const Operators = {
  getAll: (option: Option = { all: false }) =>
    axios.get('operators', { params: option }).catch(error => error.response),

  get: (operatorId: string) => axios.get(`operators/${operatorId}`).catch(error => error.response),

  putSettings: (argParams: operatorModels.PutSettingsParam) =>
    axios.put(`operators/settings`, argParams, {}).catch(error => error.response),

  putSkills: (argParams: operatorModels.PutSkillsParam) =>
    axios.put('operators/skills', argParams, {}).catch(error => error.response),
};
