import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../Theme';
import { ReactComponent as Check } from './svg/check.svg';

type Props = {
  size: number;
  checked?: boolean;
  fill?: string;
  index?: number;
  onColorToggle?: (index: number) => void;
};

const IconCheck: React.FC<Props> = props => {
  const { onColorToggle, index } = props;
  return (
    <StyledCheck
      {...props}
      onClick={() => {
        if (onColorToggle) onColorToggle(index);
      }}
    />
  );
};

const StyledCheck = styled(Check)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size}px`};
  fill: ${(props: Props) => `${props.fill}`};

  ${(props: Props) => fillColor(props.fill)};
  ${(props: Props) => strokeColor(props.checked)};
`;

const fillColor = (color: string | undefined) => {
  if (color !== undefined && color != null && color.length > 0) {
    return `fill: ${color}`;
  } else {
    return `fill: ${theme.primaryColor}`;
  }
};

const strokeColor = (isActive: boolean | undefined) => {
  if (isActive) {
    return `
      stroke: #ffffff;
    `;
  } else {
    return ``;
  }
};

export default IconCheck;
