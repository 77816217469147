import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { AnswerHistory } from './models';

export type AnswerHistoryState = {
  answerHistories: AnswerHistory[];
};

const initialState: AnswerHistoryState = {
  answerHistories: [],
};

const answerHistories = createReducer<AnswerHistory[], Action>(initialState.answerHistories).handleType(
  SET,
  (state, action: Action) => action.payload,
);
export default combineReducers({ answerHistories });
