import * as React from 'react';
import styled from 'styled-components';
import Base from './Base';
import Label from '../atoms/Label';
import Button from '../atoms/Button';
import IconClose from '../atoms/icons/IconClose';
import { skillModels } from '../../features/skill';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PopupFrame from './PopupFrame';
import arrayMove from 'array-move';

interface Props {
  isPopup: boolean;
  skillCards: skillModels.SkillDetailJSON[];
  onPopupClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSave: (skillCards: skillModels.SkillDetailJSON[]) => void;
}

type State = {
  skillCards: skillModels.SkillDetailJSON[];
};

const SortableItem = SortableElement(({ value }) => {
  return (
    <SortSkillCard color={value.overview.color === undefined ? '#ffffff' : value.overview.color}>
      <BusinessNameArea>
        <Label text={value.overview.business_name} size={14} />
      </BusinessNameArea>
      <SkillNameArea>
        <Label text={value.overview.name} size={16} />
      </SkillNameArea>
    </SortSkillCard>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} value={item} />
      ))}
    </ul>
  );
});

class SkillsSort extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
  }

  readonly state: State = {
    skillCards: [],
  };

  componentDidMount() {
    this.setState({ skillCards: this.props.skillCards });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.skillCards !== this.props.skillCards) {
      this.setState({ skillCards: this.props.skillCards });
    }
  }

  onSortEnd({ oldIndex, newIndex }): void {
    this.setState(({ skillCards }) => ({
      skillCards: arrayMove(skillCards, oldIndex, newIndex),
    }));
  }

  onPopupClose(e: React.MouseEvent<HTMLButtonElement>): void {
    this.props.onPopupClose(e);
  }

  render() {
    const { isPopup } = this.props;
    if (!isPopup) {
      return <div />;
    }
    return (
      <Base>
        <PopupFrame isPopup={isPopup}>
          <PopupTitile>
            <PopupTitleLabel size={16} text="窓口の並び順変更" />
            <PopupCloseArea>
              <IconClose onClick={this.onPopupClose} />
            </PopupCloseArea>
          </PopupTitile>
          <PopupContents>
            <SortableList items={this.state.skillCards} onSortEnd={this.onSortEnd} />
          </PopupContents>
          <PopupFooter>
            <PopupSaveButton
              type="button"
              label="保存"
              kind="primary"
              onClick={e => this.props.onClickSave(this.state.skillCards)}
            />
            <PopupCancelButton type="button" label="キャンセル" onClick={this.onPopupClose} />
          </PopupFooter>
        </PopupFrame>
      </Base>
    );
  }
}

interface CardInterface {
  color: string;
}

const PopupFooter = styled.div`
  background-color: #ffffff;
  padding: 0px 30px 0px 0px;
  text-align: right;
  border-top: 1px solid #fafafa;
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 99;
`;

const PopupTitile = styled.div`
  height: 45px;
  padding: 10px 0px 40px 10px;
  margin: 10px 0px 10px 0px;
  border-bottom: 1px solid #e8e8e8;
  z-index: 99;
`;

const PopupContents = styled.div`
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #e8e8e8;
  margin: 10px;
  z-index: 99;
`;

const SortSkillCard = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  border-left: 24px solid ${(props: CardInterface) => `${props.color}`};
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  height: 100px;
  padding: 10px;
  margin: 20px;
  z-index: 99;
`;

const BusinessNameArea = styled.div`
  padding-bottom: 5px;
  z-index: 99;
`;

const SkillNameArea = styled.div`
  font-weight: bold;
  z-index: 99;
`;

const PopupTitleLabel = styled(Label)`
  font-weight: bold;
  color: #338cd8;
  padding: 10px;
  z-index: 99;
`;

const PopupCloseArea = styled.div`
  float: right;
  padding-top: 5px;
  margin-right: 20px;
  z-index: 99;
`;

const PopupSaveButton = styled(Button)`
  margin-left: 20px;
  width: 150px;
  z-index: 99;
`;

const PopupCancelButton = styled(Button)`
  margin-left: 10px;
  width: 150px;
  z-index: 99;
`;

export default SkillsSort;
