import React from 'react';
import styled from 'styled-components';
import { theme } from '../../Theme';

type ButtonProps = {
  kind?: string | undefined;
  width?: string | number;
  height?: string | number;
  value?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

type Props = ButtonProps & {
  type: 'button' | 'submit' | 'reset';
  label: string | React.ReactNode;
  kind?: string | undefined;
  value?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const { label, type, children, ...rest } = props;
  return (
    <StyledButton ref={ref} {...rest}>
      {label}
    </StyledButton>
  );
});

const kindStyle = (kind: string | undefined) => {
  if (kind === 'primary') {
    return `
      color: #ffffff;
      background-color: ${theme.primaryColor};
      border: 1px solid #1674cb;
    `;
  }
  return `
    background-color: #f4f4f4;
    border: 1px solid #e8e8e8;
  `;
};

const size = (value?: string | number) => {
  if (value) {
    switch (typeof value) {
      case 'number':
        return `${value}px`;
      case 'string':
        return value;
      default:
        return 'auto';
    }
  } else {
    return 'auto';
  }
};

const StyledButton = styled.button`
  display: inline-block;
  width: ${(props: ButtonProps) => size(props.width)};
  height: ${(props: ButtonProps) => size(props.height)};
  padding: 12px 10px 10px;
  border-radius: 4px;
  text-align: center;
  font-weight: normal;
  transition: opacity 0.15s ease-out;
  opacity: ${(props: ButtonProps) => (props.disabled ? 0.4 : 1)};
  cursor: ${(props: ButtonProps) => (props.disabled ? 'default' : 'pointer')};
  ${(props: ButtonProps) => kindStyle(props.kind)};
`;

export default Button;
