import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET, CHECK, EDIT, DELETE } from './constants';
import { AnswerSetting } from './models';

export type AnswerSettingState = {
  AnswerSettings: AnswerSetting[];
};

const initialState: AnswerSettingState = {
  AnswerSettings: [],
};

const answerSettings = createReducer<AnswerSetting[], Action>(initialState.AnswerSettings)
  .handleType(SET, (state, action: Action) => action.payload)
  .handleType(CHECK, (state, action: Action) => {
    const index = action.payload;
    state[index].isChecked = !state[index].isChecked;
    return [...state];
  })
  .handleType(EDIT, (state, action: Action) => action.payload)
  .handleType(DELETE, (state, action: Action) => action.payload);
export default combineReducers({ answerSettings });
