import * as React from 'react';
import ReactLoading from 'react-loading';

type Props = {
  children?: React.ReactNode;
  type?: 'blank' | 'balls' | 'bars' | 'bubbles' | 'cubes' | 'cylon' | 'spin' | 'spinningBubbles' | 'spokes';
  color?: string;
  width?: string | number;
  height?: string | number;
};

const Loading: React.FC<Props> = props => {
  const { children, ...rest } = props;
  return (
    <div>
      <ReactLoading {...rest} />
    </div>
  );
};

export default Loading;
