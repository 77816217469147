import * as React from 'react';
import styled from 'styled-components';

type Props = {
  text?: string;
  size?: number;
  onClick?: () => void;
};

const Label: React.FC<Props> = props => {
  const { text } = props;
  return <Span {...props}>{text}</Span>;
};

const Span = styled.span`
  font-size: ${(props: Props) => (props.size === null ? 14 : props.size)}px;
  color: #000000;
`;

export default Label;
