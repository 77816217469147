import styled from 'styled-components';
import { ReactComponent as Arrow } from './svg/arrow.svg';

type Props = {
  size: number;
  onClick?: () => void;
};

const IconArrow = styled(Arrow)`
  width: ${(props: Props) => `${props.size}px`};
  height: ${(props: Props) => `${props.size * 1.18}px`};
  cursor: pointer;
`;

export default IconArrow;
