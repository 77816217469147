import React from 'react';
import styled from 'styled-components';

interface Props {
  role: string;
  name: string;
}

const CurrentUser: React.FC<Props> = props => {
  const { role, name } = props;
  return (
    <Wrapper>
      <Label>ログインユーザー</Label>
      <UserInfo>
        <UserRole>{role}</UserRole>
        <UserName>{name}</UserName>
      </UserInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
  padding: 0 20px;
`;

const Label = styled.p`
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
`;

const UserInfo = styled.div`
  margin-top: 6px;
`;

const UserRole = styled.p`
  color: #ffffff;
  font-size: 1.2rem;
`;

const UserName = styled.h3`
  margin-top: -2px;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: normal;
`;

export default CurrentUser;
