import axios from './httpClient';

type AnswerHistoryParam = {
  answer_setting_id: number;
  user_id: number;
  operation: string;
  update_content: string;
};

export const AnswerHistory = {
  getAll: () => axios.get('answer_settings/history').catch(error => error.response),
  add: (argParams: AnswerHistoryParam) =>
    axios.post('answer_settings/history', argParams).catch(error => error.response),
};
