import React from 'react';
import styled from 'styled-components';
import Label from '../atoms/Label';
import IconEdit from '../atoms/icons/IconEdit';
import IconDelete from '../atoms/icons/IconDelete';
import IconArrow from '../atoms/icons/IconArrow';
import IconCheck2 from '../atoms/icons/IconCheck2';
import SelectBox from '../atoms/SelectBox';
import { periodModels } from '../../features/period';
import moment from 'moment';
import DateRangePicker from '../molecules/DateRangePicker';

interface Props {
  stats: periodModels.Period[];
  isEditing: boolean;
  isOpen: boolean;
  onClick: (eventName: string, index: number) => void;
  onChange: (index: number, name: string, value: string) => void;
  onDayChange: (index: number, from: Date, to: Date) => void;
}

const getHour = (hour: string): string => {
  const arr = hour.split(':');
  return `${arr[0]}時${arr[1]}分`;
};

const SkillPeriods: React.FC<Props> = props => {
  const stats = {
    PeriodStart: [],
    PeriodEnd: [],
    HourStart: [],
    HourEnd: [],
    MinuteStart: [],
    MinuteEnd: [],
    DayOfWeek: [
      { label: '月', index: 0, value: 'mon' },
      { label: '火', index: 1, value: 'tue' },
      { label: '水', index: 2, value: 'wed' },
      { label: '木', index: 3, value: 'thu' },
      { label: '金', index: 4, value: 'fri' },
      { label: '土', index: 5, value: 'sat' },
      { label: '日', index: 6, value: 'sun' },
    ],
  };

  const currentDate = moment().add(-1, 'd');
  for (let i = 0; i < 1000; i++) {
    const date = currentDate.add(1, 'd').format('YYYY/MM/DD');
    stats.PeriodStart.push({ label: date, value: date, key: `PeriodStart${i}` });
    stats.PeriodEnd.push({ label: date, value: date, key: `PeriodStart${i}` });
  }

  for (let i = 0; i < 24; i++) {
    stats.HourStart.push({ label: ('00' + i).slice(-2), value: ('00' + i).slice(-2), key: `HourStart${i}` });
    stats.HourEnd.push({ label: ('00' + i).slice(-2), value: ('00' + i).slice(-2), key: `HourEnd${i}` });
  }

  for (let i = 0; i < 60; i++) {
    stats.MinuteStart.push({ label: ('00' + i).slice(-2), value: ('00' + i).slice(-2), key: `MinuteStart${i}` });
    stats.MinuteEnd.push({ label: ('00' + i).slice(-2), value: ('00' + i).slice(-2), key: `MinuteEnd${i}` });
  }

  const isAddAreaShow = props.stats.length < 4 && !props.isEditing;
  // const { stats } = props;
  if (props.stats == null) return <Wrap />;
  return (
    <Wrap>
      <Header>
        <StyleIconArrow size={16} isOpen={props.isOpen} onClick={() => props.onClick('toggle', -1)} />
        <TitleLabel text="特定期間での営業時間" />
      </Header>
      {!props.isOpen ? (
        <div />
      ) : (
        <Area>
          {props.stats.map((period, i) =>
            period.periodId !== 0 && !period.isEditing ? (
              <PeriodArea key={`PeriodArea${i}`}>
                <Contents>
                  <WrapTitle>
                    <SubWrapTitle>
                      <OuterTitle text="特定期間での営業時間" size={16} />
                    </SubWrapTitle>
                    <WrapAction>
                      {period.isEditing ? (
                        <Commit size={14} fill={'#338ed8'} onClick={() => props.onClick('commit', i)} />
                      ) : (
                        <Edit size={14} disabled={props.isEditing} onClick={() => props.onClick('edit', i)} />
                      )}
                      <Delete
                        size={14}
                        disabled={props.isEditing && !period.isEditing}
                        onClick={() => props.onClick('delete', i)}
                      />
                    </WrapAction>
                  </WrapTitle>
                  <Among>
                    <InnerTitle text="期間指定" size={14} />
                    <Label text={`${period.periodStart} ～ ${period.periodEnd}`} />
                  </Among>
                  <Hour>
                    <InnerTitle text="時間" size={14} />
                    <Label text={`${getHour(period.businessHoursStart)} ～ ${getHour(period.businessHoursEnd)}`} />
                  </Hour>
                </Contents>
              </PeriodArea>
            ) : (
              <PeriodArea key={`PeriodArea${i}`}>
                <Contents>
                  <WrapTitle>
                    <SubWrapTitle>
                      <OuterTitle text="特定期間での営業時間" size={16} />
                    </SubWrapTitle>
                    <WrapAction>
                      {period.isEditing ? (
                        <Commit size={14} fill={'#338ed8'} onClick={() => props.onClick('commit', i)} />
                      ) : (
                        <Edit size={14} disabled={props.isEditing} onClick={() => props.onClick('edit', i)} />
                      )}
                      <Delete size={14} onClick={() => props.onClick('delete', i)} />
                    </WrapAction>
                  </WrapTitle>
                  <Among>
                    <InnerTitle text="期間指定" size={14} />
                    <DateRangePicker
                      from={moment(period.periodStart).toDate()}
                      to={moment(period.periodEnd).toDate()}
                      onDayChange={(from, to) => props.onDayChange(i, from, to)}
                    />
                  </Among>
                  <Hour>
                    <InnerTitle text="時間" size={14} />
                    <HourSelectBox
                      name={'hourstart'}
                      options={stats.HourStart}
                      theme="gray"
                      selectedOption={
                        stats.HourStart.filter(option => option.value === period.businessHoursStart.split(':')[0])[0]
                      }
                      onChanged={(name, value) => props.onChange(i, name, `${value.value}`)}
                    />
                    <Label text={'時'} />
                    <MinuteSelectBox
                      name={'minutestart'}
                      options={stats.MinuteStart}
                      theme="gray"
                      selectedOption={
                        stats.MinuteStart.filter(option => option.value === period.businessHoursStart.split(':')[1])[0]
                      }
                      onChanged={(name, value) => props.onChange(i, name, `${value.value}`)}
                    />
                    <Label text={'分'} />
                    <FromToLabel text={`～`} />
                    <HourSelectBox
                      name={'hourend'}
                      options={stats.HourEnd}
                      theme="gray"
                      selectedOption={
                        stats.HourEnd.filter(option => option.value === period.businessHoursEnd.split(':')[0])[0]
                      }
                      onChanged={(name, value) => props.onChange(i, name, `${value.value}`)}
                    />
                    <Label text={'時'} />
                    <MinuteSelectBox
                      name={'minuteend'}
                      options={stats.MinuteEnd}
                      theme="gray"
                      selectedOption={
                        stats.MinuteEnd.filter(option => option.value === period.businessHoursEnd.split(':')[1])[0]
                      }
                      onChanged={(name, value) => props.onChange(i, name, `${value.value}`)}
                    />
                    <Label text={'分'} />
                  </Hour>
                </Contents>
              </PeriodArea>
            ),
          )}
          {isAddAreaShow ? (
            <PeriodAddArea key="PeriodAddArea">
              <AddTitle text="＋営業時間を追加" size={16} onClick={() => props.onClick('add', props.stats.length)} />
            </PeriodAddArea>
          ) : (
            <div />
          )}
        </Area>
      )}
    </Wrap>
  );
};

interface IconArrowInterface {
  size: number;
  isOpen: boolean;
}

const Header = styled.div`
  height: 45px;
`;

const Wrap = styled.div``;

const Contents = styled.div`
  padding: 10px;
`;

const WrapTitle = styled.div``;

const SubWrapTitle = styled.div`
  float: left;
`;

const Among = styled.div`
  margin-top: 40px;
  margin-bottom: 5px;
  height: 80px;
  clear: both;
`;

const Hour = styled.div`
  border-top: solid 1px #f5f5f5;
  margin-bottom: 5px;
  height: 80px;
`;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const StyleIconArrow = styled(IconArrow)`
  width: ${(props: IconArrowInterface) => `${props.size}px`};
  transform: ${(props: IconArrowInterface) => (props.isOpen ? 'none' : 'rotate(-90deg)')};
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  stroke: #000000;
  margin-right: 12px;
`;

const Area = styled.div`
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
  height: auto;
  overflow: auto;
`;

const PeriodArea = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  margin: 15px;
  width: 46%;
  float: left;
  height: 210px;
`;

const PeriodAddArea = styled.div`
  border-radius: 5px;
  background-color: #fafafa;
  border: 1px dashed #e8e8e8;
  margin: 15px;
  width: 46%;
  text-align: center;
  padding-top 140px;
  float: left;
  height: 310px;
`;

const OuterTitle = styled(Label)`
  display: block;
  color: #338cd8;
  font-weight: bold;
  float- left;
`;

const InnerTitle = styled(Label)`
  display: block;
  color: #338cd8;
  font-weight: bold;
`;

const AddTitle = styled(Label)`
  display: block;
  color: #338cd8;
  cursor: pointer;
`;

const WrapAction = styled.div`
  float: right;
  width: 60px;
`;

const Commit = styled(IconCheck2)`
  padding: 6px;
`;

const Edit = styled(IconEdit)`
  padding: 6px;
`;

const Delete = styled(IconDelete)`
  margin-left: 3px;
  padding: 6px 0px 6px 6px;
`;

const FromToLabel = styled(Label)`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
`;

const HourSelectBox = styled(SelectBox)`
  width: 70px;
  margin: 5px;
`;

const MinuteSelectBox = styled(SelectBox)`
  width: 70px;
  margin: 5px;
`;

export default SkillPeriods;
