import axios from './httpClient';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
};

type AddFlagParam = {
  name: string;
};

type EditFlagParam = {
  flag_id: number;
  name: string;
};

type DeleteFlagParam = {
  flag_id: number;
};

export const Flags = {
  getAll: (option: Option = { all: true }) => axios.get('flags', { params: option }).catch(error => error.response),
  add: (argParams: AddFlagParam) => axios.post('flags', { name: argParams.name }).catch(error => error.response),
  edit: (argParams: EditFlagParam) => axios.put('flags', argParams, {}).catch(error => error.response),
  delete: (argParams: DeleteFlagParam) => axios.delete(`flags/${argParams.flag_id}`, {}).catch(error => error.response),
};
