import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { QaHistory } from './models';

export type QaHistoryState = {
  qaHistories: QaHistory[];
};

const initialState: QaHistoryState = {
  qaHistories: [],
};

const qaHistories = createReducer<QaHistory[], Action>(initialState.qaHistories).handleType(
  SET,
  (state, action: Action) => action.payload,
);
export default combineReducers({ qaHistories });
