import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET, UPDATE } from './constants';
import * as actions from './actions';

export type AuthAction = ActionType<typeof actions>;

export type AuthState = Readonly<{
  token: string;
}>;

const initialState: AuthState = {
  token: '',
};

const token = createReducer<string, Action>(initialState.token).handleType([SET, UPDATE], (state, action: Action) => {
  const { payload } = action;
  if (payload && payload.length > 1) {
    return payload;
  }
  return state;
});

export default combineReducers({ token });
