import { createAction } from 'typesafe-actions';
import {
  SET,
  CHECK,
  CHECK_ALL,
  SET_DETAIL,
  SET_BIZ,
  CHANGE_CHAT_FLAG,
  CHANGE_ITEMS,
  CHANGE_POINTS,
  CHECK_SKILL,
  TOGGLE_BIZ,
  SET_SKILL_NUM,
} from './constants';
import { Operator, OperatorJSON, OperatorDetail, OperatorDetailJSON, Business, Skill } from './models';
import moment from 'moment';

export const set = createAction(SET, (json: OperatorJSON[]) =>
  json.map(operator => {
    const attributes = operator.attributes;
    let skill = '';
    let otherSkills = 0;
    if (attributes.skills.length > 0) {
      skill = attributes.skills[0].name;
      otherSkills = attributes.skills.length - 1;
    }

    return {
      isChecked: false,
      userId: attributes.user_id,
      name: attributes.name,
      first_name: attributes.first_name,
      type: attributes.type,
      role: attributes.role,
      hireDate: attributes.hire_date === undefined ? '' : moment(attributes.hire_date).format('YYYY/MM/DD'),
      skillName: skill,
      otherSkills,
      createdAt: attributes.created_at === undefined ? '' : moment(attributes.created_at).format('YYYY/MM/DD'),
    } as Operator;
  }),
)<Operator[]>();

export const setDetail = createAction(SET_DETAIL, (json: OperatorDetailJSON) => {
  const attributes = json.attributes;

  const businesses: Business[] = [];
  let skillNum = 0;
  for (const businessElem of attributes.businesses) {
    const skills: Skill[] = [];
    for (const skillElem of businessElem.skills) {
      const skill: Skill = {
        businessId: businessElem.business_id,
        skillId: skillElem.skill_id,
        name: skillElem.name,
        skillPoints: skillElem.skill_points,
        maxAcceptableItems: skillElem.max_acceptable_items,
        createdAt: skillElem.created_at === undefined ? '' : moment(skillElem.created_at).format('YYYY/MM/DD'),
        isChecked: false,
        order: skillElem.order,
      };
      skills.push(skill);
    }

    const business: Business = {
      businessId: businessElem.business_id,
      name: businessElem.name,
      skills: skills,
      isOpen: true,
    };

    businesses.push(business);
    skillNum += skills.length;
  }

  return {
    userId: attributes.user_id,
    operatorCode: attributes.operator_code,
    status: attributes.status,
    name: attributes.name,
    type: attributes.type,
    skillNum: skillNum,
    chatUsable: attributes.chat_usable,
    fontSize: attributes.font_size,
    businesses: businesses,
  } as OperatorDetail;
})<OperatorDetail>();

export const setBiz = createAction(SET_BIZ, (businessIds: { businessId: number; name: string }[], skills: Skill[]) => {
  const copyBusinesses: Business[] = [];
  businessIds.forEach(business => {
    const operatorSkills: Skill[] = [];
    skills.forEach((skill, i) => {
      if (skill.businessId === business.businessId) {
        const operatorSkill: Skill = {
          businessId: business.businessId,
          skillId: skill.skillId,
          name: skill.name,
          skillPoints: 0,
          maxAcceptableItems: 0,
          isChecked: false,
          createdAt: '',
          order: skill.order,
        };

        operatorSkills.push(operatorSkill);
      }
    });

    const tmpBusiness: Business = {
      businessId: business.businessId,
      name: business.name,
      skills: operatorSkills,
      isOpen: true,
    };

    copyBusinesses.push(tmpBusiness);
  });
  return copyBusinesses;
})<Business[]>();

export const check = createAction(CHECK, (index: number) => index)<number>();
export const checkAll = createAction(CHECK_ALL, () => null)<null>();
export const setChatFlag = createAction(CHANGE_CHAT_FLAG, (flag: boolean) => flag)<boolean>();
export const changeItems = createAction(CHANGE_ITEMS, (bizIndex: number, skillIndex: number, value: number) => ({
  bizIndex: bizIndex,
  skillIndex: skillIndex,
  value: value,
}))<{ bizIndex: number; skillIndex: number; value: number }>();
export const changePoints = createAction(CHANGE_POINTS, (bizIndex: number, skillIndex: number, value: number) => ({
  bizIndex: bizIndex,
  skillIndex: skillIndex,
  value: value,
}))<{ bizIndex: number; skillIndex: number; value: number }>();
export const checkSkill = createAction(CHECK_SKILL, (bizIndex: number, skillIndex: number) => ({
  bizIndex: bizIndex,
  skillIndex: skillIndex,
}))<{ bizIndex: number; skillIndex: number }>();
export const toggleBiz = createAction(TOGGLE_BIZ, (bizIndex: number) => ({ bizIndex: bizIndex }))<{
  bizIndex: number;
}>();
export const setSkillNum = createAction(SET_SKILL_NUM, (value: number) => value)<number>();
