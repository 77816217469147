import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { authReducer } from '../features/auth';
import { operatorReducer } from '../features/operator';
import { skillReducer } from '../features/skill';
import { userReducer } from '../features/user';
import { flagReducer } from '../features/flag';
import { categoryReducer } from 'features/category';
import { periodReducer } from 'features/period';
import { tagReducer } from 'features/tag';
import { businessReducer } from 'features/business';
import { notificationReducer } from 'features/notification';
import { blackListReducer } from 'features/blacklist';
import { qaHistoryReducer } from 'features/qa';
import { answerSettingReducer } from 'features/answer';
import { answerHistoryReducer } from 'features/answer/history';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    operator: operatorReducer,
    skill: skillReducer,
    user: userReducer,
    flag: flagReducer,
    category: categoryReducer,
    period: periodReducer,
    tag: tagReducer,
    business: businessReducer,
    notification: notificationReducer,
    blacklist: blackListReducer,
    qaHistory: qaHistoryReducer,
    answerSetting: answerSettingReducer,
    answerHistory: answerHistoryReducer,
  });

export default rootReducer;
