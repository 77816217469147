import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { Category, CategoryJSON } from './models';

export const set = createAction(SET, (json: CategoryJSON[]) => {
  type SubSubCategory = {
    isEditing: boolean;
    subSubCategoryId: number;
    name: string;
  };

  type SubCategory = {
    isEditing: boolean;
    subCategoryId: number;
    name: string;
    subSubCategories: SubSubCategory[];
  };

  type Category = {
    isOpen: boolean;
    isEditing: boolean;
    categoryId: number;
    name: string;
    subCategories: SubCategory[];
  };

  const categories: Category[] = [];
  for (const category of json) {
    const subCategoriesJson = category.attributes.subcategories;
    const subCategories: SubCategory[] = [];
    for (const subCategory of subCategoriesJson) {
      const subSubCategories: SubSubCategory[] = [];
      for (const subsubcategory of subCategory.subsubcategories) {
        subSubCategories.push({
          isEditing: false,
          subSubCategoryId: subsubcategory.subsubcategory_id,
          name: subsubcategory.name,
        });
      }

      subCategories.push({
        isEditing: false,
        subCategoryId: subCategory.subcategory_id,
        name: subCategory.name,
        subSubCategories: subSubCategories,
      });
    }

    categories.push({
      isOpen: false,
      isEditing: false,
      categoryId: category.attributes.category_id,
      name: category.attributes.name,
      subCategories: subCategories,
    });
  }
  return categories;
})<Category[]>();
