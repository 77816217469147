import React from 'react';
import styled from 'styled-components';
import { ShopUseLimitStats } from '../../utils/interface';
import ShopUseLimitRow from './ShopUseLimitRow';

interface Props {
  shopUseLimit: ShopUseLimitStats[];
  isWorkingJob: boolean;
  isDownload: boolean;
  downloadFileId: number;
  onClick?: (filename: string, fileId: number) => void;
  makeDownloadCsv?: (fileId: number) => void;
  downloadCsv?: (fileId: number) => void;
}

const ShopUseLimitCard: React.FC<Props> = props => {
  const { shopUseLimit, onClick, makeDownloadCsv, downloadCsv, isWorkingJob, isDownload, downloadFileId } = props;
  return (
    <Wrapper>
      <InfoTable>
        <InfoHead>
          <InfoCell>利用中</InfoCell>
          <InfoCell>CSVファイル名</InfoCell>
          <InfoCell>アップロード日</InfoCell>
          <InfoCell></InfoCell>
          <InfoCell></InfoCell>
        </InfoHead>
        {shopUseLimit.map(row => {
          if (row.attributes.visible) {
            return (
              <ShopUseLimitRow
                key={row.id}
                stats={row}
                onClick={onClick}
                makeDownloadCsv={makeDownloadCsv}
                downloadCsv={downloadCsv}
                isWorkingJob={isWorkingJob}
                isDownload={isDownload}
                downloadFileId={downloadFileId}
              />
            );
          } else {
            return false;
          }
        })}
      </InfoTable>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: none;
`;

const InfoTable = styled.div`
  display: table;
  width: 100%;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
`;

const InfoHead = styled(InfoRow)`
  background-color: #f5f5f5;
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

export default ShopUseLimitCard;
