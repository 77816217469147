import * as React from 'react';
import styled from 'styled-components';

type Props = {
  type?: string;
  theme?: 'gray';
  name?: string;
  value: string;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
};

export type Ref = HTMLInputElement;

const Input = React.forwardRef<Ref, Props>((props, ref) => (
  <InputBox type={props.type ? props.type : 'text'} ref={ref} {...props} />
));

const themeStyle = (theme?: string) => {
  if (theme === 'gray') {
    return `
      background-color: #fafafa;
      border: 1px solid #d0d0d0;
    `;
  }
  return `
    background-color: #ffffff;
  `;
};

const InputBox = styled.input`
  color: #000000;
  font-size: 1.4rem;
  border: none;
  outline: none;
  box-sizing: border-box;
  ${(props: Props) => themeStyle(props.theme)};
  &:disabled {
    background-color: #ffffff;
    opacity: 1;
  }
  &::placeholder {
    color: #7c8a98;
  }
`;

export default Input;
