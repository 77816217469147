import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Types from 'MyTypes';
import Base from '../../components/molecules/Base';
import { Skills as SkillsAPI } from '../../api/admin';
import SkillCard from '../../components/molecules/SkillCard';
import SkillSetting from '../../components/molecules/SkillSetting';
import SkillPeriods from '../../components/molecules/SkillPeriods';
import SkillCategory from '../../components/molecules/SkillCategory';
import SkillQuestion from '../../components/molecules/SkillQuestion';
import Label from '../../components/atoms/Label';
import IconBack from '../../components/atoms/icons/IconBack';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Categories as CategoryAPI } from '../../api/admin/categories';
import { categoryActions, categoryModels } from '../../features/category';
import { Periods as PeriodAPI } from '../../api/admin/periods';
import { periodActions, periodModels } from '../../features/period';
import { tagActions, tagModels } from '../../features/tag';
import { Tags as TagAPI } from '../../api/admin/tags';
import { skillModels } from '../../features/skill';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import moment from 'moment';

enum ProcMode {
  MODE_DEFAULT,
  MODE_CATE_ADD,
  MODE_CATE_EDIT,
  MODE_PERIOD_ADD,
  MODE_PERIOD_EDIT,
  MODE_TAG_ADD,
  MODE_TAG_EDIT,
}

type Props = {
  children?: React.ReactNode;
  categories: categoryModels.Category[];
  setCategories: (flags: categoryModels.CategoryJSON) => {};
  periods: periodModels.Period[];
  setPeriods: (periods: periodModels.PeriodJson) => {};
  tags: tagModels.Tag[];
  setTags: (tags: tagModels.TagJSON) => {};
};

type State = {
  isLoading: boolean;
  isEditing: boolean;
  procMode: ProcMode;
  skillCard: skillModels.SkillDetailJSON;
  skillsDetailStats: skillModels.SkillsDetailStatsJSON;
  categories: categoryModels.Category[];
  periods: periodModels.Period[];
  editPeriodIndex: number;
  tags: tagModels.Tag[];
  isExecuting: boolean;
  editCategoryIndex: { cate: number; sub: number; subsub: number };
  editTagIndex: number;

  isOpenSetting: boolean;
  isOpenPeriod: boolean;
  isOpenCategory: boolean;
  isOpenTag: boolean;
};

type PageProps = Props & RouteComponentProps<{ skill_id: string }>;

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    categories: state.category.categories,
    periods: state.period.periods,
    tags: state.tag.tags,
  };
};

const mapDispatchToProps = {
  setCategories: categoryActions.set,
  setPeriods: periodActions.set,
  setTags: tagActions.set,
};

enum CategoryType {
  Category,
  Sub,
  SubSub,
}

class SkillsDetail extends React.Component<PageProps, State> {
  readonly state: State = {
    isLoading: true,
    isEditing: false,
    procMode: ProcMode.MODE_DEFAULT,
    skillCard: {
      overview: {
        business_id: 0,
        skill_id: 0,
        name: '',
        acceptable_operators: 0,
        items_that_day: 0,
        max_waiting_time: 0,
        num_of_waiting: 0,
        order: 0,
        remaining_acceptable_incoming: 0,
        remaining_acceptable_items: 0,
        total_acceptable_items: 0,
        business_name: '',
        current_items: 0,
        num_of_leaving: 0,
        color: '#ffffff',
        max_num_of_waiting_items: 1,
      },
    },
    skillsDetailStats: {
      business_id: 0,
      skill_id: 0,
      name: '',
      after_business_hours_message: '',
      auto_response_message: '',
      blinking: '',
      business_day_of_weeks: [],
      business_hours_end: '',
      business_hours_start: '',
      color: '#ffffff',
      hard_blinking: '',
      lighting: '',
      max_num_of_waiting_items: 1,
      order: 0,
      timeout_manned: '',
      timeout_unmanned: '',
      visible: true,
      shop_use_initial_state_flag: false,
      waiting_person_exceeded_message: '',
    },
    categories: [],
    periods: [],
    editPeriodIndex: -1,
    tags: [],
    isExecuting: false,
    editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
    editTagIndex: -1,
    isOpenCategory: true,
    isOpenPeriod: true,
    isOpenSetting: true,
    isOpenTag: true,
  };

  readonly stats = {
    operatorCode: process.env.REACT_APP_OPERATOR_CODE,
  };

  get DayOfWeeksInitValue() {
    return [
      { value: 'mon', index: 0, selected: false },
      { value: 'tue', index: 1, selected: false },
      { value: 'wed', index: 2, selected: false },
      { value: 'thu', index: 3, selected: false },
      { value: 'fri', index: 4, selected: false },
      { value: 'sat', index: 5, selected: false },
      { value: 'sun', index: 6, selected: false },
    ];
  }

  componentDidMount() {
    this.fetchSkills();
  }

  fetchSkills = async () => {
    const skillId = parseInt(this.props.match.params.skill_id, 10);
    const resSkills = await SkillsAPI.getStats(skillId);
    if (resSkills.status === 200) {
      this.setState({
        skillCard: resSkills.data,
      });
    }

    const resSkillsDetail = await SkillsAPI.get(skillId);
    if (resSkillsDetail.status === 200) {
      this.setState({
        skillsDetailStats: resSkillsDetail.data.attributes,
      });
    }

    await this.fetchCategories(skillId);
    await this.fetchPeriods(skillId);
    await this.fetchTags(skillId);

    this.setState({ isLoading: false });
  };

  fetchCategories = async (skillId: number) => {
    CategoryAPI.getAll(skillId)
      .then(response => {
        if (response.status === 200) {
          this.props.setCategories(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ categories: this.props.categories });
      });
  };

  fetchPeriods = async (skillId: number) => {
    PeriodAPI.getAll(skillId)
      .then(response => {
        if (response.status === 200) {
          this.props.setPeriods(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ periods: this.props.periods });
      });
  };

  fetchTags = async (skillId: number) => {
    TagAPI.getAll(skillId)
      .then(response => {
        if (response.status === 200) {
          this.props.setTags(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        this.setState({ tags: this.props.tags });
      });
  };

  handleEventCategoryName = (
    event: React.ChangeEvent<HTMLInputElement>,
    categoryType: number,
    cateIdx: number,
    subIdx: number,
    subsubIdx: number,
  ): (() => void) => {
    const copyCategories = this.state.categories.slice();

    switch (categoryType) {
      case CategoryType.Category: {
        copyCategories[cateIdx].name = event.target.value;
        break;
      }
      case CategoryType.Sub: {
        copyCategories[cateIdx].subCategories[subIdx].name = event.target.value;
        break;
      }
      case CategoryType.SubSub: {
        copyCategories[cateIdx].subCategories[subIdx].subSubCategories[subsubIdx].name = event.target.value;
        break;
      }
    }
    this.setState({ categories: copyCategories });
    return;
  };

  handleEventSettingAction = (eventName: string): void => {
    if (eventName === 'toggle') {
      this.setState({ isOpenSetting: !this.state.isOpenSetting });
    }
  };

  handleDayChangeEventPeriodAction = (index: number, from: Date, to: Date): void => {
    const copyPeriods = this.state.periods.slice();
    copyPeriods[index].periodStart = moment(from).format('YYYY/MM/DD');
    copyPeriods[index].periodEnd = moment(to).format('YYYY/MM/DD');
    this.setState({ periods: copyPeriods });
  };

  handleChangeEventPeriodAction = (index: number, name: string, value: string): void => {
    const copyPeriods = this.state.periods.slice();
    switch (name) {
      case 'hourstart': {
        const timeArr = copyPeriods[index].businessHoursStart.split(':');
        copyPeriods[index].businessHoursStart = `${value}:${timeArr[1]}`;
        this.setState({ periods: copyPeriods });
        break;
      }
      case 'hourend': {
        const timeArr = copyPeriods[index].businessHoursEnd.split(':');
        copyPeriods[index].businessHoursEnd = `${value}:${timeArr[1]}`;
        this.setState({ periods: copyPeriods });
        break;
      }
      case 'minutestart': {
        const timeArr = copyPeriods[index].businessHoursStart.split(':');
        copyPeriods[index].businessHoursStart = `${timeArr[0]}:${value}`;
        this.setState({ periods: copyPeriods });
        break;
      }
      case 'minuteend': {
        const timeArr = copyPeriods[index].businessHoursEnd.split(':');
        copyPeriods[index].businessHoursEnd = `${timeArr[0]}:${value}`;
        this.setState({ periods: copyPeriods });
        break;
      }
    }
  };

  handleClickEventPeriodAction = (eventName: string, index: number): void => {
    if (eventName === 'toggle') {
      this.setState({ isOpenPeriod: !this.state.isOpenPeriod });
      return;
    }

    if (
      this.state.procMode !== ProcMode.MODE_DEFAULT &&
      this.state.procMode !== ProcMode.MODE_PERIOD_ADD &&
      this.state.procMode !== ProcMode.MODE_PERIOD_EDIT
    )
      return;
    if (this.state.isEditing && (eventName === 'add' || eventName === 'edit')) return;
    if (this.state.isEditing && this.state.editPeriodIndex !== index) return;

    const skillId = parseInt(this.props.match.params.skill_id, 10);
    switch (eventName) {
      case 'add': {
        const copyPeriods = this.state.periods.slice();
        const periodStart = moment();
        const periodEnd = periodStart.add(1, 'd');
        copyPeriods.push({
          isEditing: true,
          periodId: 0,
          skillId: skillId,
          periodStart: periodStart.format('YYYY/MM/DD'),
          periodEnd: periodEnd.format('YYYY/MM/DD'),
          businessHoursStart: '00:00:00',
          businessHoursEnd: '00:00:00',
        });
        this.setState({
          isEditing: true,
          periods: copyPeriods,
          editPeriodIndex: index,
          procMode: ProcMode.MODE_PERIOD_ADD,
        });
        break;
      }
      case 'edit': {
        const copyPeriods = this.state.periods.slice();
        copyPeriods[index].isEditing = true;
        this.setState({
          isEditing: true,
          periods: copyPeriods,
          editPeriodIndex: index,
          procMode: ProcMode.MODE_PERIOD_EDIT,
        });
        break;
      }
      case 'delete': {
        const copyPeriods = this.state.periods.slice();

        if (this.state.procMode === ProcMode.MODE_PERIOD_ADD) {
          copyPeriods.pop();
          this.setState({
            isEditing: false,
            periods: copyPeriods,
            editPeriodIndex: -1,
            procMode: ProcMode.MODE_DEFAULT,
          });
        } else {
          this.setState({ isLoading: true });
          PeriodAPI.delete(copyPeriods[index].periodId).then(response => {
            if (response.status === 204) {
              copyPeriods.splice(index, 1);
              this.setState({
                isEditing: false,
                periods: copyPeriods,
                editPeriodIndex: -1,
                procMode: ProcMode.MODE_DEFAULT,
              });
              this.fetchPeriods(skillId);
              this.setState({ isLoading: false });
              alert('削除が完了しました');
            } else {
              this.setState({ isLoading: false });
              alert(response.data.message);
            }
          });
        }
        break;
      }
      case 'commit': {
        const currentDate = moment().format('YYYY/MM/DD');
        const copyPeriods = this.state.periods.slice();
        const period = copyPeriods[index];
        const addParam = {
          period_id: period.periodId,
          skill_id: period.skillId,
          period_start: period.periodStart,
          period_end: period.periodEnd,
          business_hours_start: period.businessHoursStart,
          business_hours_end: period.businessHoursEnd,
        };

        if (addParam.period_end < addParam.period_start) {
          alert('期間指定に誤りがあります');
          return;
        }
        if (addParam.business_hours_end === currentDate) {
          alert('期間終了日は今日より大きい値を入力してください');
          return;
        }

        this.setState({ isLoading: true });
        if (this.state.procMode === ProcMode.MODE_PERIOD_ADD) {
          PeriodAPI.add(addParam)
            .then(response => {
              if (response.status === 200) {
                this.setState({
                  periods: copyPeriods,
                  isEditing: false,
                  editPeriodIndex: -1,
                  procMode: ProcMode.MODE_DEFAULT,
                });
                this.fetchPeriods(skillId);
                this.setState({ isLoading: false });
                alert('追加が完了しました');
              } else {
                this.setState({ isLoading: false });
                if (response.data.error_code === 103) {
                  if (response.data.message.period !== undefined) {
                    alert(response.data.message.period);
                  } else if (response.data.message.period_start !== undefined) {
                    alert(response.data.message.period_start);
                  } else if (response.data.message.period_end !== undefined) {
                    alert(response.data.message.period_end);
                  } else {
                    alert('エラーが発生しました');
                  }
                } else {
                  alert('エラーが発生しました');
                }
              }
            })
            .catch(error => {
              this.setState({ isLoading: false });
              alert(`エラーが発生しました:${error}`);
            });
        } else if (this.state.procMode === ProcMode.MODE_PERIOD_EDIT) {
          PeriodAPI.edit(addParam)
            .then(response => {
              if (response.status === 204) {
                this.setState({
                  isEditing: false,
                  periods: copyPeriods,
                  editPeriodIndex: -1,
                  procMode: ProcMode.MODE_DEFAULT,
                });
                this.fetchPeriods(skillId);
                this.setState({ isLoading: false });
                alert('変更が完了しました');
              } else {
                this.setState({ isLoading: false });
                if (response.data.error_code === 103) {
                  if (response.data.message.period !== undefined) {
                    alert(response.data.message.period);
                  } else if (response.data.message.period_start !== undefined) {
                    alert(response.data.message.period_start);
                  } else if (response.data.message.period_end !== undefined) {
                    alert(response.data.message.period_end);
                  } else {
                    alert('エラーが発生しました');
                  }
                } else {
                  alert('エラーが発生しました');
                }
              }
            })
            .catch(error => {
              this.setState({ isLoading: false });
              alert(`エラーが発生しました:${error}`);
            });
        }
        break;
      }
    }
  };

  handleEventCategoryAction = (
    eventName: string,
    categoryType: number,
    cateIdx: number,
    subIdx: number,
    subsubIdx: number,
  ): (() => void) => {
    const copyCategories = this.state.categories.slice();
    if (eventName === 'toggle') {
      if (cateIdx < 0 && subIdx < 0 && subsubIdx < 0) {
        this.setState({ isOpenCategory: !this.state.isOpenCategory });
      } else {
        copyCategories[cateIdx].isOpen = !copyCategories[cateIdx].isOpen;
        this.setState({ categories: copyCategories });
      }
    }

    if (
      this.state.procMode !== ProcMode.MODE_DEFAULT &&
      this.state.procMode !== ProcMode.MODE_CATE_ADD &&
      this.state.procMode !== ProcMode.MODE_CATE_EDIT
    )
      return;
    if (this.state.isEditing && (eventName === 'add' || eventName === 'edit')) return;
    if (
      this.state.isEditing &&
      (this.state.editCategoryIndex.cate !== cateIdx ||
        this.state.editCategoryIndex.sub !== subIdx ||
        this.state.editCategoryIndex.subsub !== subsubIdx)
    ) {
      return;
    }

    if (eventName !== 'toggle') {
      this.setState({ editCategoryIndex: { cate: cateIdx, sub: subIdx, subsub: subsubIdx } });
    }

    const skillId = parseInt(this.props.match.params.skill_id, 10);

    switch (eventName) {
      case 'add': {
        this.setState({ isEditing: true });
        switch (categoryType) {
          case CategoryType.Category: {
            const newCategory: categoryModels.Category = {
              isOpen: false,
              isEditing: true,
              categoryId: 0,
              name: '',
              subCategories: [],
            };
            copyCategories.push(newCategory);
            this.setState({ procMode: ProcMode.MODE_CATE_ADD, categories: copyCategories });
            break;
          }
          case CategoryType.Sub: {
            const newSubCategory: categoryModels.SubCategory = {
              isEditing: true,
              subCategoryId: 0,
              name: '',
              subSubCategories: [],
            };
            copyCategories[cateIdx].subCategories.push(newSubCategory);
            this.setState({ procMode: ProcMode.MODE_CATE_ADD, categories: copyCategories });
            break;
          }
          case CategoryType.SubSub: {
            const newSubSubCategory: categoryModels.SubSubCategory = {
              isEditing: true,
              subSubCategoryId: 0,
              name: '',
            };
            copyCategories[cateIdx].subCategories[subIdx].subSubCategories.push(newSubSubCategory);
            this.setState({ procMode: ProcMode.MODE_CATE_ADD, categories: copyCategories });
            break;
          }
        }

        break;
      }
      case 'edit': {
        this.setState({ isEditing: true });
        if (categoryType === CategoryType.Category) {
          copyCategories[cateIdx].isEditing = true;
        } else if (categoryType === CategoryType.Sub) {
          copyCategories[cateIdx].subCategories[subIdx].isEditing = true;
        } else if (categoryType === CategoryType.SubSub) {
          copyCategories[cateIdx].subCategories[subIdx].subSubCategories[subsubIdx].isEditing = true;
        }
        this.setState({ categories: copyCategories, procMode: ProcMode.MODE_CATE_EDIT });
        break;
      }
      case 'delete': {
        this.setState({ isExecuting: true });

        switch (categoryType) {
          case CategoryType.Category: {
            //追加モード時の削除はAPI通信不要
            if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              copyCategories.pop();
              this.setState({
                procMode: ProcMode.MODE_DEFAULT,
                categories: copyCategories,
                isEditing: false,
                isExecuting: false,
                editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
              });
              return;
            }

            this.setState({ isLoading: true });
            const categoryId = copyCategories[cateIdx].categoryId;
            copyCategories[cateIdx].isEditing = false;

            CategoryAPI.deleteCategory(categoryId).then(response => {
              if (response.status === 204) {
                copyCategories.splice(cateIdx, 1);
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                  isLoading: false,
                });
                alert('大カテゴリの削除が完了しました');
              } else {
                this.setState({ isLoading: false });
                alert(response.data.message);
              }
            });
            break;
          }
          case CategoryType.Sub: {
            //追加モード時の削除はAPI通信不要
            if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              copyCategories[cateIdx].subCategories.pop();
              this.setState({
                procMode: ProcMode.MODE_DEFAULT,
                categories: copyCategories,
                isEditing: false,
                isExecuting: false,
                editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
              });
              return;
            }

            this.setState({ isLoading: true });
            const category = copyCategories[cateIdx];
            const subCategoryId = category.subCategories[subIdx].subCategoryId;
            category.subCategories[subIdx].isEditing = false;

            CategoryAPI.deleteSubCategory(subCategoryId).then(response => {
              if (response.status === 204) {
                category.subCategories.splice(subIdx, 1);
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                  isLoading: false,
                });
                alert('中カテゴリの削除が完了しました');
              } else {
                this.setState({ isLoading: false });
                alert(response.data.message);
              }
            });
            break;
          }
          case CategoryType.SubSub: {
            //追加モード時の削除はAPI通信不要
            if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              copyCategories[cateIdx].subCategories[subIdx].subSubCategories.pop();
              this.setState({
                procMode: ProcMode.MODE_DEFAULT,
                categories: copyCategories,
                isEditing: false,
                isExecuting: false,
                editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
              });
              return;
            }

            this.setState({ isLoading: true });
            const subCategory = copyCategories[cateIdx].subCategories[subIdx];
            const subSubCategoryId = subCategory.subSubCategories[subsubIdx].subSubCategoryId;
            subCategory.subSubCategories[subsubIdx].isEditing = false;

            CategoryAPI.deleteSubSubCategory(subSubCategoryId).then(response => {
              if (response.status === 204) {
                subCategory.subSubCategories.splice(subsubIdx, 1);
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                  isLoading: false,
                });
                alert('小カテゴリの削除が完了しました');
              } else {
                this.setState({ isLoading: false });
                alert(response.data.message);
              }
            });
            break;
          }
        }
        break;
      }
      case 'commit': {
        this.setState({ isExecuting: true });

        const categoryId = copyCategories[cateIdx].categoryId;
        switch (categoryType) {
          case CategoryType.Category: {
            const categoryName = copyCategories[cateIdx].name;
            if (categoryName.length === 0) {
              alert('大カテゴリ名が未入力です');
              return;
            }

            this.setState({ isLoading: true });
            copyCategories[cateIdx].isEditing = false;
            if (this.state.procMode === ProcMode.MODE_CATE_EDIT) {
              CategoryAPI.putCategory({ skill_id: skillId, category_id: categoryId, name: categoryName }).then(
                response => {
                  this.setState({
                    categories: copyCategories,
                    isEditing: false,
                    isExecuting: false,
                    procMode: ProcMode.MODE_DEFAULT,
                    editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                  });

                  if (response.status === 204) {
                    this.setState({ isLoading: false });
                    alert('大カテゴリ名の更新が完了しました');
                  } else {
                    //再取得
                    this.fetchCategories(skillId);
                    this.setState({ isLoading: false });
                    alert(`${response.data.message}`);
                  }
                },
              );
            } else if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              CategoryAPI.addCategory(skillId, categoryName).then(response => {
                this.setState({
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                });

                if (response.status === 200) {
                  this.setState({
                    categories: copyCategories,
                  });

                  //再取得
                  this.fetchCategories(skillId);
                  this.setState({ isLoading: false });
                  alert('大カテゴリの追加が完了しました');
                } else {
                  copyCategories.pop();
                  this.setState({
                    categories: copyCategories,
                    isLoading: false,
                  });
                  alert(`${response.data.message}`);
                }
              });
            }
            break;
          }
          case CategoryType.Sub: {
            const category = copyCategories[cateIdx];
            const subCategoryId = category.subCategories[subIdx].subCategoryId;
            const subCategoryName = category.subCategories[subIdx].name;
            if (subCategoryName.length === 0) {
              alert('中カテゴリ名が未入力です');
              return;
            }

            this.setState({ isLoading: true });
            category.subCategories[subIdx].isEditing = false;
            if (this.state.procMode === ProcMode.MODE_CATE_EDIT) {
              CategoryAPI.putSubCategory({
                category_id: categoryId,
                subcategory_id: subCategoryId,
                name: subCategoryName,
              }).then(response => {
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                });

                if (response.status === 204) {
                  this.setState({ isLoading: false });
                  alert('中カテゴリ名の更新が完了しました');
                } else {
                  //再取得
                  this.fetchCategories(skillId);
                  this.setState({ isLoading: false });
                  alert(`${response.data.message}`);
                }
              });
            } else if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              CategoryAPI.addSubCategory(category.categoryId, subCategoryName).then(response => {
                this.setState({
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                });
                if (response.status === 200) {
                  this.setState({
                    categories: copyCategories,
                  });

                  //再取得
                  this.fetchCategories(skillId);
                  this.setState({ isLoading: false });
                  alert('中カテゴリの追加が完了しました');
                } else {
                  copyCategories[cateIdx].subCategories.pop();
                  this.setState({
                    categories: copyCategories,
                    isLoading: false,
                  });
                  alert(`${response.data.message}`);
                }
              });
            }
            break;
          }
          case CategoryType.SubSub: {
            const subCategory = copyCategories[cateIdx].subCategories[subIdx];
            const subCategoryId = subCategory.subCategoryId;
            const subSubCategoryId = subCategory.subSubCategories[subsubIdx].subSubCategoryId;
            const subsubCategoryName = subCategory.subSubCategories[subsubIdx].name;
            if (subsubCategoryName.length === 0) {
              alert('小カテゴリ名が未入力です');
              return;
            }

            this.setState({ isLoading: true });
            subCategory.subSubCategories[subsubIdx].isEditing = false;
            if (this.state.procMode === ProcMode.MODE_CATE_EDIT) {
              CategoryAPI.putSubSubCategory({
                subcategory_id: subCategoryId,
                subsubcategory_id: subSubCategoryId,
                name: subsubCategoryName,
              }).then(response => {
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                });

                if (response.status === 204) {
                  this.setState({ isLoading: false });
                  alert('小カテゴリ名の更新が完了しました');
                } else {
                  //再取得
                  this.fetchCategories(skillId);
                  this.setState({ isLoading: false });
                  alert(`${response.data.message}`);
                }
              });
            } else if (this.state.procMode === ProcMode.MODE_CATE_ADD) {
              CategoryAPI.addSubSubCategory(subCategory.subCategoryId, subsubCategoryName).then(response => {
                this.setState({
                  categories: copyCategories,
                  isEditing: false,
                  isExecuting: false,
                  procMode: ProcMode.MODE_DEFAULT,
                  editCategoryIndex: { cate: -1, sub: -1, subsub: -1 },
                });

                if (response.status === 200) {
                  this.setState({
                    categories: copyCategories,
                  });

                  //再取得
                  this.fetchCategories(skillId);
                  this.setState({ isLoading: false });
                  alert('小カテゴリの追加が完了しました');
                } else {
                  copyCategories[cateIdx].subCategories[subIdx].subSubCategories.pop();
                  this.setState({
                    categories: copyCategories,
                    isLoading: false,
                  });
                  alert(`${response.data.message}`);
                }
              });
            }
            break;
          }
        }
        break;
      }
    }
    return;
  };

  handleEventTagName = (event: React.ChangeEvent<HTMLInputElement>, index: number): (() => void) => {
    const copyTags = this.state.tags.slice();
    copyTags[index].name = event.target.value;
    this.setState({ tags: copyTags });
    return;
  };

  handleEventTagAction = (eventName: string, index: number): (() => void) => {
    if (
      this.state.procMode !== ProcMode.MODE_DEFAULT &&
      this.state.procMode !== ProcMode.MODE_TAG_ADD &&
      this.state.procMode !== ProcMode.MODE_TAG_EDIT
    )
      return;
    if (this.state.isEditing && (eventName === 'add' || eventName === 'edit')) return;
    if (this.state.isEditing && this.state.editTagIndex !== index) return;

    if (eventName !== 'toggle') {
      this.setState({ editTagIndex: index });
    }

    const skillId = parseInt(this.props.match.params.skill_id, 10);
    const copyTags = this.state.tags.slice();

    switch (eventName) {
      case 'add': {
        this.setState({ isEditing: true });
        const newTag: tagModels.Tag = {
          isEditing: true,
          tagId: 0,
          name: '',
          skillId: skillId,
        };
        copyTags.push(newTag);
        this.setState({ tags: copyTags, procMode: ProcMode.MODE_TAG_ADD });
        break;
      }
      case 'edit': {
        this.setState({ isEditing: true });
        copyTags[index].isEditing = true;
        this.setState({ tags: copyTags, procMode: ProcMode.MODE_TAG_EDIT });
        break;
      }
      case 'delete': {
        this.setState({ isExecuting: true });
        //追加モード時の削除はAPI通信不要
        if (this.state.procMode === ProcMode.MODE_TAG_ADD) {
          copyTags.pop();
          this.setState({
            procMode: ProcMode.MODE_DEFAULT,
            tags: copyTags,
            isEditing: false,
            isExecuting: false,
            editTagIndex: index,
          });
          return;
        }

        this.setState({ isLoading: true });
        const tagId = copyTags[index].tagId;
        copyTags[index].isEditing = false;

        TagAPI.delete(tagId).then(response => {
          if (response.status === 204) {
            copyTags.splice(index, 1);
            this.setState({
              tags: copyTags,
              isExecuting: false,
              procMode: ProcMode.MODE_DEFAULT,
              editTagIndex: -1,
              isLoading: false,
            });
            alert('タグの削除が完了しました');
          } else {
            this.setState({
              isExecuting: false,
              procMode: ProcMode.MODE_DEFAULT,
              editTagIndex: -1,
              isLoading: false,
            });
            alert(response.data.message);
          }
        });
        break;
      }
      case 'commit': {
        this.setState({ isExecuting: true });

        const tagId = copyTags[index].tagId;
        const tagName = copyTags[index].name;
        if (tagName.length === 0) {
          alert('タグ名が未入力です');
          return;
        }

        this.setState({ isLoading: true });
        copyTags[index].isEditing = false;
        if (this.state.procMode === ProcMode.MODE_TAG_EDIT) {
          TagAPI.edit({ tag_id: tagId, skill_id: skillId, name: tagName }).then(response => {
            this.setState({
              tags: copyTags,
              isEditing: false,
              isExecuting: false,
              procMode: ProcMode.MODE_DEFAULT,
              editTagIndex: -1,
            });

            if (response.status === 204) {
              this.setState({ isLoading: false });
              alert('タグ名の更新が完了しました');
            } else {
              //再取得
              this.fetchTags(skillId);
              this.setState({ isLoading: false });
              alert(response.data.message);
            }
          });
        } else if (this.state.procMode === ProcMode.MODE_TAG_ADD) {
          TagAPI.add({ skill_id: skillId, name: tagName }).then(response => {
            this.setState({
              isEditing: false,
              isExecuting: false,
              procMode: ProcMode.MODE_DEFAULT,
              editTagIndex: -1,
            });

            if (response.status === 200) {
              this.setState({
                tags: copyTags,
              });

              //再取得
              this.fetchTags(skillId);
              this.setState({ isLoading: false });
              alert('タグの追加が完了しました');
            } else {
              copyTags.pop();
              this.setState({
                tags: copyTags,
                isLoading: false,
              });
              alert(response.data.message);
            }
          });
        }
      }
    }
    return;
  };

  render() {
    const {
      isLoading,
      isEditing,
      procMode,
      skillCard,
      skillsDetailStats,
      categories,
      periods,
      tags,
      isOpenPeriod,
      isOpenCategory,
      isOpenSetting,
      isOpenTag,
    } = this.state;

    return (
      <Base>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Header>
          <RouteLink to={`/skills_admin?businessId=${skillCard.overview.business_id}`}>
            <StyleIconBack size={16} />
            <BackLabel text="窓口一覧に戻る" />
          </RouteLink>
        </Header>
        <Wrap>
          <SkillCard stats={skillCard} />
          <Padding />
          <SkillSetting isOpen={isOpenSetting} stats={skillsDetailStats} onClick={this.handleEventSettingAction} />
          <Padding />
          <SkillPeriods
            isOpen={isOpenPeriod}
            stats={periods}
            isEditing={isEditing || procMode === ProcMode.MODE_PERIOD_ADD || procMode === ProcMode.MODE_PERIOD_EDIT}
            onClick={this.handleClickEventPeriodAction}
            onDayChange={this.handleDayChangeEventPeriodAction}
            onChange={this.handleChangeEventPeriodAction}
            onDayClick={this.handleClickEventPeriodDayAction}
          />
          <Padding />
          <SkillCategory
            isOpen={isOpenCategory}
            stats={categories}
            isEditing={isEditing || procMode === ProcMode.MODE_CATE_ADD || procMode === ProcMode.MODE_CATE_EDIT}
            onClick={this.handleEventCategoryAction}
            onChange={this.handleEventCategoryName}
          />
          <Padding />
          <SkillQuestion
            isOpen={isOpenTag}
            stats={tags}
            isEditing={isEditing || procMode === ProcMode.MODE_TAG_ADD || procMode === ProcMode.MODE_TAG_EDIT}
            onClick={this.handleEventTagAction}
            onChange={this.handleEventTagName}
          />
        </Wrap>
      </Base>
    );
  }
}

const Header = styled.div`
  background-color: #ffffff;
  margin-bottom: 30px;
  margin-left: -20px;
  margin-top: -20px;
  padding-top: 15px;
  padding-left: 20px;
  width: calc(100% + 50px);
  height: 50px;
`;

const BackLabel = styled(Label)``;

const StyleIconBack = styled(IconBack)`
  stroke: #707070;
  padding-right: 10px;
`;

const Wrap = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const Padding = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;

const RouteLink = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SkillsDetail);
