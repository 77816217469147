import * as React from 'react';
import styled from 'styled-components';
import Loading from '../../components/atoms/Loading';

type Props = {
  children?: React.ReactNode;
  type?: 'blank' | 'balls' | 'bars' | 'bubbles' | 'cubes' | 'cylon' | 'spin' | 'spinningBubbles' | 'spokes';
  color?: string;
  width?: string | number;
  height?: string | number;
  isloading: boolean;
};

interface LoadingInterface {
  isloading: boolean;
}

const ScreenLoading: React.FC<Props> = props => {
  const { isloading, children, type, color, width, height } = props;
  return (
    <LoadingArea isloading={isloading}>
      <StyleLoading isloading={isloading} children={children} type={type} color={color} width={width} height={height} />
    </LoadingArea>
  );
};

const LoadingArea = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  opacity: 0.5;
  margin-left: 190px;
  overflow: scroll;

  z-index: 98;
  display: ${(props: LoadingInterface) => (props.isloading ? 'default' : 'none')};
`;

const StyleLoading = styled(Loading)`
  position: fixed;
  top: 10%;
  left: 50%;
  bottom: 10%;
  margin: auto;
  background-color: #fafafa;

  z-index: 99;
  display: ${(props: LoadingInterface) => (props.isloading ? 'default' : 'none')};
`;

export default ScreenLoading;
