import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../Theme';
import IconCheck from './icons/IconCheck2';

type Props = {
  disabled?: boolean;
  checked: boolean;
  label?: string | React.ReactNode;
  onClick?: (value: boolean) => void;
};

const Checkbox: React.FC<Props> = props => {
  const { label, checked, disabled, onClick, ...rest } = props;

  return (
    <Wrapper
      {...rest}
      checked={checked}
      disabled={disabled ? disabled : false}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        if (disabled) return;
        if (typeof onClick === 'function') onClick(!checked);
      }}
    >
      <Label space={30}>
        <Input type="checkbox" disabled={disabled} />
        {label}
      </Label>
      {checked ? <Check size={12} fill={'#ffffff'} /> : <span />}
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  label: '',
  disabled: false,
  onClick: _value => console.log('Clicked'),
};

type WrapperProps = {
  checked: boolean;
  disabled: boolean;
};

type LabelProps = {
  space: number;
};

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
  transition-property: border-color, opacity;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  vertical-align: middle;
  box-sizing: border-box;
  pointer-events: ${(props: WrapperProps) => (props.disabled ? 'none' : 'inherit')};
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 11px);
    left: 0;
    width: 20px;
    height: 20px;
    background-color: ${(props: WrapperProps) => (props.checked ? theme.primaryColor : '#f8f8f8')};
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 8px);
    left: 5px;
    display: block;
    width: 13px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: ${(props: WrapperProps) => (props.checked ? 1 : 0)};
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  padding-left: ${(props: LabelProps) => `${props.space}px`};
  text-align: left;
  cursor: pointer;
`;

const Check = styled(IconCheck)`
  z-index: 100;
  position: absolute;
  top: 5.5px;
  left: 4px;
`;

export default Checkbox;
