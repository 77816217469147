import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { BlackList, BlackListJSON } from './models';
import moment from 'moment';

export const set = createAction(SET, (json: BlackListJSON[]) => {
  return json.map(
    (blackList: BlackListJSON) =>
      ({
        userId: blackList.attributes.user_id,
        crewCode: blackList.attributes.crew_code,
        blackListedAt:
          blackList.attributes.blacklisted_at === undefined
            ? ''
            : moment(blackList.attributes.blacklisted_at).format('YYYY/MM/DD'),
        createdAt:
          blackList.attributes.created_at === undefined
            ? ''
            : moment(blackList.attributes.created_at).format('YYYY/MM/DD'),
        updatedAt:
          blackList.attributes.updated_at === undefined
            ? ''
            : moment(blackList.attributes.updated_at).format('YYYY/MM/DD'),
        name: blackList.attributes.name,
        kana: blackList.attributes.kana,
        status: blackList.attributes.status,
      } as BlackList),
  ) as BlackList[];
})<BlackList[]>();
