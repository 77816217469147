import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../Theme';
import Button from './Button';

interface Props {
  label: string;
  selected: boolean;
  index: number;
  onClick?: (index: number) => void;
}

const SquareWeekButton: React.FC<Props> = props => {
  const { label, onClick, index } = props;

  return (
    <StyledButton {...props} onClick={() => onClick(index)}>
      {label}
    </StyledButton>
  );
};

const selectStyle = (selected?: boolean) => {
  if (selected === true) {
    return `
      background-color: ${theme.primaryColor};
      color: #ffffff;
    `;
  } else {
    return `
      background-color: #fafafa;
    `;
  }
};

const StyledButton = styled(Button)`
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 12px;
  margin-right: 10px;

  ${(props: Props) => selectStyle(props.selected)};
`;

export default SquareWeekButton;
