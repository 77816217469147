import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { AnswerSetting, AnswerSettingJSON } from './models';

export const set = createAction(SET, (json: AnswerSettingJSON[]) =>
  json.map(answer => {
    const attributes = answer.attributes;
    return {
      isChecked: false,
      isEditing: false,
      isDeleting: false,
      answerSettingId: attributes.answer_setting_id,
      qa: attributes.qa,
      content: attributes.content,
      updatedAt: attributes.updated_at,
    } as AnswerSetting;
  }),
)<AnswerSetting[]>();
