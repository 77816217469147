import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Types from 'MyTypes';
import { answerSettingActions, answerSettingModels } from '../../features/answer';
import { answerHistoryActions, answerHistoryModels } from '../../features/answer/history';
import { userModels } from '../../features/user';
import Base from '../../components/molecules/Base';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconCheck2 from '../../components/atoms/icons/IconCheck2';
import IconEdit from '../../components/atoms/icons/IconEdit';
import IconDelete from '../../components/atoms/icons/IconDelete';
import Table, { Cell, Header, Row } from '../../components/atoms/TableScroll';
import { AnswerSettings as AnswerSettingAPI, AnswerHistory as AnswerHistoryAPI } from '../../api/admin';
import ScreenLoading from '../../components/atoms/ScreenLoading';
import SelectBox from '../../components/atoms/SelectBox';
import { OptionInterface } from '../../utils/interface';
import moment from 'moment';
import { Reports } from '../../api/admin';
import { AxiosResponse } from 'axios';
import Button from '../../components/atoms/Button';

type Props = {
  children?: React.ReactNode;
  answerSettings: answerSettingModels.AnswerSetting[];
  answerHistories: answerHistoryModels.AnswerHistory[];
  user: userModels.User;
  setAnswerSettings: (answerSettings: answerSettingModels.AnswerSettingJSON) => {};
  setAnswerHistories: (answerHistories: answerHistoryModels.AnswerHistoryJSON) => {};
};

enum ExeType {
  MODE_DEFAULT,
  MODE_ADD,
  MODE_EDIT,
}

type State = {
  answerSettings: answerSettingModels.AnswerSetting[];
  answerHistories: answerHistoryModels.AnswerHistory[];
  editAnswerSetting: {
    answerSettingId: number;
    qa: string;
    content: string;
  };
  isLoading: boolean;
  isEditMode: ExeType;
  isCreateRowVisible: boolean;
  editIndex: number;
  qa: {
    type: string;
  };
  target: {
    type: string;
  };
  newContent: string;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    ...state.user,
    answerSettings: state.answerSetting.answerSettings,
    answerHistories: state.answerHistory.answerHistories,
  };
};

const mapDispatchToProps = {
  setAnswerSettings: answerSettingActions.set,
  setAnswerHistories: answerHistoryActions.set,
};

class AnswerSettingAdmin extends React.Component<Props, State> {
  readonly state: State = {
    answerSettings: [],
    answerHistories: [],
    editAnswerSetting: null,
    isLoading: true,
    isEditMode: ExeType.MODE_DEFAULT,
    isCreateRowVisible: false,
    editIndex: 0,
    qa: {
      type: 'Q',
    },
    target: {
      type: 'キーワード',
    },
    newContent: '',
  };

  readonly stats = {
    QorAOption: [
      { label: 'Q', value: 'Q', key: 'q', checked: false },
      { label: 'A', value: 'A', key: 'a', checked: false },
    ],
    TargetOption: [
      { label: 'キーワード', value: 'kewword', key: 'keyword', checked: false },
      { label: 'システム', value: 'system', key: 'system', checked: false },
    ],
  };

  componentDidMount(): void {
    this.fetchAnswerSettings();
    this.fetchAnswerHistories();
  }

  fetchAnswerSettings(): void {
    this.setState({ isLoading: true });

    AnswerSettingAPI.getAll()
      .then(response => {
        if (response.status === 200) {
          this.props.setAnswerSettings(response.data);
        } else {
          alert(`保存できませんでした。${response.data.message}`);
        }
      })
      .then(() => {
        this.setState({ answerSettings: this.props.answerSettings, isLoading: false });
      });
  }

  fetchAnswerHistories(): void {
    this.setState({ isLoading: true });

    AnswerHistoryAPI.getAll()
      .then(response => {
        if (response.status === 200) {
          this.props.setAnswerHistories(response.data);
        } else {
          alert(`保存できませんでした。${response.data.message}`);
        }
      })
      .then(() => {
        this.setState({ answerHistories: this.props.answerHistories, isLoading: false });
      });
  }

  storeAnswerHistory(answerSettingId: number, operation: string, content: string): void {
    const params = {
      answer_setting_id: answerSettingId,
      user_id: this.props.user.userId,
      operation: operation,
      update_content: content,
    };

    AnswerHistoryAPI.add(params)
      .then(response => {
        if (response.status !== 204) {
          alert(`保存できませんでした。${response.data.message}`);
        }
        this.fetchAnswerHistories();
      })
      .then(() => {
        this.setState({ answerHistories: this.props.answerHistories, isLoading: false });
      });
  }

  handleCreateRow = (): void => {
    const qa = this.state.qa;
    qa.type = 'Q';
    this.stats.QorAOption[0].checked = true;
    this.setState({ isCreateRowVisible: true, isEditMode: ExeType.MODE_ADD, qa: qa });
  };

  handleDeleteCreateRow = (): void => {
    this.setState({ isCreateRowVisible: false, isEditMode: ExeType.MODE_DEFAULT });
  };

  handleQaTypeChanged = (name: string, option?: OptionInterface): void => {
    const qa = this.state.qa;
    qa.type = `${option.value}`;
    this.setState({ qa: qa });
  };

  handleAnswerSettingAdd = (qa: string, content: string): void => {
    AnswerSettingAPI.add({ qa: qa, content: content }).then(response => {
      if (response.status === 200) {
        const addContent = '「' + qa + ' / ' + content + '」を作成';
        this.storeAnswerHistory(response.data.id, 'create', addContent);
        //再取得
        this.fetchAnswerSettings();
        this.setState({ isCreateRowVisible: false, newContent: '', isEditMode: ExeType.MODE_DEFAULT });
        alert('登録が完了しました');
      } else {
        alert(response.data.message.name);
      }
    });
  };

  handleEvent = (eventName: string, index: number): (() => void) => {
    if (this.state.editIndex !== 0 && this.state.editIndex !== index) return;
    const copyAnswerSettings = this.state.answerSettings.slice();

    switch (eventName) {
      case 'edit': {
        const editAnswerSetting = {
          answerSettingId: copyAnswerSettings[index].answerSettingId,
          qa: copyAnswerSettings[index].qa,
          content: copyAnswerSettings[index].content,
        };
        copyAnswerSettings[index].isEditing = true;
        this.stats.QorAOption[this.qaType(copyAnswerSettings[index].qa)].checked = true;
        const qa = this.state.qa;
        qa.type = copyAnswerSettings[index].qa;
        this.setState({
          isEditMode: ExeType.MODE_EDIT,
          answerSettings: copyAnswerSettings,
          editAnswerSetting: editAnswerSetting,
          editIndex: index,
          qa: qa,
        });
        break;
      }
      case 'commit': {
        const answerSettingId = copyAnswerSettings[index].answerSettingId;
        const qa = this.state.qa.type;
        const content = copyAnswerSettings[index].content;

        this.setState({ isLoading: true });
        copyAnswerSettings[index].isEditing = false;
        if (this.state.isEditMode === ExeType.MODE_EDIT) {
          AnswerSettingAPI.edit({ answer_setting_id: answerSettingId, qa: qa, content: content }).then(response => {
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              answerSettings: copyAnswerSettings,
              editIndex: 0,
            });

            if (response.status === 204) {
              this.fetchAnswerSettings();
              const editAnswerSetting = this.state.editAnswerSetting;
              const addContent =
                '「' +
                editAnswerSetting.qa +
                ' / ' +
                editAnswerSetting.content +
                '」を「' +
                qa +
                ' / ' +
                content +
                '」に更新';
              this.storeAnswerHistory(editAnswerSetting.answerSettingId, 'edit', addContent);

              this.setState({ isLoading: false, editAnswerSetting: null });
              alert('更新が完了しました');
            } else {
              //再取得
              this.fetchAnswerSettings();
              this.setState({ isLoading: false, editAnswerSetting: null });
              alert(response.data.message);
            }
            return;
          });
        }
        break;
      }
      case 'delete': {
        //追加モード時の削除はAPI通信不要
        if (this.state.isEditMode === ExeType.MODE_ADD) {
          copyAnswerSettings.pop();
          this.setState({
            isEditMode: ExeType.MODE_DEFAULT,
            answerSettings: copyAnswerSettings,
            editIndex: 0,
          });
          return;
        }

        const answerSettingId = copyAnswerSettings[index].answerSettingId;
        let editAnswerSetting = null;
        if (this.state.editAnswerSetting) {
          editAnswerSetting = {
            answerSettingId: this.state.editAnswerSetting.answerSettingId,
            qa: this.state.editAnswerSetting.qa,
            content: this.state.editAnswerSetting.content,
          };
        } else {
          editAnswerSetting = {
            answerSettingId: copyAnswerSettings[index].answerSettingId,
            qa: copyAnswerSettings[index].qa,
            content: copyAnswerSettings[index].content,
          };
        }

        AnswerSettingAPI.delete({ answer_setting_id: answerSettingId }).then(response => {
          if (response.status === 204) {
            copyAnswerSettings.splice(index, 1);
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              answerSettings: copyAnswerSettings,
              editIndex: 0,
            });
            //再取得
            const addContent = '「' + editAnswerSetting.qa + ' / ' + editAnswerSetting.content + '」を削除';
            this.storeAnswerHistory(response.data.id, 'delete', addContent);
            this.fetchAnswerSettings();
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              editIndex: 0,
              editAnswerSetting: null,
            });
            alert('削除が完了しました');
          } else {
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              editIndex: 0,
              editAnswerSetting: null,
            });
            alert(response.data.message.name);
          }
        });
        break;
      }
      default: {
        return () => console.log('default');
      }
    }
  };

  handleChangeFlagName = (content: string, index: number): (() => void) => {
    const copyAnswerSettings = this.state.answerSettings.slice();
    copyAnswerSettings[index].content = content;
    this.setState({
      answerSettings: copyAnswerSettings,
    });

    return;
  };

  handleOnContentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ newContent: event.target.value });
  };

  handleOnDownload = (): void => {
    Reports.fetchAnswerHistories().then(res => this.handleResponse('AI回答の制御', res));
  };

  handleResponse = (reportName: string, response: AxiosResponse) => {
    if (this.invalidResponse(response)) {
      return alert(`${reportName}レポートの出力に失敗しました。\n${response['data'] ? response.data.message : ''}`);
    }

    const { filename, url } = response.data.attributes;
    this.download(url, filename);
  };

  invalidResponse = (response: AxiosResponse): boolean => {
    return !response || response.status !== 200;
  };

  download = (url: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  qaType = (qaType: string): number => {
    if (qaType === 'a') {
      return 1;
    }
    return 0;
  };

  render() {
    const { answerSettings, answerHistories, isLoading, isEditMode, isCreateRowVisible } = this.state;
    const { stats } = this;
    return (
      <StyleBase>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Contents>
          <Titile>
            <SkillsIconArrow size={16} />
            <TitleLabel size={16} text="AI回答の制御" />
          </Titile>
          <Area>
            <Table>
              <THeader>
                <Cell>Q/A</Cell>
                <Cell>発話内容</Cell>
                <Cell>更新日</Cell>
                <Cell></Cell>
                <Cell></Cell>
              </THeader>
              {answerSettings.map((setting: answerSettingModels.AnswerSetting, i: number) => (
                <InfoRow key={i}>
                  {!setting.isEditing ? (
                    <InfoQaCell>{setting.qa}</InfoQaCell>
                  ) : (
                    <InfoQaCell>
                      <QaSelectBox
                        name={'qa'}
                        options={stats.QorAOption}
                        theme="gray"
                        loading={isLoading}
                        disabled={isLoading}
                        selectedOption={stats.QorAOption.filter(option => option.value === this.state.qa.type)[0]}
                        onChanged={this.handleQaTypeChanged}
                      />
                    </InfoQaCell>
                  )}
                  {!setting.isEditing ? (
                    <InfoContentCell>{setting.content}</InfoContentCell>
                  ) : (
                    <InfoContentCell>
                      <EditInput
                        type="text"
                        theme={'gray'}
                        value={setting.content}
                        placeholder={'発話内容を入力してください'}
                        name={'flagName'}
                        key={`flagNaume${i}`}
                        onChange={e => this.handleChangeFlagName(e.target.value, i)}
                      />
                    </InfoContentCell>
                  )}
                  <InfoCell>{`${moment(setting.updatedAt).format('YYYY/MM/DD HH:mm')}`}</InfoCell>
                  <InfoCellAction>
                    {!setting.isEditing ? (
                      <IconEdit
                        size={12}
                        onClick={() => this.handleEvent('edit', i)}
                        disabled={isEditMode !== ExeType.MODE_DEFAULT}
                      />
                    ) : (
                      <IconCheck2 size={12} fill={'#338ed8'} onClick={() => this.handleEvent('commit', i)} />
                    )}
                  </InfoCellAction>
                  <InfoCellAction>
                    <IconDelete
                      size={12}
                      onClick={() => this.handleEvent('delete', i)}
                      disabled={isEditMode !== ExeType.MODE_DEFAULT && !setting.isEditing}
                    />
                  </InfoCellAction>
                </InfoRow>
              ))}
              {isCreateRowVisible && (
                <Footer>
                  <InfoQaCell>
                    <QaSelectBox
                      name={'qa'}
                      options={stats.QorAOption}
                      theme="gray"
                      loading={isLoading}
                      disabled={isLoading}
                      selectedOption={stats.QorAOption.filter(option => option.value === this.state.qa.type)[0]}
                      onChanged={this.handleQaTypeChanged}
                    />
                  </InfoQaCell>
                  <InfoContentCell>
                    <EditInput
                      type="text"
                      theme={'gray'}
                      value={this.state.newContent}
                      placeholder={'発話内容を入力してください'}
                      name={'flagName'}
                      onChange={this.handleOnContentChange}
                    />
                  </InfoContentCell>
                  <Cell></Cell>
                  <InfoCellAction>
                    <IconCheck2
                      size={12}
                      fill={'#338ed8'}
                      onClick={() => this.handleAnswerSettingAdd(this.state.qa.type, this.state.newContent)}
                    />
                  </InfoCellAction>
                  <InfoCellAction>
                    <IconDelete size={12} onClick={() => this.handleDeleteCreateRow()} />
                  </InfoCellAction>
                </Footer>
              )}
            </Table>
            {!isLoading && (
              <AddFlagArea>
                <AddFlagLabel text="＋AI回答の制御を登録" size={14} onClick={() => this.handleCreateRow()} />
              </AddFlagArea>
            )}
          </Area>
          <LogTitile>
            <SkillsIconArrow size={16} />
            <TitleLabel size={16} text="AI回答の制御のログ" />
            <DownloadButton
              type="button"
              label={'CSVダウンロード'}
              kind={'primary'}
              width={180}
              onClick={() => this.handleOnDownload()}
            />
          </LogTitile>
          <Area>
            <Table>
              <THeader>
                <Cell>操作者</Cell>
                <Cell>操作内容</Cell>
                <Cell>更新内容</Cell>
                <Cell>更新日</Cell>
              </THeader>
              {answerHistories.map((answerHistory: answerHistoryModels.AnswerHistory, i: number) => (
                <InfoRow key={i}>
                  <InfoQaCell>{answerHistory.userName}</InfoQaCell>
                  <InfoCell>{answerHistory.operationName}</InfoCell>
                  <InfoContentCell>{answerHistory.updateContent}</InfoContentCell>
                  <InfoCell>{`${moment(answerHistory.createdAt).format('YYYY/MM/DD HH:mm')}`}</InfoCell>
                </InfoRow>
              ))}
            </Table>
          </Area>
        </Contents>
      </StyleBase>
    );
  }
}

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
`;

const StyleBase = styled(Base)`
  position: relative;
  overflow: scroll;
`;

const Titile = styled.div`
  height: 45px;
  display: flex;
`;

const LogTitile = styled.div`
  margin-top: 15px;
  height: 45px;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  //cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const THeader = styled(Header)`
  border-radius: 16px 16px 0px 0px;
  outline: 1px solid #e8e8e8;
`;

const InfoQaCell = styled.span`
  width: 60px;
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const InfoCell = styled.span`
  width: 150px;
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const InfoContentCell = styled.span`
  width: 1000px;
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

export const Footer = styled(Row)`
  background-color: #f5f5f5;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

const InfoCellAction = styled(InfoCell)`
  width: 15px;
`;

const Contents = styled.div``;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const SkillsIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

const AddFlagArea = styled.div`
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #f5f5f5;
  border-top: none;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 0px 0px 16px 16px;
  outline: 1px solid #e8e8e8;
`;

const EditInput = styled(Input)`
  height: 30px;
  width: 1000px;
  padding: 5px;
  border-radius: 5px;
`;

const AddFlagLabel = styled(Label)`
  color: #338cd8;
  cursor: pointer;
  font-weight: bold;
`;

const QaSelectBox = styled(SelectBox)`
  height: 30px;
  width: 50px;
`;

const DownloadButton = styled(Button)`
  justify-content: flex-end;
  height: 40px;
  margin-left: 20px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AnswerSettingAdmin);
