import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { AnswerHistory, AnswerHistoryJSON } from './models';

export const set = createAction(SET, (json: AnswerHistoryJSON[]) =>
  json.map(answer => {
    const attributes = answer.attributes;
    return {
      answerHistoryId: attributes.answer_history_id,
      operationName: attributes.operation_name,
      userName: attributes.user_name,
      updateContent: attributes.update_content,
      createdAt: attributes.created_at,
    } as AnswerHistory;
  }),
)<AnswerHistory[]>();
