import * as React from 'react';
import styled from 'styled-components';
import Box from './Box';
import { OptionInterface } from '../../utils/interface';

export type Props = {
  children?: React.ReactNode;
  theme?: 'white' | 'gray';
  name: string;
  options: OptionInterface[];
  selectedOption?: OptionInterface;
  onChanged: (name: string, value: OptionInterface | undefined) => void;
  loading?: boolean;
  disabled?: boolean;
};

const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>, props: Props) => {
  const { name, options, onChanged } = props;
  const selectedOption = options.find(option => option.value.toString() === e.target.value);
  if (typeof onChanged === 'function') {
    onChanged(name, selectedOption);
  }
};

const SelectBox: React.FC<Props> = props => {
  const { name, options, theme, loading, disabled, onChanged, ...rest } = props;
  let { selectedOption } = props;

  const initialOption = options.filter(option => option.checked);
  if (!selectedOption && options.length > 0) {
    selectedOption = initialOption.length > 0 ? initialOption[0] : options[0];
  }

  return (
    <Wrapper {...rest}>
      <Box theme={theme} label={selectedOption ? selectedOption.label : ''} loading={loading} />
      <Select
        name={name}
        disabled={disabled}
        value={selectedOption ? selectedOption.value : ''}
        onChange={e => handleOnChange(e, props)}
      >
        {props.children}
        {options.length > 0 &&
          options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
      </Select>
    </Wrapper>
  );
};

SelectBox.defaultProps = {
  loading: false,
  disabled: false,
};

export const Wrapper = styled.div`
  position: relative;
  top: 0;
  display: inline-block;
  min-width: 60px;
  height: auto;
`;

export const Label = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Select = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 60px;
  height: 100%;
  padding: 11px 30px 11px 10px;
  font-size: 1.2rem;
  background-color: inherit;
  border: none;
  opacity: 0;
  cursor: pointer;
`;

export default SelectBox;
