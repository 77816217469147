import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { User } from './models';

export type UserState = {
  user: User;
};

const initialState: UserState = {
  user: {
    userId: 0,
    operatorCode: '',
    name: '',
    kana: '',
    role: 0,
  },
};

const user = createReducer<User, Action>(initialState.user).handleType(SET, (state, action: Action) => action.payload);

export default combineReducers({ user });
