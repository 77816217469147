import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import Types from 'MyTypes';
import Responses from 'Reponses';
import { Reports } from '../../api/admin';
import Label from '../../components/atoms/Label';
import Button from '../../components/atoms/Button';
import IconArrow from '../../components/atoms/icons/IconArrow';
import Base from '../../components/molecules/Base';
//import SkillSelector from '../../components/molecules/SkillSelector';
import { businessModels } from '../../features/business';
import { skillModels } from '../../features/skill';
import { OptionGroupInterface, OptionInterface } from '../../utils/interface';
import SelectGroupBox from '../../components/atoms/SelectGroupBox';
import ReportDownloadDateRangePicker from '../../components/molecules/ReportDownloadDateRangePicker';

type Props = {
  children?: React.ReactNode;
  token: string;
  businesses: businessModels.Business[];
  skills: skillModels.OperatorSkill[];
};

type DateRangeType = {
  startAt: string | null;
  endAt: string | null;
};

type LoadingType = {
  isLoading: boolean;
};

type State = {
  isLoading?: boolean;
  skillIds: number[];
  from: Date;
  to: Date;
  fromHours: string;
  toHours: string;
  category: DateRangeType & LoadingType;
  flag: DateRangeType & LoadingType;
  item: DateRangeType & LoadingType;
  log: DateRangeType & LoadingType;
  operator: DateRangeType & LoadingType;
  bot: DateRangeType & LoadingType;
  optionGroups: OptionGroupInterface[];
  selectedOption: OptionInterface;
};

const mapStateToProps = (state: Types.RootState, ownProps: RouteComponentProps<{ operator_id: string }>) => {
  return {
    ...ownProps,
    businesses: state.business.businesses,
    skills: state.skill.operatorSkills,
  };
};

const mapDispatchToProps = {};

class ReportDownload extends React.Component<Props, State> {
  readonly state: State = {
    isLoading: true,
    skillIds: [0],
    from: new Date(),
    to: new Date(),
    fromHours: '00',
    toHours: '00',
    category: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    flag: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    item: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    log: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    operator: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    bot: {
      startAt: null,
      endAt: null,
      isLoading: true,
    },
    optionGroups: [
      {
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [this.getInitOption()],
      },
    ],
    selectedOption: this.getInitOption(),
  };
  readonly stats = {
    hoursOptions: [
      { key: 'hour_sel', label: '0', value: '0', checked: false },
      { key: 'hour_sel', label: '1', value: '1', checked: false },
      { key: 'hour_sel', label: '2', value: '2', checked: false },
      { key: 'hour_sel', label: '3', value: '3', checked: false },
      { key: 'hour_sel', label: '4', value: '4', checked: false },
      { key: 'hour_sel', label: '5', value: '5', checked: false },
      { key: 'hour_sel', label: '6', value: '6', checked: false },
      { key: 'hour_sel', label: '7', value: '7', checked: false },
      { key: 'hour_sel', label: '8', value: '8', checked: false },
      { key: 'hour_sel', label: '9', value: '9', checked: false },
      { key: 'hour_sel', label: '10', value: '10', checked: false },
      { key: 'hour_sel', label: '11', value: '11', checked: false },
      { key: 'hour_sel', label: '12', value: '12', checked: false },
      { key: 'hour_sel', label: '13', value: '13', checked: false },
      { key: 'hour_sel', label: '14', value: '14', checked: false },
      { key: 'hour_sel', label: '15', value: '15', checked: false },
      { key: 'hour_sel', label: '16', value: '16', checked: false },
      { key: 'hour_sel', label: '17', value: '17', checked: false },
      { key: 'hour_sel', label: '18', value: '18', checked: false },
      { key: 'hour_sel', label: '19', value: '19', checked: false },
      { key: 'hour_sel', label: '20', value: '20', checked: false },
      { key: 'hour_sel', label: '21', value: '21', checked: false },
      { key: 'hour_sel', label: '22', value: '22', checked: false },
      { key: 'hour_sel', label: '23', value: '23', checked: false },
    ],
  };

  getInitOption(): OptionInterface {
    return {
      key: 'opt_0',
      label: '全ての窓口',
      value: '0 0',
      checked: true,
    };
  }

  private resultsButton: HTMLButtonElement | null = null;
  private itemsButton: HTMLButtonElement | null = null;
  private logsButton: HTMLButtonElement | null = null;
  private flagsButton: HTMLButtonElement | null = null;
  private botButton: HTMLButtonElement | null = null;

  get query() {
    const { skillIds, from, to, fromHours, toHours } = this.state;
    if (skillIds[0] === 0) {
      const ids = this.props.skills.map(item => item.skillId);
      return {
        skillIds: ids,
        start: from,
        end: to,
        start_hour: Number(fromHours),
        end_hour: Number(toHours),
        cache: false,
      };
    } else {
      return {
        skillIds: skillIds[0] > 0 ? skillIds : undefined,
        start: from,
        end: to,
        start_hour: Number(fromHours),
        end_hour: Number(toHours),
        cache: false,
      };
    }
  }

  componentDidMount(): void {
    this.fetchLastExportDate();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const isStorePropsExist = this.props.skills.length > 0 && this.props.businesses.length > 0;

    if (isStorePropsExist && this.state.optionGroups.length === 1) {
      //絞り込みスキル
      const optionGroups: OptionGroupInterface[] = [];
      optionGroups.push({
        optionGroupId: 0,
        optionGroupLabel: '全ての窓口',
        options: [
          {
            label: '全ての窓口',
            value: '0 0',
            checked: true,
            key: 'opt_0',
          },
        ],
      });

      if (this.props.skills.length > 0) {
        for (let i = 0; i < this.props.businesses.length; i++) {
          const business = this.props.businesses[i];
          const options: OptionInterface[] = [];
          const skills = this.props.skills.filter(skill => skill.businessId === business.businessId);

          for (let j = 0; j < skills.length; j++) {
            options.push({
              label: skills[j].name,
              value: `${business.businessId} ${skills[j].skillId}`,
              checked: false,
              key: `opt_${skills[j].skillId}`,
            });
          }

          optionGroups.push({
            optionGroupId: this.props.businesses[i].businessId,
            optionGroupLabel: this.props.businesses[i].name,
            options: options,
          });
        }
      }
      this.setState({ optionGroups: optionGroups, isLoading: false });
    }
  }

  handleOnSkillChanged = (name: string, option?: OptionInterface): void => {
    const skillId = Number(option.value.toString().split(' ')[1]);
    const optionGroups = this.state.optionGroups.slice();
    for (let i = 0; i < optionGroups.length; i++) {
      const optionGroup = optionGroups[i];
      for (let j = 0; j < optionGroup.options.length; j++) {
        if (optionGroup.options[j].value === option.value) {
          optionGroup.options[j].checked = true;
          this.setState(
            {
              skillIds: [+skillId],
              selectedOption: optionGroup.options[j],
              optionGroups: optionGroups,
            },
            this.fetchLastExportDate,
          );
          return;
        }
      }
    }
  };

  fetchLastExportDate = () => {
    const skillIds = this.state.skillIds.length > 0 ? this.state.skillIds : undefined;
    Reports.getLastDates(skillIds).then(response => {
      if (response.status !== 200) return alert(response.data);

      const { category, flag, item, log, operator, bot } = response.data;
      this.setState({
        category: {
          startAt: category.start_at,
          endAt: category.end_at,
          isLoading: false,
        },
        flag: {
          startAt: flag.start_at,
          endAt: flag.end_at,
          isLoading: false,
        },
        item: {
          startAt: item.start_at,
          endAt: item.end_at,
          isLoading: false,
        },
        log: {
          startAt: log.start_at,
          endAt: log.end_at,
          isLoading: false,
        },
        operator: {
          startAt: operator.start_at,
          endAt: operator.end_at,
          isLoading: false,
        },
        bot: {
          startAt: bot.start_at,
          endAt: bot.end_at,
          isLoading: false,
        },
      });
    });
  };

  invalidResponse = (response: AxiosResponse): boolean => {
    return !response || response.status !== 200;
  };

  handleResponse = (reportName: string, response: AxiosResponse) => {
    if (this.invalidResponse(response)) {
      return alert(`${reportName}レポートの出力に失敗しました。\n${response['data'] ? response.data.message : ''}`);
    }

    const { filename, url } = response.data.attributes;
    this.download(url, filename);
  };

  download = (url: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    const {
      from,
      to,
      fromHours,
      toHours,
      flag,
      item,
      operator,
      log,
      bot,
      optionGroups,
      selectedOption,
      isLoading,
    } = this.state;
    const { stats } = this;

    return (
      <Base>
        <div>
          <WrapTitle>
            <StyleArrow size={16} />
            <Title size={16} text="実績ダウンロード" />
          </WrapTitle>
          <Area>
            <div>
              <SkillArea>
                <WrapSubTitle>
                  <SubTitle text={'窓口'} />
                </WrapSubTitle>
                <SelectGroupBox
                  name={'skills'}
                  optionGroups={optionGroups}
                  selectedOption={selectedOption}
                  loading={isLoading}
                  disabled={isLoading}
                  onChanged={this.handleOnSkillChanged}
                />
              </SkillArea>
            </div>
            <div>
              <DateArea>
                <WrapSubTitle>
                  <SubTitle text={'ダウンロード期間'} />
                </WrapSubTitle>
                <div>
                  <ReportDownloadDateRangePicker
                    from={from}
                    to={to}
                    fromHours={fromHours}
                    toHours={toHours}
                    options={stats.hoursOptions}
                    onDayChange={(from, to) => {
                      if (from) this.setState({ from });
                      if (to) this.setState({ to });
                    }}
                    onHoursChange={(fromHours, toHours) => {
                      if (fromHours) this.setState({ fromHours });
                      if (toHours) this.setState({ toHours });
                    }}
                  />
                </div>
              </DateArea>
            </div>
            <Separator />
            <div>
              <WrapSubSubTitle>
                <SubSubTitle text={'実績別のダウンロード'} />
                <DownloadBulkButtonArea>
                  <Button
                    type="button"
                    label={'全てのCSVを一括ダウンロード'}
                    kind={'primary'}
                    width={250}
                    disabled={operator.isLoading || item.isLoading || log.isLoading || flag.isLoading}
                    onClick={() => {
                      if (this.resultsButton) this.resultsButton.click();
                      if (this.itemsButton) this.itemsButton.click();
                      if (this.logsButton) this.logsButton.click();
                      if (this.flagsButton) this.flagsButton.click();
                      if (this.botButton) this.botButton.click();
                    }}
                  />
                </DownloadBulkButtonArea>
              </WrapSubSubTitle>
              <Card
                buttonRef={el => (this.resultsButton = el)}
                title={'各種実績'}
                description={
                  'LW利用実績（利用数、AHT、対応時間、カテゴリ、解決率、評価）WG別、OP別、時間帯別、日別、月別'
                }
                from={operator.startAt}
                to={operator.endAt}
                disabled={operator.isLoading}
                onClick={() => {
                  this.setState({ operator: { ...operator, isLoading: true } });
                  Reports.getResults(this.query)
                    .then(response => {
                      if (this.invalidResponse(response)) {
                        return alert(
                          `レポートの出力に失敗しました。\n${response['data'] ? response.data.message : ''}`,
                        );
                      }

                      response.data.forEach(async (data: Responses.Report) => {
                        const { filename, url } = data.attributes;
                        this.download(url, filename);
                      });
                    })
                    .then(() => this.setState({ operator: { ...operator, isLoading: false } }));
                }}
              />
              <Card
                buttonRef={el => (this.itemsButton = el)}
                title={'案件別の実績'}
                description={'案件別の実績（対応時間等）'}
                from={item.startAt}
                to={item.endAt}
                disabled={item.isLoading}
                onClick={() => {
                  this.setState({ item: { ...item, isLoading: true } });
                  Reports.getItems(this.query)
                    .then(res => this.handleResponse('案件別の実績', res))
                    .then(() => this.setState({ item: { ...item, isLoading: false } }));
                }}
              />
              <Card
                buttonRef={el => (this.logsButton = el)}
                title={'吹き出しログ'}
                description={'クルーとオペレーターが発話した時の吹き出しログ'}
                from={log.startAt}
                to={log.endAt}
                disabled={log.isLoading}
                onClick={() => {
                  this.setState({ log: { ...log, isLoading: true } });
                  Reports.getLogs(this.query)
                    .then(res => this.handleResponse('吹き出しログ', res))
                    .then(() => this.setState({ log: { ...log, isLoading: false } }));
                }}
              />
              <Card
                buttonRef={el => (this.flagsButton = el)}
                title={'フラグの実績'}
                description={'窓口別のフラグの実績'}
                from={flag.startAt}
                to={flag.endAt}
                disabled={flag.isLoading}
                onClick={() => {
                  this.setState({ flag: { ...flag, isLoading: true } });
                  Reports.getFlags(this.query)
                    .then(res => this.handleResponse('フラグの実績', res))
                    .then(() => this.setState({ flag: { ...flag, isLoading: false } }));
                }}
              />
              <Card
                buttonRef={el => (this.botButton = el)}
                title={'AI-Botの実績'}
                description={'AI-Botの回答実績'}
                from={bot.startAt}
                to={bot.endAt}
                disabled={bot.isLoading}
                onClick={() => {
                  this.setState({ bot: { ...bot, isLoading: true } });
                  Reports.getBot(this.query)
                    .then(res => this.handleResponse('AI-Botの実績', res))
                    .then(() => this.setState({ bot: { ...bot, isLoading: false } }));
                }}
              />
            </div>
          </Area>
        </div>
      </Base>
    );
  }
}

type CardProps = {
  buttonRef?: (button: HTMLButtonElement) => void;
  title: string;
  description: string;
  from: string | null;
  to: string | null;
  disabled: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

const Card: React.FC<CardProps> = props => {
  const { title, description, buttonRef, from, to, disabled, onClick } = props;
  return (
    <DownloadArea>
      <DownloadTitleArea>
        <DownloadTitle text={title} />
      </DownloadTitleArea>
      <DownloadContentsArea>
        <div>
          <DownloadContentsTitle text={'ダウンロード内容'} size={12} />
        </div>
        <div>
          <Label text={description} />
        </div>
      </DownloadContentsArea>
      <DownloadButtonArea>
        <Button
          ref={buttonRef}
          type="button"
          label={'CSVダウンロード'}
          kind={'primary'}
          width={180}
          disabled={disabled}
          onClick={onClick}
        />
      </DownloadButtonArea>
      <div>
        <span>前回のダウンロード期間：</span>
        <span>{from && to ? `${from} ～ ${to}` : 'なし'}</span>
      </div>
    </DownloadArea>
  );
};

const WrapTitle = styled.div`
  margin-bottom: 20px;
`;

const WrapSubTitle = styled.div`
  margin-bottom: 20px;
`;

const WrapSubSubTitle = styled.div`
  margin-bottom: 50px;
`;

const Title = styled(Label)`
  font-weight: bold;
`;

const SubTitle = styled(Label)`
  color: #338cd8;
`;

const SubSubTitle = styled(Label)`
  font-weight: bold;
`;

const DownloadTitle = styled(Label)`
  font-weight: bold;
  color: #338cd8;
`;

const DownloadContentsTitle = styled(Label)`
  font-weight: bold;
  color: #b0b0b0;
`;

const Area = styled.div`
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 40px;

  height: auto;
  overflow: auto;
`;

const SkillArea = styled.div`
  padding: 10px 10px 20px 10px;
  height: 150px;
  float: left;
  width: 40%;
`;

const DateArea = styled.div`
  padding: 10px 10px 20px 10px;
  height: 150px;
  float: right;
  width: 55%;
`;

const DownloadArea = styled.div`
  float: left;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  padding: 20px;
  height: 310px;
  margin: 10px 10px 10px 0;
  width: 30%;
`;

const DownloadBulkButtonArea = styled.div`
  float: right;
`;

const DownloadContentsArea = styled.div`
  height: 120px;
  padding: 5px;
`;

const DownloadButtonArea = styled.div`
  text-align: center;
  margin: 20px 0 15px 0;
`;

const DownloadTitleArea = styled.div`
  margin-bottom: 10px;
`;

const Separator = styled.div`
  clear: both;
  margin: 20px 0 20px 0;
  border-top: solid 1px #f5f5f5;
`;

const StyleArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ReportDownload);
