import axios from './httpClient';
import { ADMIN_API } from '../config';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
};

export const MorphologicalAnalysis = {
  post: (data: FormData) => {
    return axios
      .post(`morphologicalanalyses`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(error => error.response);
  },
  getDownloadUrl: (fileId: number) => {
    return `${ADMIN_API.apiUrl}/${ADMIN_API.apiVer}/morphologicalanalyses/${fileId}/download`;
  },

  clear: (fileId: number) => {
    return axios.delete(`morphologicalanalyses/${fileId}`, {}).catch(error => error.response);
  },
};
