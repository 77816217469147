import * as React from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  value: string;
  text: string;
  checked?: true | false;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton: React.FC<Props> = props => {
  const { text, ...rest } = props;
  return (
    <Label>
      <Input type="radio" {...rest} />
      {props.text}
    </Label>
  );
};

const Label = styled.label`
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  overflow: hidden;
  padding-left: 20px;
  display: inline-block;
  :before {
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #0783f4;
    border-radius: 50%;
    left: 0;
    top: 4px;
    content: '';
    z-index: 3;
  }
  :after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    left: 3px;
    top: 7px;
    background-color: #0783f4;
    z-index: 1;
  }
`;

const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  z-index: 2;
  width: 20px;
  height: 20px;
  left: -23px;
  top: 1px;
  margin: 0;
  box-shadow: 20px -1px #fff;
  :checked {
    box-shadow: none;
  }
  :focus {
    opacity: 0.2;
    box-shadow: 20px -1px #fff;
  }
`;

export default RadioButton;
