import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Types from 'MyTypes';
import { flagActions, flagModels } from '../../features/flag';
import Base from '../../components/molecules/Base';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
import IconArrow from '../../components/atoms/icons/IconArrow';
import IconCheck2 from '../../components/atoms/icons/IconCheck2';
import IconEdit from '../../components/atoms/icons/IconEdit';
import IconDelete from '../../components/atoms/icons/IconDelete';
import Table, { Cell, Header } from '../../components/atoms/TableScroll';
import { Flags as FlagAPI } from '../../api/admin';
import ScreenLoading from '../../components/atoms/ScreenLoading';

type Props = {
  children?: React.ReactNode;
  flags: flagModels.Flag[];
  setFlags: (flags: flagModels.FlagJson) => {};
  //add: (index: number) => {};
  //checkFlag: (index: number) => {};
  //checkAllFlags: () => {};
  //editFlag: (index: number) => {};
  //deleteFlag: (index: number) => {};
};

enum ExeType {
  MODE_DEFAULT,
  MODE_ADD,
  MODE_EDIT,
}

type State = {
  flags: flagModels.Flag[];
  isLoading: boolean;
  isEditMode: ExeType;
  editIndex: number;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    flags: state.flag.flags,
  };
};

const mapDispatchToProps = {
  setFlags: flagActions.set,
  //checkFlag: flagActions.check,
  //checkAllFlags: flagActions.checkAll,
  //editFlag: flagActions.editFlag,
  //deleteFlag: flagActions.deleteFlag,
};

class FlagsAdmin extends React.Component<Props, State> {
  readonly state: State = {
    flags: [],
    isLoading: true,
    isEditMode: ExeType.MODE_DEFAULT,
    editIndex: 0,
  };

  componentDidMount(): void {
    this.fetchFlags();
  }

  fetchFlags(option = { all: true }): void {
    this.setState({ isLoading: true });

    FlagAPI.getAll(option)
      .then(response => {
        if (response.status === 200) {
          this.props.setFlags(response.data);
        } else {
          alert(response.data.message);
        }
      })
      .then(() => {
        const dispFlags = this.props.flags.filter(flag => flag.visible === true);
        this.setState({ flags: dispFlags, isLoading: false });
      });
  }

  handleEvent = (eventName: string, index: number): (() => void) => {
    if (this.state.editIndex !== 0 && this.state.editIndex !== index) return;

    const copyFlags = this.state.flags.slice();
    switch (eventName) {
      case 'add': {
        const newFlag: flagModels.Flag = {
          flagId: 0,
          name: '',
          isChecked: false,
          isAdding: true,
          isEditing: true,
          isDeleting: false,
          visible: true,
          createdAt: '',
          updatedAt: '',
        };
        copyFlags.push(newFlag);
        this.setState({
          isEditMode: ExeType.MODE_ADD,
          flags: copyFlags,
          editIndex: index,
        });
        break;
      }
      case 'edit': {
        copyFlags[index].isEditing = true;
        this.setState({
          isEditMode: ExeType.MODE_EDIT,
          flags: copyFlags,
          editIndex: index,
        });
        break;
      }
      case 'commit': {
        const flagId = copyFlags[index].flagId;
        const name = copyFlags[index].name;
        if (name.length === 0) {
          alert('フラグ名が未入力です');
          return;
        }

        this.setState({ isLoading: true });
        copyFlags[index].isEditing = false;
        if (this.state.isEditMode === ExeType.MODE_EDIT) {
          FlagAPI.edit({ flag_id: flagId, name: name }).then(response => {
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              flags: copyFlags,
              editIndex: 0,
            });

            if (response.status === 204) {
              this.setState({ isLoading: false });
              alert('更新が完了しました');
            } else {
              //再取得
              this.fetchFlags();
              this.setState({ isLoading: false });
              alert(response.data.message);
            }
            return;
          });
        } else if (this.state.isEditMode === ExeType.MODE_ADD) {
          FlagAPI.add({ name: name }).then(response => {
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              editIndex: 0,
            });

            if (response.status === 200) {
              this.setState({
                flags: copyFlags,
              });

              //再取得
              this.fetchFlags();
              this.setState({ isLoading: false });
              alert('登録が完了しました');
            } else {
              copyFlags.pop();
              this.setState({
                flags: copyFlags,
              });
              this.setState({ isLoading: false });
              alert(response.data.message);
            }
            return;
          });
        }
        break;
      }
      case 'delete': {
        //追加モード時の削除はAPI通信不要
        if (this.state.isEditMode === ExeType.MODE_ADD) {
          copyFlags.pop();
          this.setState({
            isEditMode: ExeType.MODE_DEFAULT,
            flags: copyFlags,
            editIndex: 0,
          });
          return;
        }

        const flagId = copyFlags[index].flagId;

        FlagAPI.delete({ flag_id: flagId }).then(response => {
          if (response.status === 204) {
            copyFlags.splice(index, 1);
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              flags: copyFlags,
              editIndex: 0,
            });
            //再取得
            this.fetchFlags();
            alert('削除が完了しました');
          } else {
            this.setState({
              isEditMode: ExeType.MODE_DEFAULT,
              editIndex: 0,
            });
            alert(response.data.message.name);
          }
        });
        break;
      }
      default: {
        return () => console.log('default');
      }
    }
  };

  handleChangeFlagName = (flagName: string, index: number): (() => void) => {
    const copyFlags = this.state.flags.slice();
    copyFlags[index].name = flagName;
    this.setState({
      flags: copyFlags,
    });

    return;
  };

  render() {
    const { flags, isLoading, isEditMode } = this.state;
    //const {} = this.props;

    return (
      <StyleBase>
        <ScreenLoading width={'150px'} height={'150px'} type={'spin'} color={'#black'} isloading={isLoading} />
        <Contents>
          <Titile>
            <SkillsIconArrow size={16} />
            <TitleLabel size={16} text="フラグ管理" />
          </Titile>
          <Area>
            <Table>
              <THeader>
                <Cell>フラグ名</Cell>
                <Cell />
                <Cell />
              </THeader>
              {flags.map((flag: flagModels.Flag, i: number) => (
                <InfoRow key={i}>
                  {!flag.isEditing ? (
                    <InfoCell>{flag.name}</InfoCell>
                  ) : (
                    <InfoCell>
                      <EditInput
                        type="text"
                        theme={'gray'}
                        value={flag.name}
                        placeholder={'フラグ名を入力してください'}
                        name={'flagName'}
                        key={`flagNaume${i}`}
                        onChange={e => this.handleChangeFlagName(e.target.value, i)}
                      />
                    </InfoCell>
                  )}
                  <InfoCellAction>
                    {!flag.isEditing ? (
                      <IconEdit
                        size={12}
                        onClick={() => this.handleEvent('edit', i)}
                        disabled={isEditMode !== ExeType.MODE_DEFAULT}
                      />
                    ) : (
                      <IconCheck2 size={12} fill={'#338ed8'} onClick={() => this.handleEvent('commit', i)} />
                    )}
                  </InfoCellAction>
                  <InfoCellAction>
                    <IconDelete
                      size={12}
                      onClick={() => this.handleEvent('delete', i)}
                      disabled={isEditMode !== ExeType.MODE_DEFAULT && !flag.isEditing}
                    />
                  </InfoCellAction>
                </InfoRow>
              ))}
            </Table>
            {!isLoading && (
              <AddFlagArea>
                <AddFlagLabel text="＋フラグを登録" size={14} onClick={() => this.handleEvent('add', flags.length)} />
              </AddFlagArea>
            )}
          </Area>
        </Contents>
      </StyleBase>
    );
  }
}

const Area = styled.div`
  border-radius: 16px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
`;

const StyleBase = styled(Base)`
  position: relative;
  overflow: scroll;
`;

const Titile = styled.div`
  height: 45px;
`;

const InfoRow = styled.div`
  display: table-row;
  width: 100%;
  //cursor: pointer;

  :nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const THeader = styled(Header)`
  border-radius: 16px 16px 0px 0px;
  outline: 1px solid #e8e8e8;
`;

const InfoCell = styled.span`
  display: table-cell;
  padding: 10px 20px 9px;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
`;

const InfoCellAction = styled(InfoCell)`
  width: 15px;
`;

const Contents = styled.div``;

const TitleLabel = styled(Label)`
  font-weight: bold;
`;

const SkillsIconArrow = styled(IconArrow)`
  stroke: #000000;
  margin-right: 12px;
  cursor: default;
`;

const AddFlagArea = styled.div`
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #f5f5f5;
  border-top: none;
  background-color: #ffffff;
  padding: 15px;
`;

const EditInput = styled(Input)`
  height: 30px;
  padding: 5px;
  border-radius: 5px;
`;

const AddFlagLabel = styled(Label)`
  color: #338cd8;
  cursor: pointer;
  font-weight: bold;
`;

export default connect(mapStateToProps, mapDispatchToProps)(FlagsAdmin);
