import * as React from 'react';
import styled from 'styled-components';

type PopupProps = {
  children: React.ReactNode;
  isPopup: boolean;
};

class PopupFrame extends React.Component<PopupProps, {}> {
  render() {
    const { isPopup, children } = this.props;

    if (!isPopup) {
      return <div />;
    }
    return (
      <React.Fragment>
        <Wrapper>
          <Inner>{children}</Inner>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98;

  height: auto;
  overflow: scroll;
`;

const Inner = styled.div`
  border-radius: 16px;
  height: 100%;
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  //bottom: 10%;
  //margin: auto;
  margin-bottom: 5%;
  background: white;
  z-index: 99;

  height: auto;
  overflow: auto;
`;

export default PopupFrame;
