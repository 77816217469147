import { createAction } from 'typesafe-actions';
import { SET } from './constants';
import { UserJson, User } from './models';

const decode = (json: UserJson) => {
  const user = json.attributes;
  return {
    userId: +user.user_id,
    operatorCode: user.operator_code,
    name: user.name,
    kana: user.kana,
    role: user.role,
  } as User;
};

export const set = createAction(SET, (user: UserJson) => decode(user))<User>();
