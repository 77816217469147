export const REDIRECT_SSO = `${process.env.REACT_APP_TARTE_URL}?client_id=${
  process.env.REACT_APP_TARTE_CLIENT_ID
}&redirect_uri=${
  process.env.REACT_APP_TARTE_REDIRECT_URI ? encodeURIComponent(process.env.REACT_APP_TARTE_REDIRECT_URI) : ''
}&response_type=code&scope=openid%20ipPhone%20sAMAccountName%20displayName%20distinguishedName%20extensionAttribute8%20departmentNumber`;

export const ERROR_MESSAGE = {
  signin: {
    title: 'ログインに失敗しました',
    description: 'ログインに失敗しました。再ログインをお願いします。',
  },
  socket: {
    title: 'ネットワークが切れました',
    description: 'ネットワークとの接続がきれたため、ブラウザの更新をお願いします。',
  },
  http: {
    title: 'システムエラー',
    description: 'システムによる障害が発生しました。<br />システム管理者にお問い合わせください。',
  },
  upload: {
    title: 'アップロードエラー',
    description:
      'ファイルのアップロードに失敗しました。<br />もう一度行っても失敗する場合はシステム管理者にお問い合わせください。',
  },
  authentication: {
    title: '再度ログインをお願いします',
    description: '一定時間の操作がなかったためもう一度ログインをお願いします。',
  },
  notuse: {
    title: '編集権限がありません',
    description: 'この案件の担当でないため編集することができません。',
  },
  confirmBeforeTransfer: {
    title: '転送ができません',
    description: '終了前確認を行わないと転送ができません。',
  },
};

export const OPERATOR_ROLE = {
  systemAdmin: 1,
  operatorAdmin: 2,
  general: 3,
};
