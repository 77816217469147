import axios from './httpClient';

type Query = {
  skillIds?: number[];
  start: Date;
  end: Date;
  start_hour: number;
  end_hour: number;
  cache?: boolean;
};

type QaQuery = {
  skill_ids: number[];
  start: Date;
  end: Date;
  start_hour: number;
  end_hour: number;
  keywords: string[];
  room_id: string;
  user_ids: number[];
  is_suggest: boolean;
};

const config = (query: Query) => {
  const { skillIds, ...rest } = query;
  if (skillIds) {
    return {
      params: {
        ...rest,
        skill_ids: skillIds,
      },
    };
  }

  return {
    params: {
      ...rest,
    },
  };
};

const qaConfig = (query: QaQuery) => {
  const { ...rest } = query;
  return {
    params: {
      ...rest,
    },
  };
};

export const Reports = {
  getLastDates: (skillIds?: number[]) =>
    axios.get('reports', { params: { skill_id: skillIds } }).catch(error => error.response),
  getResults: (query: Query) => axios.get('reports/results', config(query)).catch(error => error.response),
  getItems: (query: Query) => axios.get('reports/items', config(query)).catch(error => error.response),
  getLogs: (query: Query) => axios.get('reports/logs', config(query)).catch(error => error.response),
  getFlags: (query: Query) => axios.get('reports/flags', config(query)).catch(error => error.response),
  getBot: (query: Query) => axios.get('reports/bot', config(query)).catch(error => error.response),
  fetchQa: (query: QaQuery) => axios.get('reports/qa', qaConfig(query)).catch(error => error.response),
  fetchAnswerHistories: () => axios.get('reports/answer_histories').catch(error => error.response),
};
