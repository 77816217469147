import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import Types from 'MyTypes';
import { authActions } from './features/auth';
import { userActions, userModels } from './features/user';
import { skillActions, skillModels } from './features/skill';
import { businessModels, businessActions } from './features/business';
import { Auth, User, Skills, Businesses } from './api/admin';
import SideMenu from './containers/organisms/SideMenu';
import Operators from './containers/pages/Operators';
import OperatorEdit from './containers/pages/OperatorEdit';
import ShopUseLimit from './containers/pages/ShopUseLimit';
import MorphologicalAnalysis from './containers/pages/MorphologicalAnalysis';
import SkillsAdmin from './containers/pages/SkillsAdmin';
import SkillsDetail from './containers/pages/SkillsDetail';
import SkillsEdit from './containers/pages/SkillsEdit';
import FlagsAdmin from './containers/pages/FlagsAdmin';
import UtteranceAdmin from './containers/pages/UtteranceAdmin';
import ReportDownload from './containers/pages/ReportDownload';
import BlackListAdmin from './containers/pages/BlackListAdmin';
import NotificationsAdmin from './containers/pages/NotificationsAdmin';
import { REDIRECT_SSO } from './utils/constants';
import { getParam } from './api/utils';
import { OPERATOR_ROLE } from './utils/constants';
import QaAdmin from './containers/pages/QaAdmin';
import AnswerSettingAdmin from './containers/pages/AnswerSettingAdmin';

type Props = {
  token: string;
  user: userModels.User;
  setUrlToken: (token: string) => void;
  setToken: (response: AxiosResponse) => void;
  setUser: (json: userModels.UserJson) => void;
  setSkills: (json: skillModels.SkillJSON[]) => void;
  setOperatorSkills: (json: skillModels.OperatorSkillJSON[]) => void;
  setBusinesses: (json: businessModels.BusinessJSON) => void;
};

type State = {
  menuIndex: number;
  role: number;
  errorMessage: string;
};

const mapStateToProps = (state: Types.RootState, ownProps: Props) => {
  return {
    ...ownProps,
    ...state.user,
    token: state.auth.token,
    skills: state.skill.skills,
    operatorSkills: state.skill.operatorSkills,
    businesses: state.business.businesses,
  };
};

const mapDispatchToProps = {
  setUrlToken: authActions.set,
  setToken: authActions.update,
  setUser: userActions.set,
  setSkills: skillActions.set,
  setOperatorSkills: skillActions.opSet,
  setBusinesses: businessActions.set,
};

class App extends React.Component<Props, State> {
  readonly state: State = {
    menuIndex: -1,
    role: -1,
    errorMessage: '',
  };

  constructor(props: Props) {
    super(props);
    this.setMenuIndex = this.setMenuIndex.bind(this);

    if (!this.validateSettings()) {
      return;
    }
    this.init();
  }

  validateSettings(): boolean {
    if (process.env.REACT_APP_BUILD_ENV === undefined) {
      alert('環境変数 "REACT_APP_BUILD_ENV" がセットされていません');
      return false;
    }

    if (process.env.REACT_APP_BUILD_ENV === 'production') {
      if (process.env.REACT_APP_TARTE_CLIENT_ID === undefined) {
        alert('環境変数 "REACT_APP_TARTE_CLIENT_ID" がセットされていません');
        return false;
      }
      if (process.env.REACT_APP_TARTE_REDIRECT_URI === undefined) {
        alert('環境変数 "REACT_APP_TARTE_REDIRECT_URI" がセットされていません');
        return false;
      }
      if (process.env.REACT_APP_TARTE_URL === undefined) {
        alert('環境変数 "REACT_APP_TARTE_URL" がセットされていません');
        return false;
      }
    }
    return true;
  }

  init(): void {
    //URLからJWTを取得
    const urlToken = getParam('token');
    if (urlToken) {
      this.props.setUrlToken(urlToken);
      return;
    }

    //環境を取得
    const environment = process.env.REACT_APP_BUILD_ENV;
    console.log(`environment: ${environment}`);
    if (environment === 'production') {
      //[prod]
      if (!urlToken) {
        console.log('notfound token in url');
        const errorParam = getParam('error');
        if (errorParam) {
          this.loginError(500);
          return;
        } else {
          window.location.href = REDIRECT_SSO;
        }
      }
    } else {
      //[dev, stg]
      //オペレーターコード取得
      let operatorCode = getParam('operator_code');
      if (!operatorCode) {
        console.log('notfound operator_code in url');
        operatorCode = process.env.REACT_APP_OPERATOR_CODE;
        if (!operatorCode) {
          this.loginError(500);
          alert('operator_codeが見つかりません');
          return;
        }
      }

      //ログイン
      Auth.getToken(operatorCode)
        .then(response => {
          if (response.status === 200) {
            this.props.setToken(response);
          } else {
            this.loginError(response.status);
          }
        })
        .catch(error => {
          const responset = error.response;
          this.loginError(responset.status);
          return;
        });
    }
  }

  loginError(status: number): void {
    if (status === 403) {
      this.setState({ errorMessage: 'アクセス権がありません' });
    } else {
      this.setState({ errorMessage: 'ログインに失敗しました' });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // TODO: 現在ログインしているユーザー情報が取得できるようになるまでローディング画面表示？
    const { props } = this;
    if (prevProps.token === '' && prevProps.token !== props.token) {
      //ログインユーザ取得
      User.get()
        .then(response => {
          props.setUser(response.data);
          return true;
        })
        .then(bool => {
          Skills.getAll({ all: true, operator_id: this.props.user.userId }).then(response =>
            props.setOperatorSkills(response.data),
          );
        })
        .catch(error => {
          const response = error.response;
          const environment = process.env.REACT_APP_BUILD_ENV;
          if (environment === 'production') {
            //[prod]
            if (response.status === 401) {
              const errorParam = getParam('error');
              if (errorParam) {
                this.loginError(response.status);
                return;
              } else {
                window.location.href = REDIRECT_SSO;
              }
            } else {
              this.loginError(response.status);
              return;
            }
          } else {
            //[dev, stg]
            alert('エラーが発生しました');
            return;
          }
        });

      Skills.getAll({ all: true, order: 'skills.order;business_id;skill_id' }).then(response =>
        props.setSkills(response.data),
      );
      Businesses.getAll().then(response => props.setBusinesses(response.data));
    }
  }

  setMenuIndex(menuIndex: number) {
    this.setState({ menuIndex: menuIndex });
  }

  render() {
    return (
      <Router>
        {this.props.token === '' ? (
          <RejectWrapper>{this.state.errorMessage}</RejectWrapper>
        ) : (
          <Wrapper>
            <SideMenu
              name={this.props.user.name}
              menuIndex={this.state.menuIndex}
              visible={Number(this.props.user.role) === OPERATOR_ROLE.systemAdmin}
              onClick={this.setMenuIndex}
            />
            <Main>
              <Route path="/operator" component={Operators} />
              <Route path="/operator_edit/:operator_id" component={OperatorEdit} />
              <Route path="/skills_admin" component={SkillsAdmin} />
              <Route path="/skills_detail/:skill_id" component={SkillsDetail} />
              <Route path="/skills_create/" component={SkillsEdit} />
              <Route path="/skills_edit/:skill_id" component={SkillsEdit} />
              <Route path="/flags_admin" component={FlagsAdmin} />
              <Route path="/shop_use_limit" component={ShopUseLimit} />
              <Route path="/morphological_analysis" component={MorphologicalAnalysis} />
              <Route path="/utterances_admin" component={UtteranceAdmin} />
              <Route path="/reports_download" component={ReportDownload} />
              <Route path="/blacklists_admin" component={BlackListAdmin} />
              <Route path="/notifications_admin" component={NotificationsAdmin} />
              <Route path="/qa_admin" component={QaAdmin} />
              <Route path="/answer_setting" component={AnswerSettingAdmin} />
            </Main>
          </Wrapper>
        )}
      </Router>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const RejectWrapper = styled.div`
  padding: 30px;
`;

const Main = styled.main`
  width: calc(100% - 190px);
  height: 100vh;
  overflow-y: auto;
  background-color: #f5f6f7;
`;

export default connect(mapStateToProps, mapDispatchToProps)(App);
