import axios from './httpClient';
import { PostNotificationParam } from 'features/notification/models';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
  skill_ids?: number[];
};

export const Notifications = {
  getAll: (option: Option = { all: true }) =>
    axios.get('notifications', { params: option }).catch(error => error.response),
  add: (argParams: PostNotificationParam) => axios.post('notifications', argParams).catch(error => error.response),
  delete: (notificationId: number) =>
    axios.delete(`notifications/${notificationId}`, {}).catch(error => error.response),
};
