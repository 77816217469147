export const ADMIN_API = {
  apiVer: 'v1',
  apiUrl: process.env.REACT_APP_BASE_URL,
};

export const CHAT_API = {
  apiVer: 'v1',
  apiPath: 'api',
  apiUrl: process.env.REACT_APP_CHAT_HOST || 'dev-go.sapocha-sys.info',
};
