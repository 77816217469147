import styled from 'styled-components';

const IconClose = styled.span`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  :before,
  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 18px;
    height: 1px;
    margin-top: -1px;
    background-color: #0b0b0b;
  }
  :before {
    transform: rotate(135deg);
  }
  :after {
    transform: rotate(45deg);
  }
`;

export default IconClose;
