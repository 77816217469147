import { AxiosInstance } from 'axios';
import store from '../store';
import { authActions } from '../features/auth';
import { ERROR_MESSAGE } from '../utils/constants';

export const getParam = (name: string, url?: string) => {
  const targetUrl = url ? url : window.location.href;
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(targetUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const configure = (axios: AxiosInstance) => {
  axios.interceptors.request.use(config => {
    const token = store.getState().auth.token;
    if (token && token.length > 1) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    response => {
      if (process.env.NODE_ENV === 'development') {
        console.log(response);
      }

      if (response.headers['authorization']) {
        const action = authActions.update(response);
        store.dispatch(action);
      }

      return response;
    },
    error => {
      if (error.message === 'Network Error') {
        const { response } = error;
        if (process.env.NODE_ENV === 'development') {
          console.log(response);
        }

        if (response && response.status === 400 && response.headers['authorization']) {
          const action = authActions.update(response);
          store.dispatch(action);
        }

        if (response && response.status === 401 && response.headers['authorization']) {
          console.log(response);
          alert(`${ERROR_MESSAGE.authentication.title}: ${ERROR_MESSAGE.authentication.description}`);

          return Promise.reject({
            response: {
              error,
              status: response.status,
            },
          });
        }

        if (response && response.status === 403) {
          console.log(response);
          return Promise.reject({
            response: {
              error,
              status: response.status,
            },
          });
        }

        return Promise.reject({
          response: {
            error,
            status: 500,
          },
        });
      }
      return Promise.reject(error);
    },
  );
};
