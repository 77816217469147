import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from 'MyTypes';
import { SET } from './constants';
import { Tag } from './models';

export type TagState = {
  tags: Tag[];
};

const initialState: TagState = {
  tags: [],
};

const tags = createReducer<Tag[], Action>(initialState.tags).handleType(SET, (state, action: Action) => action.payload);
export default combineReducers({ tags });
