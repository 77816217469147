import axios from './httpClient';
import { PostTagParam, PutTagParam } from 'features/tag/models';

type Option = {
  all?: boolean;
  page?: number;
  limit?: number;
  order?: string;
  skill_id: number[];
};

export const Tags = {
  getAll: (skillId: number) => axios.get('tags', { params: { skill_id: skillId } }).catch(error => error.response),
  add: (argParams: PostTagParam) => axios.post('tags', argParams).catch(error => error.response),
  edit: (argParams: PutTagParam) => axios.put('tags', argParams, {}).catch(error => error.response),
  delete: (tagId: number) => axios.delete(`tags/${tagId}`, {}).catch(error => error.response),
};
