import React from 'react';
import styled from 'styled-components';
import IconSoftbank from '../../components/atoms/icons/IconSoftbank';

const SideMenu: React.FC = () => {
  return (
    <H1>
      <IconSoftbank size={120} fill={'#ffffff'} />
      <Title>窓口管理システム</Title>
    </H1>
  );
};

const H1 = styled.h1`
  width: auto;
  display: inline-block;
`;

const Title = styled.span`
  display: block;
  margin-top: 12px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.15rem;
`;

export default SideMenu;
