import React from 'react';
import styled from 'styled-components';
import { theme } from '../../Theme';

interface ModalIF {
  isShow: boolean;
}

const StyledModalWindow = styled.div`
  ${(props: ModalIF) => (props.isShow ? `visibility: visible;` : `visibility: hidden;`)}
  z-index: 1000;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: -100vh;
  left: -100vw;
  bottom: -100vh;
  right: -100vw;
  z-index: 1;
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 360px;
  max-width: 480px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  transform: translate(-50%, -50%);
  z-index: 2;
  overflow: hidden;
`;

const Title = styled.h3`
  padding: 10px 20px;
  color: #ffffff;
  background-color: ${theme.primaryColor};
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
  border-top: 1px solid #f0f0f0;
`;

const Description = styled.p`
  color: #000000;
  font-size: 1.4rem;
`;

const ModalWindow = ({ title, text, isShow, children }) => {
  return (
    <StyledModalWindow isShow={isShow}>
      <Overlay />
      <Container>
        <Title>{title}</Title>
        <Content>
          <Description>{text}</Description>
          {children}
        </Content>
      </Container>
    </StyledModalWindow>
  );
};

export default ModalWindow;
