import axios from './httpClient';

type PeriodParam = {
  period_id: number;
  skill_id: number;
  period_start: string;
  period_end: string;
  business_hours_start: string;
  business_hours_end: string;
};

export const Periods = {
  getAll: (skillId: number) => axios.get('periods', { params: { skill_id: skillId } }).catch(error => error.response),
  add: (argParams: PeriodParam) => axios.post('periods', argParams).catch(error => error.response),
  edit: (argParams: PeriodParam) => axios.put('periods', argParams, {}).catch(error => error.response),
  delete: (periodId: number) => axios.delete(`periods/${periodId}`, {}).catch(error => error.response),
};
