import axios from './httpClient';

type QaHistoryParam = {
  file_format: number;
  user_id: number;
  skill_id: number;
  file_name: string;
};

export const QaHistory = {
  getAll: (limit: number | null) =>
    axios.get('qa/history', { params: { limit: limit } }).catch(error => error.response),
  add: (argParams: QaHistoryParam) => axios.post('qa/history', argParams).catch(error => error.response),
};
