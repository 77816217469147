import { createAction } from 'typesafe-actions';
import { SET, SET_BIZ, CHECK, CHECK_ALL, TOGGLE, CHECK_ALL_SKILLS } from './constants';
import { Business, Skill, NotificationJSON, Notification } from './models';
import { businessModels } from '../business';
import { skillModels } from '../skill';
import moment from 'moment';

export const set = createAction(SET, (json: NotificationJSON[], skills: skillModels.Skill[]) =>
  json.map(notification => {
    const attributes = notification.attributes;
    return {
      notificationId: attributes.notification_id,
      title: attributes.title,
      type: attributes.type,
      openUserNumber: attributes.open_user_number,
      targetUserNumber: attributes.target_user_number,
      openRate: attributes.open_rate,
      unreadCount: attributes.unread_count,
      createdAt: attributes.created_at === undefined ? '' : moment(attributes.created_at).format('YYYY/MM/DD'),
      updatedAt: attributes.updated_at === undefined ? '' : moment(attributes.updated_at).format('YYYY/MM/DD'),
      skills: [],
      body: attributes.body,
      is_deleted: false,
    } as Notification;
  }),
)<Notification[]>();

export const setBiz = createAction(SET_BIZ, (businesses: businessModels.Business[], skills: skillModels.Skill[]) => {
  const businesseTable: Business[] = businesses.map(business => {
    const businessId = business.businessId;
    const businessName = business.name;
    const businessSkills = skills.filter(skill => skill.businessId === businessId);
    const copySkills: Skill[] = businessSkills.map(skill => {
      return {
        businessId: businessId,
        skillId: skill.skillId,
        name: skill.name,
        isChecked: false,
      } as Skill;
    }) as Skill[];

    return {
      isOpen: true,
      isChecked: false,
      businessId: businessId,
      name: businessName,
      skills: copySkills,
    } as Business;
  }) as Business[];
  return businesseTable;
})<Business[]>();

export type CheckAllIF = {
  bizIndex: number;
  skillIndex: number;
};

interface CheckIF {
  bizIndex: number;
  skillIndex: number;
}

export const check = createAction(CHECK, (bizIndex: number, skillIndex: number) => ({
  bizIndex: bizIndex,
  skillIndex: skillIndex,
}))<CheckIF>();
export const checkAllSkills = createAction(CHECK_ALL_SKILLS, (bizIndex: number) => bizIndex)<number>();
export const checkAll = createAction(CHECK_ALL, (): boolean => false)<boolean>();
export const toggle = createAction(TOGGLE, (index: number) => index)<number>();
